import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import for Excel
import jsPDF from "jspdf"; // Import for PDF
import "jspdf-autotable"; // Import for PDF tables
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../../contexts/auth";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  userQuery,
} from "@tanstack/react-query";
import StatBox from "../../Components/common/StatBox";
import {
  FaClipboardCheck,
  FaExclamationTriangle,
  FaRegClock,
  FaUsers,
} from "react-icons/fa";
import CardSkeleton from "../../Components/common/CardSkeleton";

const fetchAppointmentCounts = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("RES: ", response.data.data);
    return response.data.data;
    // if (
    //   response.data.status === "success" &&
    //   Array.isArray(response.data.data.data)
    // ) {
    //   return response.data.data.data;
    // } else {
    //   console.error("Unexpected response format:", response.data);
    // }
  } catch (error) {
    console.error("Error fetching appointment counts:", error);
  }
};

const fetchAppointments = async (searchQuery = "", token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/appointments/branch_filter_checked_in/search?page=1&search=${searchQuery}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const appointmentData = response.data.data.data?.map((appointment) => ({
      id: appointment.id,
      name: `${appointment.user.firstname} ${appointment.user.lastname}`,
      remaining_document: appointment.remaining_counts,
      cnr: appointment.cnr,
      service: appointment.branch_service.service.name,
      date: new Date(appointment.appointment_time).toLocaleDateString(),
      checkedOut: false,
      fullData: appointment,
    }));

    return appointmentData;
  } catch (error) {
    console.error("Error fetching appointments:", error);
  }
};

const fetchOfficers = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/users/branch-officers`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const officersData = response.data?.map((officer) => ({
      id: officer.branch_employee_id,
      name: `${officer.firstname} ${officer.lastname}`,
    }));
    return officersData;
  } catch (error) {
    console.error("Error fetching officers:", error);
  }
};

const checkOutAppointment = async (
  appointmentId,
  officerId,
  caseNumber,
  count,
  token
) => {
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_URL}/v1/appointments/checkOutAppointment?appointment_id=${appointmentId}&served_by=${officerId}&caseNumber=${caseNumber}`,
    //   { headers: { Authorization: `Bearer ${token}` } }
    // );
    console.log("&&&&&&&&: ", count);
    console.log("&&&&&&&&: ", token);

    const countResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/documents/serve_documents`,
      {
        appointmentId: appointmentId,
        served_by: officerId,
        count: count,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("COUNT RESULT: ", countResponse);

    // if (response.status !== 200) {
    //   throw new Error("Failed to check out");
    // }

    if (countResponse.status !== 200) {
      throw new Error("Failed to check served document.");
    }
    // fetchAppointments();
    // setSelectedOfficer('');
    // setIsModalOpen(false);
  } catch (error) {
    console.error("Error checking out:", error);
  }
};

const fetchExpiredAppointmentsForBranch = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByBranch`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // console.log("EXPIRED: ", response.data.data);
  return response.data.data;
};

const AppointmentManager = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const { token, user } = useContext(AuthContext);
  const [isExporting, setIsExporting] = useState(false);
  const [numberOfDocuments, setNumberOfDocuments] = useState(null);
  const [userLoaded, setUserLoaded] = useState(null);
  const queryClient = useQueryClient();
  // console.log(token, user?.role?.auth_role_id, "User HEre");

  const {
    data: officers,
    isLoading: isLoadingOfficerList,
    error: errorOfficerList,
  } = useQuery({
    queryKey: "offficers",
    queryFn: () => fetchOfficers(token),
  });
  const {
    data: appointmentCounts,
    isLoading: isLoadingAppointmentCount,
    error: errorAppointmentCount,
  } = useQuery({
    queryKey: ["appointmentCount", token, isModalOpen],
    queryFn: ({ queryKey }) => fetchAppointmentCounts(queryKey[1]),
    refetchInterval: 10000,
  });

  const {
    data: appointments,
    isPending: isPendingAppointment,
    isLoading: isLoadingAppointment,
    error,
  } = useQuery({
    queryKey: "appointmentData",
    queryFn: () => fetchAppointments(searchTerm, token),
    refetchInterval: 10000,
    initialData: [],
  });

  const { data: expiredAppointmentsData } = useQuery({
    queryKey: ["fetchExpiredAppointments"],
    queryFn: fetchExpiredAppointmentsForBranch,
    refetchInterval: 10000,
  });

  const mutation = useMutation({
    mutationFn: ({ appointmentId, officerId, caseNumber, count }) =>
      checkOutAppointment(appointmentId, officerId, caseNumber, count, token),
    onSuccess: () => {
      // console.log("Mutation successful");
      queryClient.invalidateQueries("appointmentData"); // Refetch appointment data
      queryClient.invalidateQueries("appointmentCount"); // Refetch appointment counts
      setIsModalOpen(false); // Close modal after success
      setSelectedOfficer(""); // Reset the officer selection
      setCaseNumber(""); // Reset the case number
    },
    onError: (error) => {
      console.error("Error checking out:", error); // Log error if mutation fails
    },
  });
  // console.log(mutation.isPending, "Loading status");

  // console.log(officers, errorOfficerList, "Officers");
  // console.log(appointments, error, "Appointments");
  // console.log(appointmentCounts, errorAppointmentCount, "Counts");

  // useEffect(() => {
  //   fetchAppointmentCounts();
  // }, [isModalOpen]);

  // const checkOutAppointment = async (appointmentId, officerId, caseNumber) => {

  //   try {
  //     const token = localStorage.getItem("token");
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/v1/appointments/checkOutAppointment?appointment_id=${appointmentId}&served_by=${officerId}&caseNumber=${caseNumber}`,
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );

  //     if (response.status !== 200) {
  //       throw new Error("Failed to check out");
  //     }
  //     fetchAppointments();
  //     setSelectedOfficer('');
  //     setIsModalOpen(false);
  //   } catch (error) {
  //     console.error("Error checking out:", error);
  //   }
  // };

  const handleCheckOutClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsModalOpen(true);
  };

  const handleConfirmCheckOut = () => {
    if (selectedOfficer && caseNumber) {
      mutation.mutate({
        appointmentId: selectedAppointment.id,
        count: numberOfDocuments,
        officerId: selectedOfficer,
        caseNumber: caseNumber,
      });
    } else {
      alert("Please select an officer and enter the case number.");
    }
  };

  const [isSearching, setIsSearching] = useState(false); // Local loading state for search

  const handleSearch = async () => {
    setIsSearching(true); // Set loading state before searching
    queryClient.invalidateQueries("appointmentData"); // Invalidate the appointment data
    await queryClient.refetchQueries("appointmentData"); // Refetch the appointment data
    setIsSearching(false); // Reset loading state after fetching
  };
  const exportToExcel = async () => {
    setIsExporting(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/todayData`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const appointmentsData = response.data?.map((appointment) => ({
        cnr: appointment.cnr,
        firstname: appointment.firstname,
        lastname: appointment.lastname,
        date: new Date(appointment.appointment_time).toLocaleDateString(),
        branch_service_id: appointment.name,
        caseNumber: appointment.caseNumber || "N/A",
      }));

      const worksheet = XLSX.utils.json_to_sheet(appointmentsData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

      const firstAppointmentDate =
        appointmentsData?.length > 0
          ? appointmentsData[0].date.replace(/\//g, "-")
          : "undefined_date";
      XLSX.writeFile(workbook, `appointments_${firstAppointmentDate}.xlsx`);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      setIsExporting(false);
    }
  };

  const exportToPDF = async () => {
    setIsExporting(true);
    const doc = new jsPDF();
    const tableColumn = ["Name", "Service", "Date", "Case Number"];
    const tableRows = [];

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/todayData`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const appointmentsData = response.data?.map((appointment) => ({
        cnr: appointment.cnr,
        firstname: appointment.firstname,
        lastname: appointment.lastname,
        date: new Date(appointment.appointment_time).toLocaleDateString(),
        service: appointment.name,
        caseNumber: appointment.caseNumber || "N/A",
      }));

      appointmentsData.forEach((appointment) => {
        const appointmentData = [
          `${appointment.firstname} ${appointment.lastname}`,
          appointment.service,
          appointment.date,
          appointment.caseNumber,
        ];
        tableRows.push(appointmentData);
      });

      doc.setFontSize(16);
      doc.text("Qetero.com", 14, 10);

      doc.setFontSize(12);
      doc.text(
        `Report Generated on: ${new Date().toLocaleDateString()}`,
        144,
        10
      );
      doc.text(`Generated By: ${userLoaded || "Unknown User"}`, 144, 15);

      doc.setFontSize(18);
      doc.text("Today's Appointments", 14, 40);

      doc.autoTable(tableColumn, tableRows, { startY: 50 });

      doc.save(`appointments_${new Date().toISOString().slice(0, 10)}.pdf`);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
    } finally {
      setIsExporting(false);
    }
  };
  const [activeStatus, setActiveStatus] = useState("confirmed"); // Toggle between confirmed or pending

  const confirmedCount =
    appointmentCounts?.find((item) => item.status === "confirmed")
      ?.appointment_count || 0;
  const pendingCount =
    appointmentCounts?.find((item) => item.status === "pending")
      ?.appointment_count || 0;

  const activeCount =
    activeStatus === "confirmed" ? confirmedCount : pendingCount;

  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 mb-6">
          {isLoadingAppointmentCount ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-yellow-500"}
              title={"Total"}
              stat={
                appointmentCounts?.reduce(
                  (total, item) =>
                    total + (Number(item.appointment_count) || 0),
                  0
                ) || 0
              }
              icon={FaUsers}
              notes={
                "Coming Up: " +
                  appointmentCounts?.find((item) => item.status === "confirmed")
                    ?.appointment_count || 0
              }
            />
          )}
          {isLoadingAppointmentCount ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-green-500"}
              title={"Queues"}
              stat={
                appointmentCounts?.find((item) => item.status === "checked in")
                  ?.appointment_count || 0
              }
              icon={FaRegClock}
            />
          )}
          {isLoadingAppointmentCount ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-green-200"}
              title={"Completed"}
              stat={
                appointmentCounts?.find((item) => item.status === "checked out")
                  ?.appointment_count || 0
              }
              icon={FaClipboardCheck}
              notes={
                "Incompleted: " +
                (appointmentCounts?.find(
                  (item) => item.status === "inCompleted"
                )?.appointment_count || 0)
              }
            />
          )}
          {isLoadingAppointmentCount ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-gray-300"}
              icon={FaExclamationTriangle}
              title={"Expired"}
              stat={
                appointmentCounts?.find((item) => item.status === "expired")
                  ?.appointment_count || 0
              }
            />
          )}
        </div>

        <div className="flex justify-end space-x-2 mb-1">
          <button
            onClick={exportToExcel}
            className={`bg-green-500 text-white p-2 rounded hover:bg-green-600 transition ${
              isExporting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isExporting}
          >
            {isExporting ? "Exporting..." : "Export to Excel"}
          </button>
          <button
            onClick={exportToPDF}
            className={`bg-green-500 text-white p-2 rounded hover:bg-green-600 transition ${
              isExporting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isExporting}
          >
            {isExporting ? "Exporting..." : "Export to PDF"}
          </button>
        </div>
      </div>

      {/* Appointment List */}
      <div className="bg-white p-6 rounded-lg shadow-md mt-6">
        <h2 className="text-xl font-bold mb-4">Check Out Appointments</h2>

        <div className="flex space-x-2 mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search appointments with Booking Code or Phone Number"
            className="p-2 border border-gray-300 rounded w-full"
          />
          <button
            onClick={handleSearch}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition"
            disabled={isSearching} // Disable button while searching
          >
            {isSearching ? "Searching..." : "Search"}
          </button>
        </div>
        {isLoadingAppointment || isPendingAppointment ? (
          <div className="text-center">Loading appointments...</div>
        ) : appointments?.length === 0 ? (
          <div className="text-center">No appointments found.</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 w-full max-w-7xl px-4 sm:px-0">
            {appointments?.map((appointment) => (
              <div
                key={appointment.id}
                className={`relative bg-white shadow-md rounded-xl p-8 border transition duration-300 ease-in-out transform hover:shadow-xl ${
                  appointment.checkedOut
                    ? "border-green-500"
                    : "border-gray-300"
                }`}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {appointment.name}
                </h3>
                <p className="text-gray-700 mb-1">
                  Service: {appointment.service}
                </p>
                <p className="text-gray-700 mb-4">Date: {appointment.date}</p>

                <p className="text-gray-700 mb-1">CNR: {appointment.cnr}</p>

                <p className="text-gray-700 mb-1">
                  Waiting: {appointment.remaining_document}
                </p>
                <div className="mt-4">
                  {user?.role?.auth_role_id ===
                    "f90db2ec-cfa3-45ed-8ee0-4321f061a7bc" && (
                    <button
                      onClick={() => handleCheckOutClick(appointment)}
                      className={`mt-4 px-6 py-3 text-white font-medium rounded-lg w-full transition-all duration-200 ease-in-out ${
                        appointment.checkedOut
                          ? "bg-green-500 cursor-not-allowed"
                          : "bg-red-500 hover:bg-red-600"
                      }`}
                      disabled={appointment.checkedOut}
                    >
                      {appointment.checkedOut ? "Checked Out" : "Check Out"}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-1/3 shadow-lg">
            <h2 className="text-lg font-bold mb-4">Check Out Appointment</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Select Officer:
              </label>
              <select
                value={selectedOfficer}
                onChange={(e) => setSelectedOfficer(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full"
              >
                <option value="">Select an officer</option>
                {officers?.map((officer) => (
                  <option key={officer.id} value={officer.id}>
                    {officer.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Case Number:
              </label>
              <input
                type="text"
                value={caseNumber}
                onChange={(e) => setCaseNumber(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full"
                placeholder="Enter case number"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Number of Documents:
              </label>
              <input
                type="number"
                min="1"
                value={numberOfDocuments}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value > selectedAppointment.remaining_document) {
                    alert(
                      `Number of documents cannot exceed ${selectedAppointment.remaining_document}.`
                    );
                  } else {
                    setNumberOfDocuments(value);
                  }
                }}
                className="p-2 border border-gray-300 rounded w-full"
                placeholder="Enter number of documents"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (
                    numberOfDocuments > selectedAppointment.remaining_document
                  ) {
                    alert(
                      `Number of documents cannot exceed ${selectedAppointment.remaining_document}.`
                    );
                    return;
                  }
                  handleConfirmCheckOut();
                }}
                className={`bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition ${
                  mutation.isPending ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={mutation.isPending} // Disable the button during loading
              >
                {mutation.isPending ? "Checking Out..." : "Confirm Check Out"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentManager;
