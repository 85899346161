import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import StatusLegend from "./StatusLegend";
import { processServiceReports } from "./processServiceReport";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../LoadingSpinner";
import ServicePieChart from "./ServicePieChart";
import { AuthContext } from "../../../contexts/auth";


import axios from "axios";

const fetchAllServiceReports = async (
  token, 
  startingDate,
  endingDate,
  selectedBranch
) => {
  let URL = "";
  
  // Construct the URL based on the parameters provided
  if (startingDate && endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?from_date=${startingDate}&to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?from_date=${startingDate}&branch_id=${selectedBranch}`;
  } else if (endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?from_date=${startingDate}&to_date=${endingDate}`;
  } else if (startingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?from_date=${startingDate}`;
  } else if (endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?to_date=${endingDate}`;
  } else if (selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization?branch_id=${selectedBranch}`;
  } else {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/serviceCountsByOrganization`;
  }

  // Set up the headers with the authorization token
  const headers = {
    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
  };

  // Make the GET request with the URL and headers
  const response = await axios.get(URL, { headers }); // Pass headers as the second argument
  return response.data.data; // Return the data from the response
};



const ServiceReports = ({ startingDate, endingDate, selectedBranch }) => {
  const {user,  token } = useContext(AuthContext);
  const {
    data: serviceData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["serviceReports", token, startingDate, endingDate, selectedBranch],
    queryFn: ({ queryKey }) =>
      fetchAllServiceReports(queryKey[1], queryKey[2], queryKey[3], queryKey[4]),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) return <div>Error loading data</div>;

  const chartData = Array.isArray(serviceData)
    ? processServiceReports(serviceData)
    : [];
  const totalAppointments = chartData.reduce(
    (acc, { count }) => acc + count,
    0
  );
  console.log("the colors: ", chartData);

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>Service Reports</CardTitle>
        <CardDescription>All Services Provided By Branches</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ServicePieChart
          chartData={chartData}
          totalAppointments={totalAppointments}
        />
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <StatusLegend chartData={chartData} />
        <div className="leading-none text-muted-foreground">
          Service Status breakdown of all branches.
        </div>
      </CardFooter>
    </Card>
  );
};

export default ServiceReports;
