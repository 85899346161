import React from "react";
import {
  ChartBarIcon,
  UsersIcon,
  XCircleIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

const iconComponents = {
  total: ChartBarIcon,
  active: UsersIcon,
  inactive: XCircleIcon,
  payment: BanknotesIcon,
};

const CardsSection = ({ cardsData }) => {
  return (
    <div className="flex flex-wrap gap-4 mb-6 items-center">
      {cardsData.map((card, index) => {
        const Icon = iconComponents[card.type] || ChartBarIcon;
        return (
          <div
            key={index}
            className="shadow-lg shadow-indigo-50 p-4 rounded-lg w-80 h-28 mt-2 flex items-center relative"
          >
            <div className="flex flex-col h-full w-full pr-12">
              <div className="text-gray-800 font-bold text-3xl">
                {card.amount}
              </div>
              <div className="text-sm text-gray-400 mt-4">{card.title}</div>
            </div>
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center justify-center">
              <div className="w-14 h-14 bg-blue-100 rounded-full flex items-center justify-center bg-primary bg-opacity-5">
                <Icon className="w-6 h-6 text-green-500" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardsSection;
