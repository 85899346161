import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext, AuthProvider } from "../../contexts/auth";
import { NavigationSidebarContext } from "../../contexts/navigationSidebar";
import About from "../../pages/about";
import SignIn from "../../pages/signin";
import Footer from "../Footer";
import Header from "../Header";
import SpecialBooking from "../../pages/SpecialBooking";
import Profile from "../../pages/Profile/profile";
import NavigationSidebar from "../navigationsidebar";
import BookForManager from "../../pages/BookForManager";
import Review from "../../pages/review"
import { ProtectedRoute } from "./protectedRoute";
import AgentReport from "../../pages/agent_report"
import BookingReport from "../../pages/booking_report"
import AgentReview from "../../pages/agent_review"
import AttendanceReport from "../../pages/attendance"
import QueueCheck from "../../pages/queue_check"

function Layout() {
  return (
    <Routes>
      <Route
        path="/signin"
        element={
          <AuthProvider>
            <SignIn />
          </AuthProvider>
        }
      />

      <Route path="/about" element={<About />} />
      <Route
        path="*"
        element={
          <AuthProvider>
            <ResponsiveLayout />
          </AuthProvider>
        }
      />
    </Routes>
  );
}

function ResponsiveLayout() {
  const { isNavigationSidebarMinimized } = useContext(NavigationSidebarContext);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = screenWidth < 768;

  const display =
    isNavigationSidebarMinimized || !isMobile ? "block" : "hidden";

  const { user } = useContext(AuthContext);

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ArrowPathIcon className="animate-spin h-10 w-10 text-gray-500" />
        <span className="ml-2 text-gray-500">
          Loading user data, please wait...
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <NavigationSidebar />
      <div
        className={`flex flex-col justify-between flex-grow overflow-y-auto ${display}`}
      >
        <Header />
        <main className="flex-grow">
          <AuthenticatedRoutes />
        </main>
        <Footer />
      </div>
    </div>
  );
}

function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />} />
      <Route path="/service" element={<ProtectedRoute />} />
      <Route path="/BranchDetail" element={<ProtectedRoute />} />
      <Route path="/organization" element={<ProtectedRoute />} />
      <Route path="/location" element={<ProtectedRoute />} />
      <Route path="/appointment" element={<ProtectedRoute />} />
      <Route path="/payment" element={<ProtectedRoute />} />
      <Route path="/ManageUser" element={<ProtectedRoute />} />
      <Route path="/appointmentduration" element={<ProtectedRoute />} />
      <Route path="/inprogress_queues" element={<ProtectedRoute />} />
      <Route path="/branch" element={<ProtectedRoute />} />
      <Route path="/checkout" element={<ProtectedRoute />} />
      <Route path="/Booking" element={<ProtectedRoute />} />
      <Route path="checkOutAppointments" element={<ProtectedRoute />} />
      <Route path="/updateBranch" element={<ProtectedRoute />} />
      <Route path="appointmentsList" element={<ProtectedRoute />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/specialBooking" element={<SpecialBooking />} />
      <Route path="/book" element={<BookForManager />} />
      <Route path='/review' element={<Review />}/>
      <Route path = "/agent_report" element={<AgentReport/>}/>
      <Route path = "/booking_report" element={<BookingReport/>}/>
      <Route path = "/branch_review" element={<AgentReview/>}/>
      <Route path = "/attendance_report" element={<AttendanceReport/>}/>
      <Route path = "/queue_check" element = {<QueueCheck/>}/>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Layout;
