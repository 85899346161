import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toaster, toast } from "sonner";
import Select from "react-select";
import axios from "axios";
import EditBranchModal from './EditBranchModal'; // Import the new modal component
import ExtraSlotsModal from "./ExtraSlotModal";

const AgentAssignServices = () => {
  const [allServices, setAllServices] = useState([]);
  const [branchServices, setBranchServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showConfirmRemovalModal, setShowConfirmRemovalModal] = useState(false);
  const [showExtraSlotModal, setShowExtraSlotModal]= useState(false);
  const [selectedServicesForRemoval, setSelectedServicesForRemoval] = useState(new Set());
  const [showEditBranchModal, setShowEditBranchModal] = useState(false); // State for the edit modal
  const location = useLocation();
  const { branchId, name } = location.state || {};

  useEffect(() => {
    fetchAllServices();
    if (branchId) {
      fetchBranchServices(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (Array.isArray(allServices) && Array.isArray(branchServices)) {
      const branchServiceIds = new Set(branchServices.map(service => service.service_id));

      const availableServices = allServices.filter(service => !branchServiceIds.has(service.id));
      setFilteredServices(availableServices);

      setSelectedOptions(branchServices.map(service => ({
        value: service.service_id,
        label: service.name,
      })));
    }
  }, [allServices, branchServices]);

  const fetchAllServices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/services`);
      setAllServices(Array.isArray(response.data.services) ? response.data.services : []);
    } catch (error) {
      console.error("Error fetching all services:", error);
      toast.error("Failed to fetch all services. Please try again later.");
    }
  };

  const fetchBranchServices = async (branchId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/branch_services/findServicesByBranchGuest?branch_id=${branchId}`
      );
      setBranchServices(Array.isArray(response.data.services) ? response.data.services : []);
    } catch (error) {
      console.error("Error fetching branch services:", error);
      toast.error("Failed to fetch services for the branch. Please try again later.");
    }
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleUpdateService = async () => {
    const selectedServiceIds = selectedOptions.map((option) => option.value);
    console.log("Selected Service IDs for Update:", selectedServiceIds);

    const requestBody = {
      branchId: branchId,
      serviceIds: selectedServiceIds,
    };

    try {
      setIsUpdating(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/branch_services/addServicesToBranch`,
        requestBody
      );

      if (response.status === 201) {
        toast.success("Services updated successfully");
        fetchBranchServices(branchId);
        setShowAddServiceModal(false);
      } else {
        toast.error("Failed to update services. Please try again later.");
      }
    } catch (error) {
      console.error("Error updating services:", error);
      toast.error("Failed to update services. Please try again later.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleServiceSelectionForRemoval = (serviceId) => {
    const updatedSelection = new Set(selectedServicesForRemoval);
    if (updatedSelection.has(serviceId)) {
      updatedSelection.delete(serviceId);
    } else {
      updatedSelection.add(serviceId);
    }
    setSelectedServicesForRemoval(updatedSelection);
  };

  const openConfirmRemovalModal = () => {
    setShowConfirmRemovalModal(true);
  };

  const handleRemoveSelectedServices = async () => {
    const serviceIdsToRemove = Array.from(selectedServicesForRemoval);
    console.log("Service IDs to Remove:", serviceIdsToRemove);

    if (serviceIdsToRemove.length === 0) {
      toast.error("No services selected for removal.");
      return;
    }

    const requestBody = {
      branchId: branchId,
      serviceIds: serviceIdsToRemove,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/branch_services/removeServicesFromBranch`,
        requestBody
      );

      if (response.status === 200) {
        toast.success("Selected services removed successfully");
        fetchBranchServices(branchId);
        setSelectedServicesForRemoval(new Set());
      } else {
        toast.error("Failed to remove selected services. Please try again later.");
      }
    } catch (error) {
      console.error("Error removing selected services:", error);
      toast.error("Error Deleting");
    } finally {
      setShowConfirmRemovalModal(false);
    }
  };

  return (
    <>
      <Toaster position="top-right" richColors />
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6 mb-4 flex justify-between items-center">
          <h2 className="text-2xl font-semibold mb-2">Assign Services to {name} Branch</h2>
          {/* Button to open Edit Branch Modal */}
          <button
            className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-200"
            onClick={() => setShowEditBranchModal(true)}
          >
            Edit Branch Info
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const allServiceIds = branchServices.map(service => service.service_id);
                      setSelectedServicesForRemoval(isChecked ? new Set(allServiceIds) : new Set());
                    }}
                    checked={selectedServicesForRemoval.size === branchServices.length && branchServices.length > 0}
                    className="cursor-pointer"
                  />
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Current Services
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {branchServices.map((service) => (
                <tr key={service.service_id} className="hover:bg-gray-50">
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                    <input
                      type="checkbox"
                      checked={selectedServicesForRemoval.has(service.service_id)}
                      onChange={() => handleServiceSelectionForRemoval(service.service_id)}
                      className="cursor-pointer"
                    />
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{service.name}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between mt-4">
            <button
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
              onClick={() => setShowAddServiceModal(true)}
            >
              Edit Services
            </button>

            <button
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
              onClick={() => setShowExtraSlotModal(true)}
            >
              Activate Extra
            </button>
            <button
              className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-200"
              onClick={openConfirmRemovalModal}
            >
              Remove Selected
            </button>
          </div>
        </div>

        {showAddServiceModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="bg-white p-6 rounded-lg shadow-md z-50 max-w-lg w-full">
              <h2 className="text-lg font-semibold mb-4 text-center">Add Services to {name}</h2>
              <Select
                isMulti
                options={filteredServices.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
                value={selectedOptions}
                onChange={handleMultiSelectChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #e2e8f0",
                    borderRadius: "0.25rem",
                    minHeight: "40px",
                    width: "100%",
                    marginBottom: "0.5rem",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#f7fafc" : "#ffffff",
                    "&:hover": {
                      backgroundColor: "#edf2f7",
                    },
                  }),
                }}
              />
              <div className="flex justify-end mt-4">
                <button
                  className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
                  onClick={handleUpdateService}
                  disabled={isUpdating}
                >
                  {isUpdating ? "Updating..." : "Update Services"}
                </button>
                <button
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md ml-2 hover:bg-gray-400 transition duration-200"
                  onClick={() => setShowAddServiceModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {showConfirmRemovalModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="bg-white p-6 rounded-lg shadow-md z-50 max-w-md w-full">
              <h2 className="text-lg font-semibold mb-4 text-center">Confirm Removal</h2>
              <p className="text-gray-600 mb-4">Are you sure you want to remove the selected services?</p>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-200"
                  onClick={handleRemoveSelectedServices}
                >
                  Confirm
                </button>
                <button
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md ml-2 hover:bg-gray-400 transition duration-200"
                  onClick={() => setShowConfirmRemovalModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {showEditBranchModal && (
          <EditBranchModal 
            branchId={branchId} 
            name={name} 
            onClose={() => setShowEditBranchModal(false)} // Pass close function
          />
        )}

        {showExtraSlotModal && (
        <ExtraSlotsModal
        isOpen={showExtraSlotModal}  // Pass showExtraSlotModal here
        branchId={branchId} 
        name={name} 
        onClose={() => setShowExtraSlotModal(false)}
      />
      
        )}
      </div>
    </>
  );
};

export default AgentAssignServices;
