import React from "react";

const statusStyles = {
  Active: "border border-green-500 text-green-500",
  Inactive: "border border-red-500 text-red-500",
  Modify: "border border-green-500 text-green-500",
};

const StatusChips = ({ status, onClick }) => {
  const isClickable = status === "Modify";
  const chipStyles =
    statusStyles[status] || "border border-gray-500 text-gray-500";

  return (
    <span
      className={`px-2 py-1 rounded-full text-sm ${
        isClickable ? "cursor-pointer" : ""
      } ${chipStyles}`}
      onClick={isClickable ? onClick : undefined}
    >
      {status}
    </span>
  );
};

export default StatusChips;
