import React, { useState } from "react";
import "../../index.css";
import axios from "axios";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

function AddServiceModal({ handleAddModal }) {
  const [formData, setFormData] = useState({
    name: "",
    organization_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  // Fetch organizations on mount
  React.useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/organizations/all`
        );
        setOrganizations(response.data);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };
    fetchOrganizations();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/services/`,
        formData
      );

      if (response.data?.status === "success") {
        console.log("Service added successfully:", response.data);
        setIsSuccessModalOpen(true);
        resetForm();
      } else {
        console.error("Failed to add service:", response.data);
      }
    } catch (error) {
      console.error("Error adding service:", error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const resetForm = () => {
    setFormData({ name: "", organization_id: "" });
  };

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={handleAddModal}
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold mb-4">Add New Service</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Service Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  placeholder="Type service name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Organization
                </label>
                <select
                  name="organization_id"
                  value={formData.organization_id}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                >
                  <option value="">Select organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="bg-gray-300 text-black px-4 py-2 rounded-md"
                  onClick={handleAddModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary text-white px-4 py-2 rounded-md"
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Add Service"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isSuccessModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-sm mx-auto">
            <div className="mb-6">
              <CheckCircleIcon className="h-16 w-16 text-green-500 mx-auto" />
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Service Added Successfully!
            </h2>
            <p className="text-gray-600 mb-6">
              You can now add more services or close this modal.
            </p>
            <button
              className="bg-primary text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
              onClick={() => setIsSuccessModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddServiceModal;
