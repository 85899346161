import React from 'react';
import { TableBody, Table, TableCell, TableHead, TableHeader, TableRow } from '../../Components/common/Table';



const Locations = ({locations}) => {
  return (
    <Table className="p-4 border border-gray-200 rounded-lg">
      <TableHeader className="border-none">
        <TableRow className="bg-gray-100">
          <TableHead>No.</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Landmark</TableHead>
          <TableHead>Address</TableHead>
          <TableHead>City</TableHead>
          <TableHead>Zone</TableHead>
          <TableHead>Region</TableHead>
          <TableHead>Country</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {locations.map((location, index) => (
          <TableRow key={location.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{location.name}</TableCell>
            <TableCell>{location.landmark}</TableCell>
            <TableCell>{location.address}</TableCell>
            <TableCell>{location.city}</TableCell>
            <TableCell>{location.zone}</TableCell>
            <TableCell>{location.region}</TableCell>
            <TableCell>{location.country}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Locations;
