import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LoadingSpinner from "../dashboard/LoadingSpinner";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../Components/common/Card";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const timeAgo = (timestamp) => {
  const now = new Date();
  const time = new Date(timestamp);
  const diff = now - time;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) return `${seconds} seconds ago`;
  if (minutes < 60) return `${minutes} minutes ago`;
  if (hours < 24) return `${hours} hours ago`;
  return `${days} days ago`;
};

const fetchBranches = async () => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches`;
  const response = await axios.get(url);
  return response.data.data;
};

const fetchGeneralReport = async (branchId) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/reviews/countsByReviewByBranch`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { branch_id: branchId },
    }
  );
  return response.data.data;
};

const fetchAllReviews = async (branchId) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/reviews/getAllReviewsByBranch`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: branchId ? { branch_id: branchId } : {},
    }
  );
  return response.data.data;
};

const getBranchReviewStatByBranch = async () => {
  const token = localStorage.getItem("token");
   const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/reviews/officerReviewStatsByBranch`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};


const ReviewsList = ({ branchId, selectedRating }) => {
  const { data: reviews, isLoading, error } = useQuery({
    queryKey: ["reviewsList", branchId],
    queryFn: () => fetchAllReviews(branchId),
  });

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>Error loading reviews. Please try again later.</div>;

  // Filter reviews based on selected rating
  const filteredReviews = reviews.filter(
    (review) => !selectedRating || review.rating === selectedRating
  );

  return (
    <div className="mt-8">
      <Card className="w-full max-w-5xl">
        <CardHeader className="items-center pb-4">
          <CardTitle>All Reviews</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {filteredReviews.map((review) => (
              <div
                key={review.review_id}
                className="border rounded-lg p-4 shadow-md bg-white"
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-semibold text-lg text-gray-800">
                    {review.firstname} {review.lastname}
                  </h3>
                  <div className="flex items-center text-yellow-500">
                    {[...Array(5)].map((_, idx) => (
                      <span key={idx}>
                        {idx < Math.floor(review.rating) ? (
                          <AiFillStar className="text-xl" />
                        ) : (
                          <AiOutlineStar className="text-gray-300 text-xl" />
                        )}
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  {review.reviewedbranch || "Unspecified Branch"}
                </p>
                <p
                  className={`text-sm mt-2 ${
                    review.comments
                      ? "text-gray-500"
                      : "text-gray-400 italic font-light"
                  }`}
                >
                  {review.comments || "No comments provided."}
                </p>
                <p className="text-sm text-gray-600 mt-2">
                  {timeAgo(review.created_at)}
                </p>
              </div>
            ))}
          </div>
        </CardContent>
        <CardFooter className="text-center text-sm text-gray-500 mt-4">
          End of reviews
        </CardFooter>
      </Card>
    </div>
  );
};

const AgentReview = () => {
  const [branchId, setBranchId] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);

  // State to manage whether we are showing top 3 or all branches
  const [showMoreTopRated, setShowMoreTopRated] = useState(false);

  const { data: branches, isLoading: branchesLoading } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  const { data: generalReport, isLoading: generalLoading } = useQuery({
    queryKey: ["generalReport", branchId],
    queryFn: () => fetchGeneralReport(branchId),
  });

  const { data: branchReviewStats, isLoading: statsLoading } = useQuery({
    queryKey: ["branchReviewStats"],
    queryFn: getBranchReviewStatByBranch,
  });

  const handleBranchChange = (event) => {
    setBranchId(event.target.value);
  };

  const handleRatingFilterChange = (event) => {
    const rating = parseInt(event.target.value, 10);
    setSelectedRating(rating === 0 ? null : rating); // "0" will reset the filter
  };

  const toggleShowMoreTopRated = () => {
    setShowMoreTopRated(!showMoreTopRated);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-6">
      <div className="flex justify-between w-full max-w-6xl">
        {/* Left Section (General Report) */}
        <div className="w-2/3">
          <Card className="w-full mb-6">
            <CardHeader className="items-center pb-4">
              <CardTitle>General Report</CardTitle>
            </CardHeader>
            <CardContent>
              {generalLoading ? (
                <LoadingSpinner />
              ) : (
                generalReport && (
                  <div className="flex flex-col gap-6">
                    {/* Average Rating */}
                    <div className="flex flex-col items-center">
                      <div className="flex items-center gap-2">
                        <p className="text-4xl font-bold text-gray-800">
                          {generalReport.averageRating}
                        </p>
                        <p className="text-gray-600 text-lg">/ 5</p>
                      </div>
                      <div className="flex items-center justify-center mt-1">
                        {[...Array(5)].map((_, index) => (
                          <span key={index}>
                            {index < Math.floor(generalReport.averageRating) ? (
                              <AiFillStar className="text-yellow-500 text-xl" />
                            ) : (
                              <AiOutlineStar className="text-gray-300 text-xl" />
                            )}
                          </span>
                        ))}
                      </div>
                      <p className="text-gray-500 mt-1">
                        {generalReport.totalRatings} Ratings
                      </p>
                    </div>

                    {/* Ratings Summary */}
                    <div>
                      <h3 className="font-semibold text-lg text-gray-800 mb-2">
                        Ratings Summary
                      </h3>
                      {Object.entries(generalReport.ratingsSummary).map(
                        ([rating, count]) => (
                          <div
                            key={rating}
                            className="flex items-center gap-4 mb-2"
                          >
                            <div className="flex">
                              {[...Array(parseInt(rating))].map((_, index) => (
                                <AiFillStar
                                  key={index}
                                  className="text-yellow-500 text-sm"
                                />
                              ))}
                            </div>
                            <div className="flex-1 h-4 bg-gray-200 rounded-full overflow-hidden">
                              <div
                                className="bg-yellow-500 h-full"
                                style={{
                                  width: `${(count / generalReport.totalRatings) *
                                    100}%`,
                                }}
                              ></div>
                            </div>
                            <span className="text-gray-600">{count}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </CardContent>
            <CardFooter className="text-center text-sm text-gray-500 mt-4">
              Updated automatically every 10 seconds
            </CardFooter>
          </Card>
        </div>

        {/* Right Section (Top Rated Officers) */}
        <div className="w-1/3 space-y-6">
          <Card className="w-full">
            <CardHeader className="items-center pb-4">
              <CardTitle>Officer Rating</CardTitle>
            </CardHeader>
            <CardContent>
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                branchReviewStats && (
                  <div>
                    {branchReviewStats
                      .slice(0, showMoreTopRated ? branchReviewStats.length : 5)
                      .map((officer) => (
                        <div key={officer.officerId} className="mb-4">
                          <div className="font-semibold text-gray-800">
                            {officer.officerName}
                          </div>
                          <div className="flex items-center text-yellow-500">
                            {[...Array(5)].map((_, idx) => (
                              <span key={idx}>
                                {idx < Math.floor(officer.averageRating) ? (
                                  <AiFillStar className="text-xl" />
                                ) : (
                                  <AiOutlineStar className="text-gray-300 text-xl" />
                                )}
                              </span>
                            ))}
                          </div>
                          <p className="text-sm text-gray-500">
                            Rating: {officer.averageRating} / 5 {`-----`} {officer.totalReviews} Reviews
                          </p>
                        </div>
                      ))}
                    <button
                      onClick={toggleShowMoreTopRated}
                      className="text-blue-500 text-sm mt-4"
                    >
                      {showMoreTopRated ? "See Less" : "See More"}
                    </button>
                  </div>
                )
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Rating Filter */}
      <div className="mb-4">
        <div className="flex gap-6">
          <div className="flex flex-col">
            <label
              htmlFor="ratingFilter"
              className="font-semibold text-gray-800 mb-2"
            >
              Filter by Rating:
            </label>
            <select
              id="ratingFilter"
              className="border border-gray-300 rounded p-2"
              value={selectedRating || ""}
              onChange={handleRatingFilterChange}
            >
              <option value="">All Ratings</option>
              {[1, 2, 3, 4, 5].map((rating) => (
                <option key={rating} value={rating}>
                  {rating} Star{rating > 1 ? "s" : ""}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* All Reviews Section */}
      <ReviewsList branchId={branchId} selectedRating={selectedRating} />
    </div>
  );
};

export default AgentReview;
