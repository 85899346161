import DatePicker from "react-datepicker";

const DateSelectionForm = ({selectedDate, setSelectedDate}) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // Function to disable Sundays
  const isSunday = (date) => {
    return date.getDay() === 0; // getDay() returns 0 for Sundays
  };

  return (
    <div className="flex flex-col items-center w-full max-w-md mx-auto">
      {/* <h2 className="text-3xl font-bold mb-6">Select a Date</h2> */}

      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        minDate={new Date()}
        // maxDate={new Date()}
        filterDate={(date) => !isSunday(date)} // Disable Sundays
        inline
        dateFormat="yyyy-MM-dd"
        monthsShown={1}
        className="w-full border border-gray-300 rounded-lg p-4"
      />
      {/* 
        {selectedDate && (
          <div className="mt-4">
            <p className="text-lg font-semibold">
              Selected Date: {formatDate(selectedDate)}
            </p>
          </div>
        )} */}

      {/* Button Container */}
      <div className="flex justify-between w-full mt-6 mr-18 space-x-4">
        {/* Back Button */}

        {/* Next Button */}
      </div>
    </div>
  );
};

export default DateSelectionForm;
