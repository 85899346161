import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AttendanceDashboard = () => {
  const [todayData, setTodayData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch attendance data from the API
  const fetchAttendanceData = async (reportType) => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      if (!token) {
        throw new Error('No token found in localStorage');
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/attendance/attendances/report?reportType=${reportType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the Authorization header
          },
        }
      );

      const result = await response.json();
      if (result.status === 'success') {
        return result.data;
      } else {
        throw new Error(result.message || 'Failed to fetch data');
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // Fetch data for today and month
  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = await fetchAttendanceData('today');
        const month = await fetchAttendanceData('month');
        setTodayData(today);
        setMonthData(month);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Data for the bar chart (monthly attendance)
  const monthlyAttendanceData = {
    labels: Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`), // Days of the month
    datasets: [
      {
        label: 'Clocked In',
        data: Array(31).fill(0), // Initialize with 0
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Clocked Out',
        data: Array(31).fill(0), // Initialize with 0
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  // Populate the chart data
  monthData.forEach((record) => {
    const day = new Date(record.created_at).getDate() - 1; // Get day of the month (0-indexed)
    if (record.type === 'clockedIn') {
      monthlyAttendanceData.datasets[0].data[day]++;
    } else if (record.type === 'clockedOut') {
      monthlyAttendanceData.datasets[1].data[day]++;
    }
  });

  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Today's Attendance Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white shadow-md rounded-lg p-6 text-center">
          <h3 className="text-lg font-semibold text-gray-700">Total Clocked In (Today)</h3>
          <p className="text-3xl font-bold text-blue-600">
            {todayData.filter((record) => record.type === 'clockedIn').length}
          </p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 text-center">
          <h3 className="text-lg font-semibold text-gray-700">Total Clocked Out (Today)</h3>
          <p className="text-3xl font-bold text-green-600">
            {todayData.filter((record) => record.type === 'clockedOut').length}
          </p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 text-center">
          <h3 className="text-lg font-semibold text-gray-700">Total Attendance (This Month)</h3>
          <p className="text-3xl font-bold text-purple-600">{monthData.length}</p>
        </div>
      </div>
      <h2 className="text-2xl font-bold mt-14">Today's Attendance</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden mb-8">
        <table className="min-w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Mobile</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Type</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Time</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {todayData.map((record) => (
              <tr key={record.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {`${record.firstname} ${record.lastname}`}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{record.mobile}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{record.type}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Date(record.created_at).toLocaleTimeString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Monthly Attendance Chart */}
      <h2 className="text-2xl font-bold mb-4">Monthly Attendance</h2>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <Bar
          data={monthlyAttendanceData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' },
              title: { display: true, text: 'Attendance Overview (This Month)' },
            },
          }}
        />
      </div>

      {/* Summary Cards */}

    </div>
  );
};

export default AttendanceDashboard;