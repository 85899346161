import React, { useState } from "react";
import Locations from "./Locations";
import { PlusIcon, TrendingUp } from "lucide-react";
import Button from "../../Components/common/Button";
import ListHeader from "../../Components/common/ListHeader";
import TableSekeleton from "../../Components/common/TableSekeleton";
import CardSkeleton from "../../Components/common/CardSkeleton";
import StatBox from "../../Components/common/StatBox";
import AddLocationModal from "./AddLocation";
import { useQuery } from "@tanstack/react-query";

const fetchLocations = async (page, limit, searchQuery = "") => {
  const url = searchQuery
    ? `${process.env.REACT_APP_API_URL}/v1/locations/search?page=${page}&limit=${limit}&text=${searchQuery}`
    : `${process.env.REACT_APP_API_URL}/v1/locations?page=${page}&limit=${limit}`;

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Error fetching locations");
  }
  return response.json();
};

const Location = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: locationsData,
    error: locationsError,
    isLoading: isLoadingLocations,
  } = useQuery({
    queryKey: ["locations", currentPage, itemsPerPage, searchQuery],
    queryFn: () => fetchLocations(currentPage, itemsPerPage, searchQuery),
  });

  console.log("locations: ", locationsData, locationsError);

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    console.log("searching", e);
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  if (locationsError) {
    return <div>Error loading locations: {locationsError.message}</div>;
  }

  const totalPages = locationsData
    ? Math.ceil(locationsData?.total_locations / itemsPerPage)
    : 1;

  return (
    <div className="p-4 bg-green">
      <div className="flex px-4 justify-end">
        <Button className="a" onClick={handleAddModal}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Location
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        {isLoadingLocations ? (
          <CardSkeleton />
        ) : (
          <>
            <StatBox
              fromColor={"from-primary"}
              toColor={" to-yellow-500"}
              icon={TrendingUp}
              stat={locationsData?.total_locations}
              title={"Total Locations"}
            />
            <StatBox
              fromColor={"from-primary"}
              toColor={" to-green-500"}
              icon={TrendingUp}
              stat={locationsData?.total_locations}
              title={"Total Locations"}
            />
            <StatBox
              fromColor={"from-primary"}
              toColor={" to-green-200"}
              icon={TrendingUp}
              stat={locationsData?.total_locations}
              title={"Total Locations"}
            />
          </>
        )}
        {/* ... other StatBoxes ... */}
      </div>
      <div className="flex flex-col border rounded-lg border-gray-100 m-4">
        <ListHeader
          titleHeader={"Location List"}
          setListLimit={setItemsPerPage}
          searchOnChange={handleSearchChange}
          searchQuery={searchQuery} // Pass searchQuery state
          setSearchQuery={setSearchQuery} // Pass setSearchQuery function
        />
        {isLoadingLocations ? (
          <TableSekeleton />
        ) : (
          <Locations locations={locationsData?.locations || []} />
        )}
        <nav
          aria-label="Page navigation example"
          className="flex justify-center py-2 "
        >
          <ul className="flex items-center -space-x-px h-8 text-sm">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === index + 1
                      ? "text-blue-600 border border-blue-300 bg-blue-50"
                      : "text-gray-500 bg-white border border-gray-300"
                  } hover:bg-gray-100 hover:text-gray-700`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {showAddModal && <AddLocationModal handleAddModal={handleAddModal} />}
      </div>
    </div>
  );
};

export default Location;
