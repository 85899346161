import React, { useState } from "react";
import CardsSection from "../../Components/common/CardsSection";
import TableSection from "../../Components/common/TableSection";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import CardSkeleton from "../../Components/common/CardSkeleton";
import TableSkeleton from "../../Components/common/TableSekeleton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";

const fetchBranches = async () => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches`;
  const response = await axios.get(url);
  return response.data.data;
};

// Fetch Branch Booking Report
const fetchBranchBookingReport = async ({ queryKey }) => {
  const [_, page, perPage, startingDate, endingDate] = queryKey;

  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchBookingReport?page=${page}&limit=${perPage}&from_date=${startingDate}&to_date=${endingDate}`;
    const { data } = await axios.get(url);
    const report = data?.branches || [];
    const totalInformation = data?.totalInformation || {};

    console.log(data, "the report data here");

    const formattedReport = report.map(
      ({
        branch_id,
        branch_name,
        paid_slots,
        total_slots_available,
        total_appointments,
        self_paid_appointments,
        call_center_appointments,
        self_paid_percentage,
      }) => ({
        branch_id: branch_id,
        branch_name: branch_name,
        paid_slots: paid_slots,
        total_slots_available: total_slots_available,
        total_appointments: total_appointments,
        self_paid_appointments: self_paid_appointments,
        call_center_appointments: call_center_appointments,
        self_paid_percentage: self_paid_percentage,
      })
    );

    // Sorting the data by self_paid_percentage in descending order
    const sortedReport = formattedReport.sort(
      (a, b) => b.self_paid_percentage - a.self_paid_percentage
    );

    return {
      totalItems: sortedReport.length,
      totalPages: 1, // Assuming there's no pagination in the new response structure
      data: sortedReport,
      totalInformation: totalInformation, // Include totalInformation in the response
    };
  } catch (error) {
    console.error("Error fetching branch booking report:", error);
    throw error;
  }
};

const ManageRoute = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");

  const HEADERS = [
    { name: "Branch Name", dataIndex: "branch_name" },
    { name: "Paid Slots", dataIndex: "paid_slots" },
    { name: "Total Slots Available", dataIndex: "total_slots_available" },
    { name: "Total Appointments", dataIndex: "total_appointments" },
    { name: "Self Paid Appointments", dataIndex: "self_paid_appointments" },
    { name: "Walk-in Appointments", dataIndex: "call_center_appointments" },
    { name: "Self Paid Percentage", dataIndex: "self_paid_percentage" },
  ];

  const { data, error, isLoading } = useQuery({
    queryKey: [
      "branchBookingReport",
      currentPage,
      rowsPerPage,
      startingDate,
      endingDate,
    ],
    queryFn: fetchBranchBookingReport,
    keepPreviousData: true,
  });
  // Sorting the data by self_paid_appointments in descending order
  const sortedData = data?.data?.sort(
    (a, b) => b.self_paid_appointments - a.self_paid_appointments
  );



  console.log("API Response Data:", data); // Log data for debugging

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex flex-wrap gap-4 mb-4">
          <CardSkeleton />
        </div>
        <TableSkeleton />
      </div>
    );
  }

  if (error) return <div>Error fetching data: {error.message}</div>;

  const totalPages = data?.totalPages;

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (term) => {
    if (term.trim() === "") {
      setSearchTerm("");
    } else {
      setSearchTerm(term);
      setCurrentPage(1);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.text("Branch Booking Report", 20, 10);

    // Define the columns
    const columns = [
      "Branch Name",
      "Paid Slots",
      "Total Slots Available",
      "Total Appointments",
      "Self Paid Appointments",
      "Call Center Appointments",
      "Self Paid Percentage",
    ];

    // Prepare rows for the table
    const rows = data?.data?.map((row) => [
      row.branch_name,
      row.paid_slots,
      row.total_slots_available,
      row.total_appointments,
      row.self_paid_appointments,
      row.call_center_appointments,
      row.self_paid_percentage,
    ]);

    // Add autoTable to the document
    doc.autoTable({
      head: [columns],
      body: rows,
    });

    // Save the PDF
    doc.save("branch_booking_report.pdf");
  };

  const dateChangeHandler = (date) => {
    if (date === null) {
      return ""; // Return an empty string if the date is null
    }
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const day = date?.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-4">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-2">
        {/* Cards Section */}
        <div className="w-full overflow-x-auto">
          <div className="flex gap-4 min-w-max">
            <CardsSection
              cardsData={[
                {
                  title: "Total Paid Slots",
                  amount: data?.totalInformation?.total_paid_slots || 0,
                },
        
                {
                  title: "Total Self Paid Appointments",
                  amount: data?.totalInformation?.total_self_paid_appointments || 0,
                },
                {
                  title: "Total Call Center Appointments",
                  amount: data?.totalInformation?.total_call_center_appointments || 0,
                },
              ]}
            />
          </div>
        </div>


      </div>
      <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto ml-auto">
  <DatePicker
    selected={startingDate ? new Date(startingDate) : null}
    onChange={(date) => setStartingDate(date ? dateChangeHandler(date) : "")}
    dateFormat="yyyy-MM-dd"
    monthsShown={1}
    className="w-full md:w-60 border border-gray-300 rounded-lg p-2 md:p-4"
    placeholderText="Filter By Date From"
    isClearable
  />
  <DatePicker
    selected={endingDate ? new Date(endingDate) : null}
    onChange={(date) => setEndingDate(date ? dateChangeHandler(date) : "")}
    dateFormat="yyyy-MM-dd"
    monthsShown={1}
    className="w-full md:w-60 border border-gray-300 rounded-lg p-2 md:p-4"
    placeholderText="Filter By Date To"
    isClearable
  />
</div>

      {/* Table Section */}
      <TableSection
        rowsPerPage={rowsPerPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        setSearchTerm={setSearchTerm}
        currentData={sortedData || []} // Ensure data is passed correctly (check if it's undefined)
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        currentPage={currentPage}
        totalPages={totalPages}
        downloadPDF={downloadPDF}
        handlePageChange={handlePageChange}
        headers={HEADERS}
      />
    </div>
  );
};

export default ManageRoute;