import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios'; // Axios for making HTTP requests

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordChange, setPasswordChange] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false); // To manage loading state
  const [error, setError] = useState(''); // To show error messages
  const [successMessage, setSuccessMessage] = useState(''); // To show success message

  // Decode JWT token from localStorage
  useEffect(() => {
    const token = localStorage.getItem('token'); // Replace 'token' with the actual key you use
    if (token) {
      const decoded = jwtDecode(token);
      setUserInfo(decoded.data); // Access user info inside the decoded token
    }
  }, []);

  const handlePasswordChangeInput = (e) => {
    const { name, value } = e.target;
    setPasswordChange({
      ...passwordChange,
      [name]: value,
    });
  };

  const handleSubmitPasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (passwordChange.newPassword !== passwordChange.confirmPassword) {
      setError('New passwords do not match!');
      return;
    }

    // Start loading
    setLoading(true);

    try {
      // Make the password change request
      const token = localStorage.getItem('token'); // Authorization token
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/change-password`,
        {
          currentPassword: passwordChange.currentPassword,
          newPassword: passwordChange.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        }
      );

      // Handle success response
      setSuccessMessage(response.data.msg || 'Password changed successfully!');
      setPasswordChange({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (err) {
      // Handle error
      setError(err.response?.data?.msg || 'Failed to change password. Please try again.');
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {/* Display Profile Info */}
        {userInfo ? (
          <>
            <div className="flex flex-col items-center mb-6">
              {/* Placeholder for Profile Image */}
              <img
                className="w-24 h-24 rounded-full object-cover mb-4"
                src="https://via.placeholder.com/150"
                alt="Profile"
              />
              <h1 className="text-2xl font-semibold">
                {userInfo.firstname} {userInfo.lastname}
              </h1>
              <p className="text-gray-600">Email: {userInfo.email || 'N/A'}</p>
              <p className="text-gray-600">Mobile: {userInfo.mobile || 'N/A'}</p>
            </div>

            {/* Toggle Change Password Form */}
            <button
              onClick={() => setShowPasswordForm(!showPasswordForm)}
              className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
            >
              {showPasswordForm ? 'Cancel' : 'Change Password'}
            </button>

            {/* Password Change Form */}
            {showPasswordForm && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold mb-4">Change Password</h2>
                <form onSubmit={handleSubmitPasswordChange} className="space-y-4">
                  {error && <p className="text-red-500">{error}</p>}
                  {successMessage && <p className="text-green-500">{successMessage}</p>}
                  
                  <div>
                    <label className="block text-gray-700">Current Password</label>
                    <input
                      type="password"
                      name="currentPassword"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-400"
                      value={passwordChange.currentPassword}
                      onChange={handlePasswordChangeInput}
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700">New Password</label>
                    <input
                      type="password"
                      name="newPassword"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-400"
                      value={passwordChange.newPassword}
                      onChange={handlePasswordChangeInput}
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700">Confirm New Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-400"
                      value={passwordChange.confirmPassword}
                      onChange={handlePasswordChangeInput}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? 'Changing Password...' : 'Submit'}
                  </button>
                </form>
              </div>
            )}
          </>
        ) : (
          <p>Loading profile...</p>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
