import React from "react";

const StatusLegend = ({ chartData }) => (
  <div className="flex flex-wrap gap-4">
    {chartData.map(({ status, fill }) => (
      <div key={status} className="flex items-center gap-2">
        <div
          style={{ backgroundColor: fill }}
          className="h-4 w-4 rounded-full"
        ></div>
        <span>{status == "inCompleted" ? "In Completed" : status}</span>
      </div>
    ))}
  </div>
);

export default StatusLegend;
