import React from "react";
function Footer() {
    return (
        <footer className="bg-primary text-white p-2">
            <div className="flex justify-center">
                <p>© {new Date().getFullYear()} Qetero - All rights reserved</p>
            </div>
        </footer>
    );
}

export default Footer;