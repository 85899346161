// UserList.js

import React from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import {
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/common/Table";
import ListHeader from "../../Components/common/ListHeader";

const UserList = ({
  currentItems,
  currentPage,
  setItemsPerPage,
  totalPages,
  searchQuery,
  handleSearch,
  handlePageChange,
  getUserInfo,
}) => {
  return (
    <>
     
      <div className="overflow-x-auto">
        <Table className="p-4 border border-gray-200 rounded-lg">
          <TableHeader className="border-none">
            <TableRow className="bg-gray-100">
              <TableHead>No.</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Phone number</TableHead>
              <TableHead>Roles</TableHead>
              <TableHead>Branch</TableHead>
              <TableHead>Created at</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentItems?.map((user, index) => {
              const rowIndex =
                (currentPage - 1) * currentItems.length + index + 1;
              return (
                <TableRow key={user.id}>
                  <TableCell>{rowIndex}</TableCell>
                  <TableCell>{`${user?.firstname} ${user?.lastname}`}</TableCell>
                  <TableCell>{user?.mobile}</TableCell>
                  <TableCell>{user?.role_name}</TableCell>
                  <TableCell>{user?.branch_name}</TableCell>
                  <TableCell>
                    {new Date(user.created_at).toLocaleString("en-US", {
                      dateStyle: "long",
                      timeStyle: "short",
                    })}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`font-bold border rounded-full align-middle px-2 py-1 flex border-gray-300 ${
                        user.active
                          ? "text-green-600 bg-green-200"
                          : "text-red-600 bg-red-200"
                      }`}
                    >
                      {user.active ? "Active" : "Inactive"}
                    </span>
                  </TableCell>
                  <TableCell>
                    <button
                      className="flex items-center text-white-500 hover:text-gray-500"
                      onClick={() => getUserInfo(user.id)}
                    >
                      <PencilIcon className="h-5 w-5 mr-1" />
                      Edit
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="mt-5 flex justify-end">
          <nav className="relative z-0 inline-flex shadow-sm">
            {/* <button
                            onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            disabled={currentPage === 1} // Disable if on the first page
                        >
                            Previous
                        </button> */}
            {/* {[...Array.from({length: totalPages})].map((number) => (
                            <button
                                key={number + 1}
                                onClick={() => handlePageChange(number + 1)}
                                className={`relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium hover:bg-gray-50 ${currentPage === number + 1 ? "bg-gray-800 text-white" : "bg-white text-gray-500"}`}
                            >
                                {number + 1}
                            </button>
                        ))} */}

            {/* <button
                            onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}
                            className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            disabled={currentPage === totalPages} // Disable if on the last page
                        >
                            Next
                        </button> */}
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserList;
