import React, { useContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/auth";
import ConfirmationModal from "../../Components/common/ConfirmationModal"; // Import the modal component
import StatBox from "../../Components/common/StatBox"; // Import the StatBox component
import axios from "axios";
import CardSkeleton from "../../Components/common/CardSkeleton";
import {
  FaClipboardCheck,
  FaExclamationTriangle,
  FaRegClock,
  FaUsers,
} from "react-icons/fa";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

// const fetchCountsByBranch = async (token) => {
//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
//   // if (!response.ok) {
//   //   throw new Error("Failed to fetch queue data");
//   // }
//   return response.data.data;
// };

const fetchInProgressQueues = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/findInProgressQueuesByUser`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const result = await response.json();
  console.log("the data of INPROGRESS QUEUES: ", result.queues); // Log actual data structure
  return result;
};

const fetchQueue = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/NextQueuesByBranch`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // if (!response.ok) {
  //   throw new Error("Failed to fetch queue data");
  // }
  // Return response as JSON
  return response.json(); // This is the correct return
};

const fetchQueueSkip = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/NextQueuesByBranchSkip`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch queue data");
  }
  console.log("the data of SKIP QUEUES: ", response.data.data);
  return response.json();
};

const fetchWaitingQueues = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/waitingQueues`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // if (!response.ok) {
  //   throw new Error("Failed to fetch queue data");
  // }
  const correctResponse = response.json();
  return correctResponse;
};

const fetchQueueStat = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/queueStatistics`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch queue data");
  }
  return response.json();
};

const updateQueueStatus = async ({ token, queueId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/updateCompleted?queueId=${queueId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update queue status");
  }
  return response.json();
};

const updateQueueCancel = async ({ token, queueId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/cancelQueue?queueId=${queueId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update queue status");
  }
  return response.json();
};

const updateQueueOnHold = async ({ token, queueId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/HoldQueue?queueId=${queueId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update queue status");
  }
  return response.json();
};

const QueueManager = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const [isWorking, setIsWorking] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedQueueId, setSelectedQueueId] = useState("");

  // Fetch in-progress queues
  const {
    data: inProgressData,
    isLoading: inProgressLoading,
    error: inProgressError,
    refetch: refetchInProgress,
  } = useQuery({
    queryKey: ["in-progress-queues"],
    queryFn: () => fetchInProgressQueues(token),
    refetchInterval: 10000,
  });

  // *******
  // const { data: dataFromBranch, isLoading: dataFromBranchLoading } = useQuery({
  //   queryKey: ["countsByBranch", token],
  //   queryFn: ({ queryKey }) => fetchCountsByBranch(queryKey[1]),
  // });
  // console.log("DATA OF BRANCH: ", dataFromBranch);
  // Fetch next queue
  const {
    isRefetching,
    isLoading,
    error,
    data: queue,
    refetch,
  } = useQuery({
    queryKey: ["queue"],
    queryFn: () => fetchQueue(token),
    enabled: false,
    refetchInterval: 10000,
  });
  // Fetch queue statistics
  const {
    data: queueStat,
    isLoading: statLoading,
    error: statError,
  } = useQuery({
    queryKey: ["queue-stat"],
    queryFn: () => fetchQueueStat(token),
    refetchInterval: 10000,
  });
  // console.log("stat: ", queueStat);
  // Fetch next queue when skip is clicked
  const {
    data: skipQueue,
    isLoading: skipLoading,
    error: skipError,
    refetch: refetchSkip,
  } = useQuery({
    queryKey: ["skip-queue"],
    queryFn: () => fetchQueueSkip(token),
    enabled: false,
  });

  //fetch waiting queue
  const {
    data: waitingQueue,
    isLoading: waitingLoading,
    error: waitingError,
    refetch: refetchWaiting,
  } = useQuery({
    queryKey: ["waiting-queue"],
    queryFn: () => fetchWaitingQueues(token),
    staleTime: 90000,
    refetchInterval: 10000,
  });
  console.log("*** waiting", waitingQueue);

  const { mutate: updateStatus, isPending: isUpdating } = useMutation({
    mutationFn: (queueId) => updateQueueStatus({ token, queueId }),
    onSuccesss: () => {
      setSelectedQueueId("");
      queryClient.invalidateQueries("in-progress-queues");
    },
  });

  // Update queue status mutation
  const { mutate: updateCancel, isPending: isCanceling } = useMutation({
    mutationFn: (queueId) => updateQueueCancel({ token, queueId }),
  });

  const { mutate: updateHold, isPending: isHolding } = useMutation({
    mutationFn: (queueId) => updateQueueOnHold({ token, queueId }),
  });

  // Start working on the next queue

  const startWorking = () => {
    setIsWorking(true);
    refetch(); // Fetch the next queue using React Query
    refetchInProgress();
  };

  const handleCompleteQueue = (queueId) => {
    updateStatus(queueId); // Mark queue as completed
    setSelectedQueueId(queueId);
  };

  const handleAction = (type) => {
    setActionType(type);
    setIsConfirmOpen(true);
  };

  const handleConfirm = () => {
    setIsConfirmOpen(false);

    if (actionType === "next") {
      // Complete the current queue
      updateStatus(queue?.queues[0]?.queue_id, {
        onSuccess: () => {
          setIsWorking(false); // Show the "Call Next" button again
        },
      });
    } else if (actionType === "skip") {
      // Skip the current queue and fetch the next one
      //refetchSkip();
      updateHold(queue?.queues[0]?.queue_id, {
        onSuccess: () => {
          setIsWorking(false);
        },
      });
      setIsWorking(false);
    } else if (actionType === "noshow") {
      // Skip the current queue and fetch the next one
      updateCancel(queue?.queues[0]?.queue_id, {
        onSuccess: () => {
          setIsWorking(false); // Show the "Call Next" button again
          queryClient.invalidateQueries("queue");
          queryClient.invalidateQueries("in-progress-queues");
          setIsWorking(false);
        },
      });
    } else {
      // Handle other actions if needed
      // console.log("Unknown action type:", actionType);
    }
  };

  const handleCancel = () => {
    setIsConfirmOpen(false); // Close the confirmation modal
  };

  return (
    <div className="min-h-screen bg-gray-10 p-6 flex flex-col items-center">
      <div className="grid grid-cols-1 md:grid-cols-[70%_30%] gap-6 w-full max-w-7xl">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 m-4">
            {console.log("the waiting list is here: ", waitingQueue)}
            {waitingLoading ? (
              <CardSkeleton />
            ) : (
              <StatBox
                fromColor={"from-primary"}
                toColor={" to-yellow-500"}
                title="Waiting Queue"
                icon={FaRegClock}
                stat={waitingQueue?.data?.waiting_count || 0}
                // stat={
                //   dataFromBranch?.find((item) => item.status === "checked in")
                //     ?.appointment_count || 0
                // }
              />
            )}
            {statLoading ? (
              <CardSkeleton />
            ) : (
              <StatBox
                fromColor={"from-primary"}
                toColor={" to-green-500"}
                title="Completed Queue"
                icon={FaClipboardCheck}
                stat={queueStat?.data?.completed_count || 0}
                // stat={
                //   dataFromBranch?.find((item) => item.status === "checked out")
                //     ?.appointment_count || 0
                // }
              />
            )}
            {statLoading ? (
              <CardSkeleton />
            ) : (
              <StatBox
                fromColor={"from-primary"}
                toColor={" to-gray-200"}
                title="No Show Queue"
                icon={FaExclamationTriangle}
                stat={queueStat?.data?.cancelled_count || 0}
                // stat={
                //   dataFromBranch?.find((item) => item.status === "expired")
                //     ?.appointment_count || 0
                // }
              />
            )}
          </div>
          <div className=" px-24 flex rounded-2xl justify-center py-6 border ">
            {!isWorking ? (
              <div className="flex w-2/4 px-4 py-5 bg-white border border-primary rounded-2xl flex-col items-center justify-center h-full">
                <img
                  src="/logo.png"
                  alt="Logo"
                  className="h-24 w-24 justify-self-center  mb-6 "
                />
                <button
                  className="h-16 bg-primary px-6 text-white font-semibold tracking-wider rounded-full flex items-center justify-center cursor-pointer hover:bg-blue-600"
                  onClick={startWorking}
                >
                  Call Next
                </button>
              </div>
            ) : isLoading ||
              isUpdating ||
              isRefetching ||
              skipLoading ||
              isCanceling ? (
              <div className="w-3/4 bg-white p-6 rounded-lg shadow-md animate-pulse space-y-4">
                <div className="w-3/4 h-12 bg-gray-200 mb-4"></div>
                <div className="w-1/2 h-12 bg-gray-200 mb-4"></div>
                <div className="w-1/2 h-12 bg-gray-200 mb-4"></div>
                <div className="w-1/3 h-16 bg-gray-200"></div>
              </div>
            ) : error ? (
              <div className="w-3/4 text-red-500 text-center">
                Error loading queue: {error.message}
              </div>
            ) : queue?.queues?.length > 0 ? (
              <div className="bg-white justify-center w-3/4 p-6 border-primary border  rounded-lg shadow-md space-y-4">
                <img
                  src="/logo.png"
                  alt="Logo"
                  className="h-24 w-24 justify-self-center  mb-6 "
                />
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-gray-700">
                    {queue?.queues[0]?.firstname} {queue?.queues[0]?.lastname}
                  </h3>
                  <p className="text-2xl font-bold text-gray-700">Queue No</p>
                  <p className="text-4xl font-extrabold text-indigo-600">
                    {queue?.queues[0]?.queue_no}
                  </p>
                </div>
                <div className="flex justify-between mt-6">
                  <button
                    className="px-4 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50"
                    onClick={() => handleAction("skip")}
                  >
                    Not Complete
                  </button>
                  <button
                    className="px-4 py-2 border border-green-600 text-green-600 rounded-lg hover:bg-blue-50"
                    onClick={() => handleAction("noshow")}
                  >
                    Not Show
                  </button>
                  <button
                    className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-blue-700"
                    onClick={() => handleAction("next")}
                  >
                    Complete
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-3/4 flex flex-col  gap-4 border border-primary bg-white p-6 rounded-lg shadow-md">
                <img
                  src="/logo.png"
                  alt="Logo"
                  className="h-24 w-24 justify-self-center  mb-6 "
                />
                <h3 className="text-center text-xl text-gray-500">
                  No Queue Available
                </h3>
                <button
                  className="h-16 bg-primary px-6 text-white font-semibold tracking-wider rounded-full flex items-center justify-center cursor-pointer hover:bg-blue-600"
                  onClick={startWorking}
                >
                  Call Next
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="border rounded-2xl p-6">
          <h3 className="text-xl font-semibold mb-4">Not Completed Queues</h3>
          {inProgressLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="loader border-t-4 border-blue-500 rounded-full w-16 h-16"></div>
            </div>
          ) : inProgressError ? (
            <div className="text-red-500 text-center">
              Failed to load in-progress queues
            </div>
          ) : inProgressData?.queues?.length > 0 ? (
            <div className="space-y-4">
              {inProgressData.queues.map((queue) => (
                <div
                  key={queue.queue_id}
                  className={`relative bg-white shadow-lg rounded-lg p-6 border ${
                    queue.completed ? "border-green-500" : "border-gray-200"
                  }`}
                >
                  <h3 className="text-xl font-semibold text-gray-800">{`Name: ${queue.firstname} ${queue.lastname}`}</h3>
                  <p className="text-gray-600">{`Queue No: ${queue.queue_no}`}</p>
                  <p className="text-gray-600">{`Service: ${queue.service_name}`}</p>

                  <button
                    className={`mt-4 px-4 py-2 text-white rounded w-full ${
                      queue.completed
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-primary hover:bg-blue-600"
                    }`}
                    disabled={isUpdating}
                    onClick={() => handleCompleteQueue(queue.queue_id)}
                  >
                    {isUpdating && selectedQueueId == queue.queue_id
                      ? "Loading..."
                      : "Mark as Completed"}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">
              No Completed Queues Available
            </p>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={isConfirmOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        message={`Are you sure?`}
      />
    </div>
  );
};

export default QueueManager;
