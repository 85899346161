import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from "react";
import { AuthContext } from '../../contexts/auth';
import { DefaultViews, RoleBasedViews } from '../../pages/views';
import { LogIn } from 'lucide-react';

const ProtectedRoute = () => {
    console.log("layout rerender")
    console.log("role", LogIn)
    const { user } = useContext(AuthContext);
    const location = useLocation();

    try {
        return RoleBasedViews[user.role.auth_role_id].routes[location.pathname].component;
    } catch {
        try {
            //console.log('trying default view');
            return DefaultViews[location.pathname].component;
        } catch {
            return <Navigate to='/403' />;
        };
    };

}

export { ProtectedRoute };