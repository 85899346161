import React from "react";

function TimeOfDayTag({ time }) {
  const tagColor = time === true ? "#FFD700" : "#FF8C00";
  return (
    <span
      style={{
        backgroundColor: tagColor,
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "5px",
      }}
    >
      {time === true ? "Morning" : "Afternoon"}
    </span>
  );
}

export default TimeOfDayTag;
