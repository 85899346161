import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CardsSection from "../../Components/common/CardsSection";
import TableSection from "../../Components/common/TableSection";
import Modal from "../../Components/common/Modal";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Button from "../../Components/common/Button";
import StatusChips from "../../Components/common/StatusChips";
import CardSkeleton from "../../Components/common/CardSkeleton";
import TableSkeleton from "../../Components/common/TableSekeleton";

const fetchOrganizations = async ({ queryKey }) => {
  const [_, page, perPage, searchTerm] = queryKey;
  const url = searchTerm
    ? `${process.env.REACT_APP_API_URL}/v1/organizations/search?text=${searchTerm}&page=${page}&limit=${perPage}`
    : `${process.env.REACT_APP_API_URL}/v1/organizations/?page=${page}&limit=${perPage}`;

  const { data } = await axios.get(url);
  if (searchTerm) {
    data.data = data.organizations.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  } else {
    data.data = data.data.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  }

  return data;
};

const ManageStations = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pendingSearchTerm, setPendingSearchTerm] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [isActiveFilter, setIsActiveFilter] = useState(null);

  const { data, error, isLoading } = useQuery({
    queryKey: ["organizations", currentPage, rowsPerPage, searchTerm],
    queryFn: fetchOrganizations,
    keepPreviousData: true,
  });

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex flex-wrap gap-4 mb-4">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
        <TableSkeleton />
      </div>
    );
  }
  if (error) return <div>Error fetching data: {error.message}</div>;

  const filteredData = data?.data.filter((row) => {
    const matchesOrganization = selectedOrganizationId
      ? row.organizationName === selectedOrganizationId
      : true;
    const matchesActiveStatus =
      isActiveFilter === null ? true : row.activity === isActiveFilter;

    return matchesOrganization && matchesActiveStatus;
  });

  const sortedData = filteredData.sort((a, b) => b.createdAt - a.createdAt);
  const totalOrganizations = data?.totalItems || 0;
  const totalPages = data?.totalPages;
  const HEADERS = [
    { name: "Organization", dataIndex: "organizationName" },
    { name: "Description", dataIndex: "status" },
    {
      name: "Status",
      dataIndex: "activity",
      render: (value) => <StatusChips status={value ? "Active" : "Inactive"} />,
    },
    {
      name: "Actions",
      render: (row) => (
        <>
          <button
            className="text-blue-600 hover:text-blue-900 mr-2"
            onClick={() => handleViewDetails(row.id)}
          >
            View Details
          </button>
          <button
            className="text-green-600 hover:text-green-900"
            onClick={() => handleEdit(row.id)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleViewDetails = (id) => {
    navigate(`/branch?orgId=${id}`);
    console.log("View details for organization with id:", id);
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/organizations/${id}`
      );
      const { name, description, active } = response.data;

      setOrganizationName(name);
      setDescription(description);
      setStatus(active);
      setSelectedOrganizationId(id);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching organization data for editing:", error);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setPendingSearchTerm("");
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrganizationId(null);
  };

  const handleAddOrganization = () => {
    // Submit the changes (handle form submission logic here)
    console.log("Updating organization with id:", selectedOrganizationId);

    setIsModalOpen(false);
    setSelectedOrganizationId(null);
  };

  const handleSearchTermChange = (event) => {
    const value = event.target.value;
    setPendingSearchTerm(value);

    if (value.trim() === "") {
      setSearchTerm("");
      setCurrentPage(1);
    }
  };

  const handleSearch = () => {
    if (pendingSearchTerm.trim() === "") {
      handleClearSearch();
    } else {
      setSearchTerm(pendingSearchTerm);
      setCurrentPage(1);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap gap-4 mb-4">
        <CardsSection
          cardsData={[
            {
              title: "Total Organizations",
              amount: totalOrganizations.toString(),
              type: "total",
            },
            {
              title: "Active Organizations",
              amount:
                data?.data.filter((org) => org.activity).length.toString() ||
                "0",
              type: "active",
            },
            {
              title: "Inactive Organizations",
              amount:
                data?.data.filter((org) => !org.activity).length.toString() ||
                "0",
              type: "inactive",
            },
          ]}
        />
      </div>
      <Button
        className=" py-1 text-sm h-10 w-48 rounded align-bottom mb-5"
        onClick={() => setIsModalOpen(true)}
      >
        Add Organization
      </Button>
      <TableSection
        rowsPerPage={rowsPerPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        searchTerm={pendingSearchTerm}
        handleSearchTermChange={handleSearchTermChange}
        handleSearch={handleSearch}
        currentData={sortedData}
        currentPage={currentPage}
        handleClearSearch={handleClearSearch}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        headers={HEADERS}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleAddOrganization}
        organizationName={organizationName}
        setOrganizationName={setOrganizationName}
        description={description}
        setDescription={setDescription}
        status={status}
        setStatus={setStatus}
        organizationId={selectedOrganizationId} // Pass the selected organization's ID
      />
    </div>
  );
};

export default ManageStations;
