import React from "react";

const UserModal = ({
  isModalOpen,
  closeModal,
  isEditing,
  handleFirstNameChange,
  handleLastNameChange,
  handlePhoneNumberChange,
  handlePassword,
  firstName,
  lastName,
  phoneNumber,
  password,
  organizationId,
  branchId,
  firstNameError,
  lastNameError,
  phoneNumberError,
  passwordError,
  handleAddClick,
  handleEditClick,
  setWindowId,
  organizations = [],
  branches = [],
  windows = [],
  roles = [],
  setOrganizationId,
  setBranchId,
  roleId,
  setRoleId,
}) => {
  {
    console.log("WHAT are the values: ", roleId, organizationId, branchId, branches );
  }
  return (
    isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-70 backdrop-filter backdrop-blur-sm">
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-gray-900 rounded-lg shadow">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-lg font-semibold text-white">
                {isEditing ? "Edit User" : "Add New User"}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <form className="p-4 md:p-5">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={handleFirstNameChange}
                  />
                  {firstNameError && (
                    <p className="text-red-500 text-xs">{firstNameError}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={handleLastNameChange}
                  />
                  {lastNameError && (
                    <p className="text-red-500 text-xs">{lastNameError}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    required
                    onChange={handlePhoneNumberChange}
                  />
                  {phoneNumberError && (
                    <p className="text-red-500 text-xs">{phoneNumberError}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={handlePassword}
                  />
                  {passwordError && (
                    <p className="text-red-500 text-xs">{passwordError}</p>
                  )}
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Organization
                  </label>
                  <select
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    onChange={(e) => setOrganizationId(e.target.value)}
                    value={organizationId}
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Branch
                  </label>
                  <select
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    onChange={(e) => setBranchId(e.target.value)}
                    value={branchId}
                  >
                    <option value="">Select Branch</option>
                    {branches?.data?.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-white">
                    Role
                  </label>
                  {console.log("The ROLE: ", roles)}
                  <select
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    onChange={(e) => {
                      setRoleId(e.target.value);
                      console.log("Selected Role ID:", e.target.value); // Log the selected role ID
                    }}
                    value={roleId}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>

                {roleId === "af43ff03-13d4-4c22-ab35-ae9e59408b20" && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-white">
                      Window
                    </label>
                    <select
                      className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      onChange={(e) => setWindowId(e.target.value)}
                    >
                      <option value="">Select Window</option>
                      {windows.map((window) => (
                        <option key={window.id} value={window.id}>
                          {window.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="flex justify-end mt-4">
                {isEditing ? (
                  <button
                    className="bg-green-600 text-white px-4 py-2 rounded-md"
                    onClick={handleEditClick}
                  >
                    Save Changes
                  </button>
                ) : (
                  <button
                    className="bg-primary text-white px-4 py-2 rounded-md"
                    onClick={handleAddClick}
                  >
                    Add User
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default UserModal;
