import React from "react";

const TableSkeleton = () => {
  const rows = Array.from({ length: 10 }, (_, index) => index); // Create an array of 10 elements

  return (
    <div className="overflow-x-auto  p-4 border  border-gray-200 rounded-lg  animate-pulse">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <th scope="col" className="px-6 py-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </th>
          <th scope="col" className="px-6 py-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </th>
          <th scope="col" className="px-6 py-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </th>
          <th scope="col" className="px-6 py-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </th>
          <th scope="col" className="px-6 py-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </th>
          {/* Repeat for each table header */}
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row} className="bg-white border-b">
              <td className="px-6 py-4">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
              </td>
              <td className="px-6 py-4">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
              </td>
              {/* Repeat for each table cell */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
