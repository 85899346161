import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Branchs from "./Services";
import { PlusIcon, TrendingUp } from "lucide-react";
import Button from "../../Components/common/Button";
import ListHeader from "../../Components/common/ListHeader";
import TableSekeleton from "../../Components/common/TableSekeleton";
import CardSkeleton from "../../Components/common/CardSkeleton";
import StatBox from "../../Components/common/StatBox";
import AddBranchModal from "./AddServices";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Modal from "./EditService";
import { toast } from "sonner";

const fetchOrganizations = async ({ queryKey }) => {
  const [_, page, perPage, searchTerm] = queryKey;
  const url = searchTerm
    ? `${process.env.REACT_APP_API_URL}/v1/organizations/search?text=${searchTerm}&page=${page}&limit=${perPage}`
    : `${process.env.REACT_APP_API_URL}/v1/organizations/?page=${page}&limit=${perPage}`;

  const { data } = await axios.get(url);
  if (searchTerm) {
    data.data = data.organizations.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  } else {
    data.data = data.data.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  }

  return data;
};

// Fetch services data with search functionality
const fetchservices = async ({ queryKey }) => {
  const [_, page, limit, orgId, searchQuery, selectedOrganization] = queryKey; // Add searchQuery to queryKey
  const url = orgId
    ? `${process.env.REACT_APP_API_URL}/v1/services/by-organization?organization_id=${orgId}&search=${searchQuery}` // Add search param
    : selectedOrganization
    ? `${process.env.REACT_APP_API_URL}/v1/services/by-organization?organization_id=${selectedOrganization}&search=${searchQuery}`
    :  `${process.env.REACT_APP_API_URL}/v1/services?page=${page}&limit=${limit}&search=${searchQuery}`; // Add search param

  const { data } = await axios.get(url);
  if (orgId) {
    return {
      services: data.services,
      total: data.services?.length || 0,
      totalPages: 1,
      currentPage: 1,
    };
  } else {
    return data;
  }
};

const updateService = async (updatedService) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/services/${updatedService.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedService),
    }
  );
  if (!response.ok) throw new Error("Failed to update service.");
  return response.json();
};

const Serv = () => {
  const queryClient = useQueryClient();
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const orgId = searchParams.get("orgId");
  const [serviceUpdateModal, setServiceUpdateModal] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [organization_id, setOrganizationId] = useState("");
  const [active, setActive] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // const [searchQuery, setSearchQuery]
  // const [] = useState("")

  // fetchOrganizations
  const {
    data: organizationsdata,
    error: organizationserror,
    isLoading: isLoadingOrganizations,
  } = useQuery({
    queryKey: ["organizations", currentPage, itemsPerPage, searchQuery],
    queryFn: fetchOrganizations,
  });

  // Query to fetch services
  const {
    data: servicesdata,
    error: serviceserror,
    isLoading: isLoadingBranches,
  } = useQuery({
    queryKey: [
      "services",
      currentPage,
      itemsPerPage,
      orgId,
      searchQuery,
      selectedOrganization
    ], // Include searchQuery
    queryFn: fetchservices,
  });
  console.log("ALL SERVICES: ", servicesdata);

  const updateServiceMutation = useMutation({
    mutationFn: updateService,
    onSuccess: () => {
      toast.success("Servie Updated Successfully");
      queryClient.invalidateQueries(["services"]);
      setServiceUpdateModal(false);
    },
  });

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search change (when user submits the search)
  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  if (serviceserror) {
    return <div>Error loading branches: {serviceserror.message}</div>;
  }

  const handleServiceUpdate = () => {
    let data = {
      name: serviceName,
      id: serviceId,
      active,
      organization_id,
    };
    console.log("the form data: ", data);
    updateServiceMutation.mutate(data);
  };

  return (
    <div className="p-4 bg-green">
      <div className="flex px-4 justify-end">
        <Button className="a" onClick={handleAddModal}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Services
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-yellow-500"}
            icon={TrendingUp}
            stat={servicesdata?.total}
            title={"Total Services"}
          />
        )}
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-green-500"}
            icon={TrendingUp}
            stat={
              servicesdata?.services?.filter((service) => service.active)
                .length || 0
            }
            title={"Active Services"}
          />
        )}
      </div>
      <div className="flex flex-col border rounded-lg border-gray-100 m-4">
        <ListHeader
          titleHeader={"Services List"}
          setListLimit={setItemsPerPage}
          searchOnChange={handleSearchChange}
          searchQuery={searchQuery} // Pass searchQuery state
          setSearchQuery={setSearchQuery} // Pass setSearchQuery function
          organziationData={organizationsdata}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          fromService={true}
        />
        {isLoadingBranches ? (
          <TableSekeleton />
        ) : (
          <Branchs
            services={servicesdata?.services || servicesdata || []}
            getServiceId={(serviceId) => {
              const service = servicesdata?.services.find(
                (s) => s.id === serviceId
              );
              console.log("the service: ", service);
              setServiceId(serviceId);
              setServiceName(service.name);
              setOrganizationId(service.organization_id);
              setActive(service.active);
              setServiceUpdateModal(true);
            }}
          />
        )}
        <nav
          aria-label="Page navigation example"
          className="flex justify-center py-2 "
        >
          <ul className="flex items-center -space-x-px h-8 text-sm">
            {Array.from(
              { length: servicesdata?.totalPages || 1 },
              (_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 leading-tight ${
                      currentPage === index + 1
                        ? "text-blue-600 border border-blue-300 bg-blue-50"
                        : "text-gray-500 bg-white border border-gray-300"
                    } hover:bg-gray-100 hover:text-gray-700`}
                  >
                    {index + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        </nav>
        {showAddModal && <AddBranchModal handleAddModal={handleAddModal} />}
      </div>
      <Modal
        isOpen={serviceUpdateModal}
        onConfirm={handleServiceUpdate}
        onClose={() => setServiceUpdateModal(false)}
        handleNameChange={(e) => setServiceName(e.target.value)}
        serviceName={serviceName}
        handleServiceActive={(e) => setActive(e.target.value)}
        active={active}
        serviceId={serviceId}
        serviceOrganization={organization_id}
      />
    </div>
  );
};

export default Serv;
