import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { AuthContext } from '../../contexts/auth';
import { RoleBasedViews } from '../views';
import { jwtDecode } from "jwt-decode";

export default function SignIn() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { setToken } = useContext(AuthContext);

    async function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        setMobileError('');
        setPasswordError('');
        setError(null);
        let isValid = true;

        if (formData.get('mobile').trim() === '') {
            setMobileError('Mobile number is required');
            isValid = false;
        } else {
            const phoneNumberRegex = /^(7|9)\d{8}$/;
            if (!phoneNumberRegex.test(formData.get('mobile').trim())) {
                setMobileError('Invalid mobile number format. Mobile number must start with either 9 or 7, followed by 8 digits.');
                isValid = false;
            }
        }

        if (formData.get('password').trim() === '') {
            setPasswordError('Password is required');
            isValid = false;
        }

        if (isValid) {
            setLoading(true);
            const data = {
                'mobile': '+251' + formData.get('mobile'),
                'password': formData.get('password')
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/validatePassword`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
            
                if (response.ok) {
                    const result = await response.json();
                    const decodedToken = jwtDecode(result.token);
                    const role = decodedToken.data?.role?.auth_role_id;
                    const roleBasedView = RoleBasedViews[role];
                    if (roleBasedView && roleBasedView.routes) {
                        const roles_menu = Object.keys(roleBasedView?.routes)?.map((key) => {
                            const { icon, label } = roleBasedView.routes[key];
                            return { Icon: icon, label, to: key };
                        });
            
                        if (roles_menu && roles_menu.length > 0) {
                            localStorage.setItem('token', result.token);
                            setToken(result.token);
                            navigate(roles_menu[0].to);
                        } else {
                            setError('No menus access has been granted to you. Please contact your administrator');
                        }
                    } else {
                        setError('Invalid role or role-based view configuration');
                    }
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                setLoading(false);
                setError('Either phone number or password is incorrect. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <ArrowPathIcon className="animate-spin h-10 w-10 text-gray-500" />
                <span className="ml-2 text-gray-500">Authenticating User, please wait...</span>
            </div>
        );
    }

    return (
        <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
                    <img className="w-8 h-8 mr-2" src="icon.png" alt="logo" />
                    Qetero Portal
                </a>
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Sign in to your account
                        </h1>
                        {error && (
                            <div className="flex justify-center items-center mb-4">
                                <div className="text-xs font-bold bg-red-500 text-white p-4 rounded-lg">
                                    <p>{error}</p>
                                </div>
                            </div>
                        )}
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900">
                                    Mobile
                                </label>
                                <div className="flex items-center space-x-4">
                                    <p className='flex mr-3'><img className='w-10 mr-1' src="eth.png" alt="ETH Flag" />+251</p>
                                    <input
                                        id="mobile"
                                        name="mobile"
                                        type="tel"
                                        autoComplete="mobile"
                                        placeholder='ENTER YOUR MOBILE NUMBER'
                                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                </div>
                                {mobileError && <p className='mt-2 font-bold text-red-500 text-xs'>{mobileError}</p>}
                            </div>

                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    placeholder='ENTER YOUR PASSWORD'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {passwordError && <p className='mt-2 font-bold text-red-500 text-xs'>{passwordError}</p>}
                            </div>

                            <button
                                type="submit"
                                className="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
