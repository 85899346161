import React from "react";
import { FaEdit } from "react-icons/fa";
// import { FaDeleteLeft } from "react-icons/fa6";
import "./Calendar.css"; // CSS file for styling

const Calendar = ({ month, year, officerData, handleEditClick }) => {
  console.log("####: ", officerData);
  // Get days in the month
  const getDaysInMonth = (month, year) => {
    return new Array(new Date(year, month + 1, 0).getDate())
      .fill(null)
      .map((_, index) => index + 1);
  };

  // Get the starting day of the month
  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay(); // 0 = Sunday, 6 = Saturday
  };

  const daysInMonth = getDaysInMonth(month, year);
  const firstDay = getFirstDayOfMonth(month, year);

  return (
    <div className="calendar">
      {/* Header: Days of the Week */}
      <div className="calendar-header">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="calendar-day-header">
            {day}
          </div>
        ))}
      </div>

      {/* Body: Days of the Month */}
      <div className="calendar-body">
        {/* Empty spaces for days before the first day */}
        {Array.from({ length: firstDay }).map((_, index) => (
          <div key={index} className="calendar-empty"></div>
        ))}

        {/* Days of the month */}
        {daysInMonth.map((day) => (
          <div key={day} className="calendar-day">
            <div className="calendar-date text-yellow-800">{day}</div>
            <div className="calendar-info">
              Per Slot:{" "}
              <span className="text-gray-900">
                {officerData[day]?.appointment_services_per_slot
                  ? officerData[day].appointment_services_per_slot
                  : officerData[day]?.branch_services_per_slot || 0}
              </span>
            </div>
            <div className="flex flex-row justify-evenly items-center m-2">
              <button onClick={() => handleEditClick(officerData[day])}>
                <FaEdit className="text-green-500 text-md" />
              </button>
              {/* <button>
                <FaDeleteLeft className="text-red-500 text-sm" />
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
