"use client";

import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../charts/Chart";
import LoadingSpinner from "../LoadingSpinner";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";

const fetchAllAppointments = async (token, startingDate, endingDate) => {
  // let currentPage = 1;
  // let totalPages = 1;
  // const allAppointments = [];

  // while (currentPage <= totalPages) {
  let URL = "";
  if (startingDate && endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByBranch?from_date=${startingDate}&to_date=${endingDate}`;
  } else if (startingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByBranch?from_date=${startingDate}`;
  } else if (endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByBranch?to_date=${endingDate}`;
  } else {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByBranch`;
  }
  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // const { data, totalPages: fetchedTotalPages } = response.data.data;
  // allAppointments.push(...data);
  // totalPages = fetchedTotalPages;
  // currentPage += 1;
  // }
  console.log("ALl the appointments: ", response.data.data);
  const updatedKeyValues = response?.data?.data?.map((eachAppointments) => ({
    date: eachAppointments.appointment_date,
    appointments: eachAppointments.appointment_count,
    confirmed_count: eachAppointments.confirmed_count,
    checkedin_count: eachAppointments.checkedin_count,
    checkedout_count: eachAppointments.checkedout_count,
    expired_count: eachAppointments.expired_count,
    incomplted_count: eachAppointments.incomplted_count,
    rejected_count: eachAppointments.rejected_count,
    cancelled_count: eachAppointments.cancelled_count,
  }));
  return updatedKeyValues;
};

const processAppointments = (appointments) => {
  const appointmentsPerDay = {};

  appointments?.forEach((appointment) => {
    const date = new Date(appointment.appointment_time).toLocaleDateString(
      "en-US"
    );
    if (!appointmentsPerDay[date]) {
      appointmentsPerDay[date] = 0;
    }
    appointmentsPerDay[date] += 1;
  });

  return Object.entries(appointmentsPerDay).map(([date, count]) => ({
    date,
    appointments: count,
  }));
};

export default function BranchBarChart({ startingDate, endingDate }) {
  const { token } = useContext(AuthContext);
  const {
    data: appointmentsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["appointments", token, startingDate, endingDate],
    queryFn: ({ queryKey }) =>
      fetchAllAppointments(queryKey[1], queryKey[2], queryKey[3]),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) return <div>Error loading data</div>;

  const chartData = appointmentsData;

  const chartConfig = {
    appointments: {
      label: "Appointments",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Daily Appointments</CardTitle>
        <CardDescription>Number of appointments per day</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            data={chartData}
            className="w-[800px] h-[300px] "
            margin={{ top: 20, right: 30, bottom: 25 }}
          >
            <XAxis
              dataKey="date"
              tick={true}
              tickLine={false}
              axisLine={false}
              interval={0}
              angle={-35}
              textAnchor="end"
              dy={10}
              fontSize={"10px"}
              className="text-xs"
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              ticks={[0, 200, 400, 600, 800, 1000]}
            />
            <Bar dataKey="appointments" fill="#30f254" radius={4} />
            <Bar dataKey="confirmed_count" stackId="a" fill="#e5fa02" />
            <Bar dataKey="checkedin_count" fill="#ffc658" />
            <Bar dataKey="checkedout_count" fill="#f081e7" />
            <Bar dataKey="expired_count" fill="#BBB5F7" />
            <Bar dataKey="incomplted_count" fill="#82ca9d" />
            <ChartTooltip
              content={<ChartTooltipContent indicator="line" />}
              cursor={false}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
