import React, { useState, useEffect } from "react";
import axios from "axios";
import { QueryClient, useQuery } from "@tanstack/react-query";
import {
  FaClipboardCheck,
  FaRegClock,
  FaUsers,
  FaExclamationTriangle,
  FaExclamationCircle,
  FaStar,
} from "react-icons/fa";
import { MdDeleteForever, MdOutlineCancel } from "react-icons/md";
import StatBox from "../../Components/common/StatBox";
import { Star, TrendingUp } from "lucide-react";
import CardSkeleton from "../../Components/common/CardSkeleton";
import { toast, Toaster } from "sonner";
import TimeSlotSelection from "../Booking/Steps/TimeSlotSelection";

const AppointmentManager = () => {
  const [appointments, setAppointments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteAppConfirmationModal, setDeleteAppConfirmationModal] =
    useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // Store selected appointment for confirmation
  const [confirmLoading, setConfirmLoading] = useState(false); // Loading state for confirm button
  const [deleteAppLoading, setDeleteAppLoading] = useState(false);
  const [grayCount, setGrayCount] = useState(0);

  const [appointmentCounts, setAppointmentCounts] = useState([]); // Initial state as an empty array

  const [selectedTimeSlotDetails, setSelectedTimeSlotDetails] = useState({
    time_from: null,
    duration_id: null,
    remainingSlots: null,
    branch_service_per_hour: null,
  });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isModalOpenForReschedule, setIsModalOpenForReschedule] =
    useState(false);
  const [distinctDates, setDistinctDates] = useState([]);
  const [timeToFilter, setTimeToFilter] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const fetchAppointmentCounts = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;

      // Check if response is structured as expected
      // if (
      //   response.data.status === "success" &&
      //   Array.isArray(response.data.data.data)
      // ) {
      //   setAppointmentCounts(response.data.data.data); // Set the appointment counts
      //   return [];
      // } else {
      //   console.error("Unexpected response format:", response.data);
      // }
    } catch (error) {
      console.error("Error fetching appointment counts:", error);
    }
  };

  function subtractHours(timeString, hours) {
    // Convert the time string (HH:mm) to a date object
    const [hoursPart, minutesPart] = timeString.split(":");

    let appointmentDate = new Date();

    appointmentDate.setHours(hoursPart);
    appointmentDate.setMinutes(minutesPart);

    // Subtract the hours
    appointmentDate.setHours(appointmentDate.getHours() - hours);

    // Format the time back to HH:mm
    const formattedTime = appointmentDate
      .toTimeString()
      .split(" ")[0]
      .slice(0, 5);
    return formattedTime;
  }

  // Use Effect to fetch data on component mount
  // useEffect(() => {
  //   fetchAppointmentCounts(); // Fetch appointment counts when the component mounts
  // }, [isModalOpen]);
  const {
    data: fetchAppointmentsCountData = [],
    isLoading: fetchAppointmentCountsLoading,
  } = useQuery({
    queryKey: ["fetchAppointmetnsCount", isModalOpen],
    queryFn: fetchAppointmentCounts,
    refetchInterval: 10000,
  });

  // Function to fetch appointments with optional search term
  const fetchAppointments = async (search = "") => {
    // setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/branch_filter/search?page=1&search=${search}&date=${date}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const appointmentData = response.data.data.data.map((appointment) => ({
        id: appointment.id,
        name: `${appointment.user.firstname} ${appointment.user.lastname}`,
        cnr: appointment.cnr,
        time_specific: appointment.branch_service.branch?.time_specific,
        appointmentTime:
          appointment?.appointment_duration?.time_from || "00:00",
        document_count: appointment.document_count,
        service: appointment.branch_service?.service.name,
        date: new Date(appointment.appointment_time).toLocaleDateString(),
        checkedIn: false,
        fullData: appointment,
        appointment_through: appointment.appointment_through,
      }));

      // console.log(appointmentData, "data is here");
      // const sortedData = appointmentData.sort((a, b) => {
      //   if (
      //     a.appointment_through === "self" &&
      //     b.appointment_through === "callCenter"
      //   ) {
      //     return -1; // Move "self" earlier
      //   }
      //   if (
      //     a.appointment_through === "callCenter" &&
      //     b.appointment_through === "self"
      //   ) {
      //     return 1; // Move "callCenter" later
      //   }
      //   return 0; // Keep the same order for other cases
      // });
      const distinctDurations = [
        ...new Set(
          appointmentData.map((appointment) =>
            subtractHours(appointment.appointmentTime, 6)
          )
        ),
      ];
      //
      // subtractHours(appointment.appointmentTime, 6)

      setDistinctDates(distinctDurations);
      // console.log("*****: ", appointmentData);
      // console.log("******: ", distinctDurations);

      const grayAppointments = appointmentData.filter((appointment) => {
        const timeDifference = getTimeDifference(
          appointment.fullData.appointment_duration?.time_from || "00:00"
        );
        return timeDifference < -10 || timeDifference > 10;
      });

      // Set gray appointment count
      setGrayCount(grayAppointments.length);

      setAppointments(appointmentData);
      // setLoading(false);
      return appointmentData;
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    appointments.map((eachAppointment) => {
      if (eachAppointment.appointmentTime == timeToFilter) {
      }
    });
  }, [timeToFilter]);

  // Function to add an appointment to the queue
  const addToQueue = async (item, token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/queues/add_queue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            appointment_id: item?.id,
            first_name: item?.user?.firstname,
            last_name: item?.user?.lastname,
            phone_number: item?.user?.mobile,
            branch_service_id: item?.branch_service?.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add to queue");
      }

      const result = await response.json();
      console.log("Queue added successfully:", result);
      return result;
    } catch (error) {
      console.error("Error adding to queue:", error);
    }
  };

  // useEffect(() => {
  //   fetchAppointments();
  // }, [date]);

  const [dashboardData, setDashboardData] = useState({
    waitingQueues: 0,
    appointmentsToday: 0,
  });

  const handleSearch = () => {
    fetchAppointments(searchTerm); // Fetch appointments based on the search term
  };

  const handleCheckInClick = (appointment) => {
    setSelectedAppointment(appointment); // Set the appointment for confirmation
    setIsModalOpen(true); // Open confirmation modal
  };

  const handleConfirmCheckIn = async () => {
    const token = localStorage.getItem("token");
    setConfirmLoading(true); // Set confirm loading state to true

    await addToQueue(selectedAppointment.fullData, token); // Add to queue
    fetchAppointments(); // Refetch appointments after adding to queue
    setIsModalOpen(false); // Close modal
    setConfirmLoading(false); // Reset confirm loading state
  };

  // let now = new Date();
  const getTimeDifference = (appointmentTime) => {
    // Check if appointmentTime is a valid string
    if (typeof appointmentTime !== "string" || !appointmentTime.includes(":")) {
      console.error("Invalid appointment time format");
      return NaN;
    }

    // Split appointmentTime and handle missing seconds by defaulting them to 0
    const timeParts = appointmentTime.split(":").map(Number);
    if (timeParts.some(isNaN)) {
      console.error("Appointment time contains invalid numbers");
      return NaN;
    }

    const [hours, minutes, seconds = 0] = timeParts; // default seconds to 0
    const appointmentsInMinutes = hours * 60 + minutes + seconds / 60;

    const now = new Date();
    console.log(appointmentTime, "appointmentTime");

    const nowInMinutes =
      now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60;

    console.log(now, "now");
    console.log(nowInMinutes, "nowInMinutes");
    console.log(appointmentTime, "appointmentTime");
    console.log(appointmentsInMinutes, "appointmentsInMinutes");

    return appointmentsInMinutes - nowInMinutes;
  };

  // console.log(getTimeDifference, "getTimeDifference");

  const {
    data: appointmentsData = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["appointmentsData", date],
    queryFn: ({ queryKey }) => fetchAppointments(""),
    refetchInterval: 20000,
    refetchIntervalInBackground: true,
  });
  const handleCancelAppointment = async (appointment) => {
    setSelectedAppointment(appointment);
    setDeleteAppConfirmationModal(true);
  };

  const DeleteHandler = async () => {
    setDeleteAppLoading(true);
    const token = localStorage.getItem("token");
    console.log("The Appointment is: ", selectedAppointment);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/removeAppointment?appointmentId=${selectedAppointment.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("the RESPon: ", response);
      if (response?.data?.status == "success") {
        toast.success("Appointment Removed successfully.");
        fetchAppointments();
      }
    } catch (error) {
      console.error("Error deleting App: ", error);
    }
    setDeleteAppLoading(false);
    setDeleteAppConfirmationModal(false);
  };

  const handleReschedule = async () => {
    const token = localStorage.getItem("token");
    const durationId = selectedTimeSlotDetails.duration_id;
    const appointmentId = selectedAppointment.id;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/rescheduleAppointment?appointmentId=${appointmentId}&durationId=${durationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status == "success") {
        toast.success("Appointment Rescheduled successfully.");
        fetchAppointments();
        setIsModalOpenForReschedule(false);
        setDeleteAppConfirmationModal(false);
        setReschedule(false);
      }
    } catch (error) {
      console.log("The error from reschedule: ", error);
    }
  };

  useEffect(() => {
    if (!timeToFilter) {
      setFilteredAppointments(appointments); // Show all appointments if no filter is selected
    } else {
      const filtered = appointments.filter(
        (appointment) =>
          subtractHours(appointment.appointmentTime, 6) == timeToFilter
      );
      setFilteredAppointments(filtered);
    }
  }, [timeToFilter, appointments]);

  return (
    <div className="container mx-auto p-6">
      <Toaster richColors position="top-right" />
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="grid grid-cols-4 gap-4 mb-6 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4">
          {fetchAppointmentCountsLoading ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-yellow-500"}
              icon={FaUsers}
              title={"Total"}
              stat={
                fetchAppointmentsCountData?.reduce(
                  (total, item) =>
                    total + (Number(item.appointment_count) || 0),
                  0
                ) || 0
              }
              notes={
                "Coming Up: " +
                (fetchAppointmentsCountData?.find(
                  (item) => item.status === "confirmed"
                )?.appointment_count || 0)
              }
            />
          )}
          {fetchAppointmentCountsLoading ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-green-500"}
              icon={FaRegClock}
              title={"Queue"}
              stat={
                fetchAppointmentsCountData?.find(
                  (item) => item.status === "checked in"
                )?.appointment_count || 0
              }
            />
          )}
          {fetchAppointmentCountsLoading ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-green-200"}
              icon={FaClipboardCheck}
              title={"Completed"}
              stat={
                fetchAppointmentsCountData?.find(
                  (item) => item.status === "checked out"
                )?.appointment_count || 0
              }
              notes={
                "Incompleted: " +
                (fetchAppointmentsCountData?.find(
                  (item) => item.status === "inCompleted"
                )?.appointment_count || 0)
              }
            />
          )}
          {fetchAppointmentCountsLoading ? (
            <CardSkeleton />
          ) : (
            <StatBox
              fromColor={"from-primary"}
              toColor={"to-gray-300"}
              icon={FaExclamationTriangle}
              title={"Expired"}
              stat={
                fetchAppointmentsCountData?.find(
                  (item) => item.status === "expired"
                )?.appointment_count || 0
              }
            />
          )}
        </div>
      </div>

      <div className="flex flex-col sm:flex-row mb-4 w-full max-w-full space-y-2 sm:space-y-0 sm:space-x-2">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-md flex-grow"
        />

        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-blue-500 text-white rounded-md sm:w-auto w-full"
        >
          Search
        </button>
        {console.log("The time selected: ", timeToFilter)}
        <select
          className="px-4 py-2 bg-blue-500 text-white rounded-md sm:w-auto w-full"
          onChange={(e) => setTimeToFilter(e.target.value)}
        >
          <option value="" selected>
            Select Time
          </option>
          {distinctDates.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p className="text-gray-500">Loading...</p>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8 w-full max-w-8xl px-4 sm:px-0">
          {appointments.length > 0 ? (
            filteredAppointments.map((appointment) => (
              <div
                key={appointment.id}
                className={`relative bg-white shadow-md rounded-xl p-8 border transition duration-300 ease-in-out transform hover:shadow-xl ${
                  appointment.checkedIn
                    ? "border-yellow-500"
                    : "border-gray-300"
                }`}
              >
                <div className="flex justify-between items-center">
                  {appointment.appointment_through == "self" ? (
                    <FaStar className="text-yellow-700" />
                  ) : (
                    ""
                  )}
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {appointment.name}
                  </h3>

                  <button
                    onClick={() => handleCancelAppointment(appointment)}
                    className="rounded-full p-2 "
                  >
                    <i>
                      <MdDeleteForever className="size-6 text-red-500" />
                    </i>{" "}
                  </button>
                </div>
                <p className="text-gray-700 mb-1">
                  Service: {appointment.service}
                </p>

                <p className="text-gray-700">
                  Date {appointment.date} on{" "}
                  {subtractHours(appointment.appointmentTime, 6)}
                </p>

                <p className="text-gray-700">CNR: {appointment.cnr}</p>

                <p className="text-gray-700">
                  Documents: {appointment.document_count}
                </p>

                <button
                  className={`mt-4 px-6 py-3 text-white font-medium rounded-lg w-full transition-all duration-200 ease-in-out ${
                    getTimeDifference(
                      appointment.fullData?.appointment_duration?.time_from ||
                        "00:00"
                    ) > 10
                      ? "bg-yellow-500 hover:bg-yellow-600" // Yellow for time difference > 10
                      : getTimeDifference(
                          appointment.fullData?.appointment_duration
                            ?.time_from || "00:00"
                        ) >= -10 &&
                        getTimeDifference(
                          appointment.fullData?.appointment_duration
                            ?.time_from || "00;00"
                        ) <= 10
                      ? "bg-green-500 hover:bg-green-600" // Green for time difference within -10 to 10
                      : "bg-gray-500 cursor-not-allowed" // Gray if time difference < -10
                  }`}
                  onClick={() => handleCheckInClick(appointment)}
                  // Disable button if the appointment is already checked in, or if it is time-specific and the time difference exceeds the allowed limits
                  disabled={
                    appointment.checkedIn ||
                    (appointment.time_specific &&
                      (getTimeDifference(
                        appointment.fullData?.appointment_duration?.time_from ||
                          "00:00"
                      ) < -50 ||
                        getTimeDifference(
                          appointment.fullData?.appointment_duration
                            ?.time_from || "00:00"
                        ) > 50))
                  }
                >
                  {appointment.checkedIn ? "Checked In" : "Check In"}
                </button>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No appointments found.</p>
          )}
        </div>
      )}

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Confirm Check In</h3>
            <p>
              Are you sure you want to check in {selectedAppointment?.name}?
            </p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded ${
                  confirmLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleConfirmCheckIn}
                disabled={confirmLoading} // Disable button while loading
              >
                {confirmLoading ? "Loading..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteAppConfirmationModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex w-full justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setDeleteAppConfirmationModal(false);
                  setReschedule(false);
                }}
              >
                <icon>
                  <MdOutlineCancel className="text-red-600 font-bold" />
                </icon>
              </button>
            </div>
            {!reschedule && (
              <>
                <h3 className="text-xl font-semibold mb-4 text-red-600">
                  Removing Appointment?
                </h3>
                <p>Are you sure you want to Remove this Appointment?</p>
                <p>Name: {selectedAppointment?.name}</p>
                <div className="flex justify-between mt-5">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => setDeleteAppConfirmationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2 cursor-pointer"
                    onClick={() => setReschedule(true)}
                  >
                    Reschedule
                  </button>
                  <button
                    className={`bg-red-500 text-white px-4 py-2 rounded ${
                      deleteAppLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={DeleteHandler}
                    disabled={deleteAppLoading} // Disable button while loading
                  >
                    {deleteAppLoading ? "Loading..." : "Delete"}
                  </button>
                </div>
              </>
            )}
            {reschedule && (
              <div className="mt-4">
                {console.log("The selected AAPP: ", selectedAppointment)}
                <h3 className="text-2xl font-semibold text-green-500 mb-4 text-center">
                  Please Select Time to Reschedule.
                </h3>
                <TimeSlotSelection
                  selectedDate={new Date()}
                  selectedBranch={
                    selectedAppointment.fullData.branch_service.branch.id
                  }
                  selectedService={
                    selectedAppointment.fullData.branch_service_id
                  }
                  selectedTimeSlotDetails={selectedTimeSlotDetails}
                  setSelectedTimeSlotDetails={setSelectedTimeSlotDetails}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  setIsModalOpen={setIsModalOpenForReschedule}
                />
                <div className="flex justify-center mt-5">
                  <button
                    onClick={handleReschedule}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                  >
                    Reschedule
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentManager;
