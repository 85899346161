import React from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/common/Table";

import TimeOfDayTag from "../../Components/common/TimeOfDayTag";

const Dates = ({ dates, type = null, onDelete }) => {
  console.log("DATE: ", dates)
  return (
    <Table className="p-4 border  border-gray-200 rounded-lg">
      <TableHeader className="border-none">
        <TableRow className=" bg-gray-100">
          <TableHead>No.</TableHead>
          {type == 'offdate' &&
            <TableHead>Branch</TableHead>
          }
          <TableHead>Dates</TableHead>
          <TableHead>Full Day</TableHead>
          <TableHead>Created at</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {dates.map((date, index) => (
          <TableRow key={date.id}>
            <TableCell>{index + 1}</TableCell>
            {type == 'offdate' &&
              <TableCell >{date?.branch?.name}</TableCell>
            }
            <TableCell>{type == null ? date.dates : date.off_date}</TableCell>
            <TableCell>
              {date.is_morning && date.is_afternoon ? "Full Day" : date.is_morning ? "Morning" : "Afternoon"}
            </TableCell>
            <TableCell>{date?.created_at}</TableCell>
            {type == 'offdate' &&
              <TableCell>
                <button
                  onClick={() => onDelete(date.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </TableCell>
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Dates;
