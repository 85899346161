import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "./Button";

function ListHeader({
  titleHeader,
  searchOnChange,
  setListLimit,
  searchQuery,
  setSearchQuery,
  isFromAppointmentDuration,
  ...props
}) {
  console.log("searching", props);

  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;
    setListLimit(selectedValue);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    searchOnChange(searchQuery.trim()); // Call the searchOnChange prop function with trimmed search input
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="flex p-4 items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white light:bg-gray-900">
      <div>
        <p className="text-lg font-medium text-gray-800 light:text-white">
          {titleHeader}
          <select
            className="border ml-2 text-md rounded p-1"
            onChange={handleFilterChange}
          >
            <option className="text-sm " value="10">
              10
            </option>
            <option className="text-sm " value="15">
              15
            </option>
            <option className="text-sm " value="20">
              20
            </option>
            <option className="text-sm " value="25">
              25
            </option>
            <option className="text-sm " value="30">
              30
            </option>
            <option className="text-sm " value="40">
              40
            </option>
            <option className="text-sm " value="50">
              50
            </option>
            <option className="text-sm " value="75">
              75
            </option>
            <option className="text-sm " value="100">
              100
            </option>
          </select>
        </p>
      </div>

      {isFromAppointmentDuration ? (
        <div>
          <div className="flex justify-center ">
            <label htmlFor="default-search" className="sr-only">
              Search Branch
            </label>
            <select
              value={props.selectedBranch}
              onChange={(e) => props.setSelectedBranch(e.target.value)}
              className="w-64 p-1 border border-gray-300 rounded-md shadow-sm m-1"
            >
              <option value="">Select a branch.</option>
              {props.branches?.map((eachBranchData, index) => (
                <option key={index} value={eachBranchData.id}>
                  {eachBranchData.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-center ">
            {props.fromService || props.fromBranch ? (
              <div className="flex justify-center">
                <label className="sr-only" htmlFor="selectOrganization">
                  Filter Based On Organization
                </label>
                <select
                  value={props.selectedOrganization}
                  onChange={(e) =>
                    props.setSelectedOrganization(e.target.value)
                  }
                  className="w-60 p-1 border border-gray-300 rounded-md shadow-sm m-1"
                >
                  <option value="">Select Organization</option>
                  {props.organziationData?.data.map((eachOrgData, index) => (
                    <option key={index} value={eachOrgData.id}>
                      {eachOrgData.organizationName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            <label htmlFor="default-search" className="sr-only">
              Search
            </label>
            <form className="max-w-sm mx-auto" onSubmit={handleSearchSubmit}>
              <div className="flex flex-row items-center">
                <input
                  type="search"
                  id="default-search"
                  value={searchQuery}
                  onChange={handleInputChange}
                  className="block w-full p-2 ps-5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-blue-500 light:focus:border-blue-500"
                  placeholder="Search"
                />
                <Button type="submit" variant="outline" className="ml-2">
                  <span className="flex items-center">
                    <MagnifyingGlassIcon className="h-4 w-4 text-center text-primary" />
                    <span className="ml-2">Search</span>
                  </span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListHeader;
