import React, { useState, useEffect } from "react";
import axios from "axios";
import ServiceBarChart from "./ServiceBarChart/ServiceBarChart";
import BranchBarChart from "./BranchBarChart/BranchBarChart";
import PieChart from "./PieChart/PieChart";
import OfficerPerformanceChart from "./OfficerPerformanceChart";
import DatePicker from "react-datepicker";
import StatBox from "../../Components/common/StatBox";
import {
  FaClipboardCheck,
  FaRegClock,
  FaUsers,
  FaExclamationTriangle,
} from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import ServiceReports from "./ServiceChart";
import CardSkeleton from "../../Components/common/CardSkeleton";
import BookedThroughChart from "./BookedThroughChart/PieChart";
import Monthlyappointment from "./MonthlyAppoitment/Monthlyappointment";
import Feedbackreport from "./FeedBackReport/PieChart";
import AgentPerformance from "./AgentPerformance/AgentPerformance";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    waitingQueues: 0,
    appointmentsToday: 0,
  });

  const [appointmentCounts, setAppointmentCounts] = useState([]);
  const [officerPerformanceData, setOfficerPerformanceData] = useState([]);
  const [grayCount, setGrayCount] = useState(0);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  // Function to fetch appointment counts
  const fetchAppointmentCounts = async (startingDate, endingDate) => {
    const token = localStorage.getItem("token");
    // if(star)
    let correctFormatStartingDate = dateChangeHandler(startingDate);
    let correctFormatEndingDate = dateChangeHandler(endingDate);
    let url = "";
    if (startingDate && endingDate) {
      url = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}`;
    } else if (startingDate) {
      url = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?from_date=${correctFormatStartingDate}`;
    } else if (endingDate) {
      url = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?to_date=${correctFormatEndingDate}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("CONSOLE LOG: ", response.data.data);

      if (
        response.data.status === "success" &&
        Array.isArray(response.data.data)
      ) {
        setAppointmentCounts(response.data.data); // Set the appointment counts
      } else {
        console.error("Unexpected response format:", response.data);
      }
      return response.data.data;
    } catch (error) {
      console.error("Error fetching appointment counts:", error);
    }
  };

  // Function to fetch waiting queues data
  const fetchWaitingQueues = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/queues/waitingQueues`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch waiting queues data");
      }

      const data = await response.json();
      console.log("Waiting Queues Data:", data);
      setDashboardData((prevData) => ({
        ...prevData,
        waitingQueues: data.data.waiting_count,
      }));
      return data;
    } catch (error) {
      console.error("Error fetching waiting queues:", error);
    }
  };

  const getTimeDifference = (appointmentTime) => {
    const [hours, minutes, seconds] = appointmentTime.split(":").map(Number);
    const appointmentsInMinutes = hours * 60 + minutes + seconds / 60;

    const now = new Date();
    const nowInMinutes =
      now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60;
    return appointmentsInMinutes - nowInMinutes;
  };
  // Function to fetch appointments with optional search term
  const fetchAppointments = async (search = "") => {
    // setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/branch_filter/search?page=1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const appointmentData = response.data.data.data.map((appointment) => ({
        id: appointment.id,
        name: `${appointment.user.firstname} ${appointment.user.lastname}`,
        cnr: appointment.booking_code,
        appointmentTime: appointment.appointment_duration.time_from,
        service: appointment.branch_service.service.name,
        date: new Date(appointment.appointment_time).toLocaleDateString(),
        checkedIn: false,
        fullData: appointment,
      }));

      const grayAppointments = appointmentData.filter((appointment) => {
        const timeDifference = getTimeDifference(
          appointment.fullData.appointment_duration.time_from
        );
        return timeDifference < -10 || timeDifference > 10;
      });

      // Set gray appointment count
      setGrayCount(grayAppointments.length);

      // setAppointments(appointmentData);
      // setLoading(false);
      return appointmentData;
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  // Function to fetch today's appointments
  const fetchAppointmentsToday = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/`,
        {
          params: { page: 1, limit: 200 },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch appointments data");
      }

      const today = new Date();
      const todayString = today.toISOString().split("T")[0];

      const appointmentsTodayCount = response.data.data.data.filter(
        (appointment) => appointment.appointment_time.startsWith(todayString)
      ).length;
      setDashboardData((prevData) => ({
        ...prevData,
        appointmentsToday: appointmentsTodayCount,
      }));
      return appointmentsTodayCount;
    } catch (error) {
      console.error("Error fetching appointments today:", error);
    }
  };

  // Function to fetch officer performance data
  const fetchOfficerPerformance = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/officer-performance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.status === "success" &&
        Array.isArray(response.data.data)
      ) {
        setOfficerPerformanceData(response.data.data); // Set the officer performance data
      } else {
        console.error("Unexpected response format:", response.data);
      }
      return response.data.data;
    } catch (error) {
      console.error("Error fetching officer performance data:", error);
    }
  };

  // Use Effect to fetch data on component mount
  // useEffect(() => {
  // fetchWaitingQueues();
  // fetchAppointmentsToday();
  // fetchAppointmentCounts();
  // fetchOfficerPerformance(); // Fetch officer performance data
  // }, []);

  const fetchExpriredAppointmentsForBranch = async (
    startingDate,
    endingDate
  ) => {
    const token = localStorage.getItem("token");
    let correctFormatStartingDate = dateChangeHandler(startingDate);
    let correctFormatEndingDate = dateChangeHandler(endingDate);
    let URL = "";
    if (startingDate && endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByBranch?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}`;
    } else if (startingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByBranch?from_date=${correctFormatStartingDate}`;
    } else if (endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByBranch?to_date=${correctFormatEndingDate}`;
    } else {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByBranch`;
    }
    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("THE EXP: ", response.data.data);
    return response.data.data;
  };

  const {
    data: expiredAppointmentsData,
    isLoading: expiredAppointmentsLoading,
  } = useQuery({
    queryKey: ["expiredAppointments", startingDate, endingDate],
    queryFn: ({ queryKey }) =>
      fetchExpriredAppointmentsForBranch(queryKey[1], queryKey[2]),
    refetchInterval: 10000,
  });
  const {
    data: fetchAppointmentsCountDataForBranch = [],
    isLoading: appointmentsCountLoading,
  } = useQuery({
    queryKey: ["fetchAppointmetnsCount", startingDate, endingDate],
    queryFn: ({ queryKey }) => fetchAppointmentCounts(queryKey[1], queryKey[2]),
  });

  const { data: fetchWaitingQueusDataForBranch = [] } = useQuery({
    queryKey: ["fetchWaitingQueus"],
    queryFn: fetchWaitingQueues,
  });
  const { data: fetchAppointmentsTodayDataForBranch = [] } = useQuery({
    queryKey: ["fetchAppointmentsToday"],
    queryFn: fetchAppointmentsToday,
  });

  const { data: fetchOfficerPerformanceForBranch = [] } = useQuery({
    queryKey: ["fetchOfficerPerformanceForBranch"],
    queryFn: fetchOfficerPerformance,
  });

  const { data: fetchAppointmentsForBranch = [] } = useQuery({
    queryKey: ["fetchAppointmentsForBranch"],
    queryFn: fetchAppointments,
  });

  const dateChangeHandler = (date) => {
    if (date === null) {
      return;
    }
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = date?.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="container mx-auto p-4">
      {/* Cards Section */}
      {/* Charts Section */}
      <div className="flex justify-end">
        <DatePicker
          selected={startingDate}
          onChange={setStartingDate}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date From"
          isClearable
        />
        <DatePicker
          selected={endingDate}
          onChange={setEndingDate}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date To"
          isClearable
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 m-4">
        {appointmentsCountLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-yellow-500"}
            icon={FaUsers}
            title={"Total"}
            stat={fetchAppointmentsCountDataForBranch?.reduce(
              (total, item) => total + Number(item.appointment_count || 0),
              0
            )}
            notes={
              "Coming Up: " +
              (fetchAppointmentsCountDataForBranch?.find(
                (item) => item.status === "confirmed"
              )?.appointment_count || 0)
            }
          />
        )}
        {appointmentsCountLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-500"}
            icon={FaRegClock}
            title={"Queue"}
            stat={
              fetchAppointmentsCountDataForBranch?.find(
                (item) => item.status === "checked in"
              )?.appointment_count || 0
            }
          />
        )}
        {appointmentsCountLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-200"}
            icon={FaClipboardCheck}
            title={"Completed"}
            stat={
              fetchAppointmentsCountDataForBranch?.find(
                (item) => item.status === "checked out"
              )?.appointment_count || 0
            }
            notes={
              "Incompleted: " +
              (fetchAppointmentsCountDataForBranch?.find(
                (item) => item.status === "inCompleted"
              )?.appointment_count || 0)
            }
          />
        )}
        {expiredAppointmentsLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-gray-300"}
            icon={FaExclamationTriangle}
            title={"Expired"}
            // stat={grayCount}
            stat={
              fetchAppointmentsCountDataForBranch?.find(
                (item) => item.status === "expired"
              )?.appointment_count || 0
            }
          />
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="p-4 rounded bg-white shadow-lg">
          <PieChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <ServiceReports
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <BookedThroughChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <BranchBarChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <AgentPerformance data={officerPerformanceData} />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <OfficerPerformanceChart data={officerPerformanceData} />{" "}
          {/* Pass the filtered data */}
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <Feedbackreport
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <Monthlyappointment data={officerPerformanceData} />{" "}
          {/* Pass the filtered data */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
