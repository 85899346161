import React, { createContext, useState } from 'react';

export const RouteContext = createContext();

export const RouteProvider = ({ children }) => {
    const [routes, setRoutes] = useState([])
    return (
        <RouteContext.Provider value={{ routes, setRoutes }}>
            {children}
        </RouteContext.Provider>
    );
}