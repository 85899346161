import React, { useState, useEffect } from "react";
import { CogIcon, CalendarIcon, ClockIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";
import ServiceSelection from "./Steps/ServiceSelection";
import DateSelectionForm from "./Steps/DateSelection";
import TimeSlotSelection from "./Steps/TimeSlotSelection";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  appointmentDetails,
  onInputChange,
  userDetails,
  loading,
}) => {
  const [errors, setErrors] = useState({});

  if (!isOpen) return null;
  console.log(loading, "status");

  const normalizePhoneNumber = (mobile) => {
    // Remove all non-numeric characters except leading "+"
    let normalizedMobile = mobile.replace(/[^0-9+]/g, "");

    // Check if the number starts with +251 (Ethiopia's country code)
    if (normalizedMobile.startsWith("+251")) {
      return normalizedMobile; // Already in the correct format
    }

    // Check if the number starts with 0, replace with +251
    if (normalizedMobile.startsWith("0")) {
      normalizedMobile = "+251" + normalizedMobile.slice(1); // Replace leading 0 with +251
    }

    // If the number is 9 digits and doesn't start with + or 0, assume it's local
    if (/^\d{9}$/.test(normalizedMobile)) {
      normalizedMobile = "+251" + normalizedMobile; // Prepend +251
    }

    console.log("Formatted Mobile Number:", normalizedMobile);
    return normalizedMobile;
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!userDetails.first_name) {
      newErrors.first_name = "First name is required.";
    }
    if (!userDetails.last_name) {
      newErrors.last_name = "Last name is required.";
    }

    // Normalize and store the mobile number in userDetails
    let formattedMobile = normalizePhoneNumber(userDetails.mobile);
    userDetails.mobile = formattedMobile; // Update userDetails with the normalized mobile number

    if (!formattedMobile) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\+251\d{9}$/.test(formattedMobile)) {
      newErrors.mobile = "Mobile number must be in the format +251XXXXXXXXX.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirm = () => {
    if (validateInputs()) {
      onConfirm(); // Only call onConfirm if inputs are valid
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Confirm Appointment</h2>
        <p className="mb-2">
          <strong>Fill Out Those Please</strong> {appointmentDetails.branch}
        </p>

        {/* User Information Fields */}
        <input
          type="text"
          placeholder="First Name"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-2 ${
            errors.first_name ? "border-red-500" : ""
          }`}
          name="first_name"
          value={userDetails.first_name}
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.first_name && (
          <p className="text-red-500 text-sm">{errors.first_name}</p>
        )}

        <input
          type="text"
          placeholder="Last Name"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-2 ${
            errors.last_name ? "border-red-500" : ""
          }`}
          name="last_name"
          value={userDetails.last_name}
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.last_name && (
          <p className="text-red-500 text-sm">{errors.last_name}</p>
        )}

        <input
          type="text"
          placeholder="Mobile Number"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-4 ${
            errors.mobile ? "border-red-500" : ""
          }`}
          name="mobile"
          value={userDetails.mobile} // This will display the normalized number
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.mobile && (
          <p className="text-red-500 text-sm">{errors.mobile}</p>
        )}

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 mr-2 rounded"
            disabled={loading}
          >
            Cancel
          </button>

          <button
            onClick={handleConfirm} // Call the handleConfirm function
            className={`px-4 py-2 rounded text-white ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
            }`}
            disabled={loading}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Appointment Confirmed!</h2>
        <p>Your appointment has been successfully scheduled.</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Booking = () => {
  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null); // We will set this from the decoded token
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showAllServices, setShowAllServices] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [normalizedMobile, setNormalizedMobile] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlotId, setTimeSlotId] =useState(null);
  const [selectedExtraSlot, setSelectedExtraSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(selectedBranch, selectedService, selectedTimeSlot, selectedDate);
  const [selectedTimeSlotDetails, setSelectedTimeSlotDetails] = useState({
    time_from: null,
    duration_id: null,
    remainingSlots: null,
    branch_service_per_hour: null,
  });

  function getFormattedDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const formattedDate = getFormattedDate();

  console.log(selectedTimeSlotDetails.duration_id, "duration ID"); // This will give you the duration ID of the selected time slot

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});

  console.log(appointmentDetails, "aapointment Details");

  // User Details State
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
  });

  console.log(userDetails, "user Details");

  // Decode the token to get the branch ID when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming the JWT token is stored in local storage
    if (token) {
      const decodedToken = jwtDecode(token);
      const branchId = decodedToken.data.employee.branch_id;
      setSelectedBranch(branchId);
    }
  }, []);

  // Handle Next for the Stepper
  const nextStep = (SelectedServiceId) => {
    if (step === 1 && SelectedServiceId) {
      // passed parameter as the second argument
      setStep((prev) => prev + 2);
    }
    // else if (step === 2 && selectedDate) {
    //   // fetchAvailableTimeSlots(selectedDate);
    //   setStep((prev) => prev + 1);
    // }
  };

  const prevStep = () => {
    setStep((prev) => prev - 2);
  };

  const initialStep = () => {
    if (step === 3 && selectedTimeSlotDetails) {
      setStep((prev) => prev - 2);
    }
  };

  const openConfirmationModal = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleAppointmentConfirmation = async () => {
    setLoading(true);
    try {
      console.log("Checking for existing appointments...");
      console.log("Selected time slot details:", selectedTimeSlotDetails.remainingSlots);
  
      const remainingSlotsHere = selectedTimeSlotDetails.remainingSlots;
  
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
  
        return `${year}-${month}-${day}`;
      };
  
      const formattedDate = formatDate(selectedDate); // Format the selected date
      console.log(timeSlotId,'Time Slot ID');
      
  
      // Prepare the data for the new API call
      const appointmentData = {
        extra_slot_id: selectedExtraSlot, // Selected extra slot ID
        first_name: userDetails.first_name, // User's first name
        last_name: userDetails.last_name, // User's last name
        mobile: userDetails.mobile, // User's mobile number
        reschedule_count: 0, // Assuming no reschedule
        status: "confirmed", // Status of the appointment
        appointment_through: "callCenter", // Appointment made through call center
        branch_service_id: selectedService, // Selected service ID
        appointment_date: formattedDate, // Formatted appointment date
        appointment_duration_id: timeSlotId, // Appointment duration ID
      };
  
      console.log("Appointment data to send:", appointmentData);
  
      // Send a POST request to the new API
      const appointmentResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/extra_slots/add_special_appointment`,
        appointmentData
      );
  
      if (appointmentResponse.data.status === "success") {
        console.log("Appointment successfully created:", appointmentResponse.data);
        setIsSuccessModalOpen(true); // Open success modal if appointment is confirmed
        setUserDetails({
          first_name: "",
          last_name: "",
          mobile: "",
        });
        initialStep(); // Reset the form steps
      } else {
        console.error("Failed to create appointment:", appointmentResponse.data);
        alert("Failed to create appointment: " + (appointmentResponse.data.message || "Please try again."));
      }
    } catch (err) {
      console.error("Error during appointment creation:", err);
      alert("An error occurred while creating the appointment: " + err.message || "Please try again.");
    } finally {
      setLoading(false);
      setIsModalOpen(false); // Close the confirmation modal
    }
  };
  
  useEffect(() => {
    let now = new Date();
    setSelectedDate(now);
  }, []);

  return (
    <div className="container mx-auto p-8">
      {/* <h1 className="text-4xl font-bold mb-8">Book an Appointment</h1> */}
      {step === 1 && (
        <ServiceSelection
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          nextStep={nextStep}
          setShowAllServices={setShowAllServices}
          showAllServices={showAllServices}
        />
      )}
      {step === 2 && (
        <DateSelectionForm
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {step === 3 && (
        <TimeSlotSelection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedExtraSlot={selectedExtraSlot}
          loading={loading}
          setSelectedTimeSlot={setSelectedTimeSlot}
          setTimeSlotId={setTimeSlotId}
          timeSlotId={timeSlotId}
          selectedTimeSlotDetails={selectedTimeSlotDetails}
          setSelectedExtraSlot={setSelectedExtraSlot}
          setSelectedTimeSlotDetails={setSelectedTimeSlotDetails}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <div className="flex justify-center space-x-6 mt-6">
        {step === 1 ? (
          ""
        ) : (
          <button
            onClick={prevStep}
            className="bg-blue-500 text-white px-6 py-2 rounded"
          >
            Back
          </button>
        )}

        {step === 2 && selectedDate && (
          <button
            onClick={nextStep}
            className="bg-blue-500 text-white px-6 py-2 rounded"
          >
            Next
          </button>
        )}
      </div>


      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleAppointmentConfirmation}
        appointmentDetails={appointmentDetails}
        userDetails={userDetails}
        onInputChange={handleInputChange}
        loading={loading}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          setIsModalOpen(false); // Close the confirmation modal if it's open
        }}
      />
    </div>
  );
};

export default Booking;
