import React, { useContext, useState } from "react";
import ServiceBarChart from "./ServiceBarChart/ServiceBarChart";
import BranchBarChart from "./BranchBarChart/BranchBarChart";
import PieChart from "./PieChart/PieChart";
import ServiceChart from "./ServiceChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import StatBox from "../../Components/common/StatBox";
import {
  FaClipboardCheck,
  FaCloudDownloadAlt,
  FaExclamationTriangle,
  FaRegClock,
  FaUser,
} from "react-icons/fa";
import { AuthContext } from "../../contexts/auth";
import CardSkeleton from "../../Components/common/CardSkeleton";
//import LocationPerformance from "./AreaChart/LocationPerformanceAreaChart";

const fetchBranches = async (token) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches/by-organization-fetch`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};


function Dashboard() {
  const {user,  token } = useContext(AuthContext);

  
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");

  const fetchAppointmentsByOrganization = async (
    token,
    startingDate,
    endingDate,
    selectedBranch
  ) => {
    console.log("Current token:", token); // Debugging line
    let correctFormatStartingDate = dateChangeHandler(startingDate);
    let correctFormatEndingDate = dateChangeHandler(endingDate);
    let URL = "";
  
    // Construct the URL based on the presence of parameters
    if (token && startingDate && endingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}&branch_id=${selectedBranch}`;
    } else if (startingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?from_date=${correctFormatStartingDate}&branch_id=${selectedBranch}`;
    } else if (endingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?to_date=${correctFormatEndingDate}&branch_id=${selectedBranch}`;
    } else if (startingDate && endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}`;
    } else if (startingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?from_date=${correctFormatStartingDate}`;
    } else if (endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?to_date=${correctFormatEndingDate}`;
    } else if (selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization?branch_id=${selectedBranch}`;
    } else {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByOrganization`;
    }
    console.log(token, token, 'token is here');
    
    // API call
    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    const responseData = response.data.data;
    console.log("THE LOG: ", responseData);
  
    return responseData;
  };
  

  const fetchExpiredAppointmentsByOrganization = async (
    token,
    startingDate,
    endingDate,
    selectedBranch
  ) => {
    let correctFormatStartingDate = dateChangeHandler(startingDate);
    let correctFormatEndingDate = dateChangeHandler(endingDate);
    let URL = "";
    if (token && startingDate && endingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}&branch_id=${selectedBranch}`;
    } else if (startingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?from_date=${correctFormatStartingDate}&branch_id=${selectedBranch}`;
    } else if (endingDate && selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?to_date=${correctFormatEndingDate}&branch_id=${selectedBranch}`;
    } else if (startingDate && endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?from_date=${correctFormatStartingDate}&to_date=${correctFormatEndingDate}`;
    } else if (startingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?from_date=${correctFormatStartingDate}`;
    } else if (endingDate) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?to_date=${correctFormatEndingDate}`;
    } else if (selectedBranch) {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization?branch_id=${selectedBranch}`;
    } else {
      URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getExpiredByOrganization`;
    }

    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = response.data.data;
    console.log("THE LOG: ", responseData);

    return responseData;
  };

  const {
    data: branchesData,
    error: branchesError,
    isLoading: isLoadingBranches,
  } = useQuery({
    queryKey: ["branches", token],
    queryFn: ({ queryKey }) => fetchBranches(queryKey[1]),
  });
  
  const { data: organizationData } = useQuery({
    queryKey: [
      "countsByOrganization",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchAppointmentsByOrganization(
        queryKey[1],
        queryKey[2],
        queryKey[3],
        queryKey[4]
      ),
  });

  const {
    data: expiredOrganizationData,
    error: expiredOrganizationError,
    isLoading: isLoadingExpiredOrganization,
  } = useQuery({
    queryKey: [
      "getExpiredByOrganization",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchExpiredAppointmentsByOrganization(
        queryKey[1],
        queryKey[2],
        queryKey[3],
        queryKey[4]
      ),
  });

  const dateChangeHandler = (date) => {
    if (date === null) {
      return;
    }
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = date?.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-end mr-5">
        <select
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
          className="w-64 p-1 border border-gray-300 rounded-md shadow-sm m-1"
        >
          <option value="">Select a branch.</option>
          {branchesData?.map((eachBranchData, index) => (
            <option key={index} value={eachBranchData.id}>
              {eachBranchData.name}
            </option>
          ))}
        </select>
        <DatePicker
          selected={startingDate}
          onChange={(date) => setStartingDate(date)}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date From"
          isClearable
        />
        <DatePicker
          selected={endingDate}
          onChange={(date) => setEndingDate(date)}
          dateFormat="yyyy-MM-dd"
          monthsShown={1}
          className="w-70 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date To"
          isClearable
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 m-4">
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-yellow-500"}
            icon={FaCloudDownloadAlt}
            title={"Total"}
            stat={organizationData?.reduce(
              (total, item) => total + Number(item.appointment_count || 0),
              0
            )}
            notes={
              "Coming Up: " +
              (organizationData?.find((item) => item.status === "confirmed")
                ?.appointment_count || 0)
            }
          />
        )}
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-500"}
            icon={FaRegClock}
            title={"Queue"}
            stat={
              organizationData?.find((item) => item.status === "checked in")
                ?.appointment_count || 0
            }
          />
        )}
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-200"}
            icon={FaClipboardCheck}
            title={"Completed"}
            stat={
              organizationData?.find((item) => item.status === "checked out")
                ?.appointment_count || 0
            }
            notes={
              "Incompleted: " +
              (organizationData?.find((item) => item.status === "inCompleted")
                ?.appointment_count || 0)
            }
          />
        )}
        {isLoadingBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-gray-300"}
            icon={FaExclamationTriangle}
            title={"Expired"}
            stat={
              organizationData?.find((item) => item.status === "expired")
                ?.appointment_count || 0
            }
          />
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="p-4 rounded bg-white shadow-lg">
          <PieChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <ServiceChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <BranchBarChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        <div className="p-4 rounded bg-white shadow-lg">
          <ServiceBarChart
            startingDate={dateChangeHandler(startingDate)}
            endingDate={dateChangeHandler(endingDate)}
            selectedBranch={selectedBranch}
          />
        </div>
        {/* <div className="p-4 rounded bg-white shadow-lg">
          <LocationPerformance />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
