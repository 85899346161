import { CogIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "../../dashboard/LoadingSpinner";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import {
  FaMicrochip,
  FaShoppingCart,
  FaGift,
  FaHome,
  FaHandHoldingUsd,
} from "react-icons/fa";

const ServiceSelection = ({
  setSelectedService,
  selectedService,
  nextStep,
}) => {
  const { token } = useContext(AuthContext); // Get the token from the AuthContext
 
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [serviceSearchQuery, setServiceSearchQuery] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming the JWT token is stored in local storage
    if (token) {
      const decodedToken = jwtDecode(token);
      const branchId = decodedToken.data.employee.branch_id;
      setSelectedBranch(branchId);
    }
  }, []);


  // Fetch service data based on selected branch
  useEffect(() => {
    const fetchServices = async () => {
      if (selectedBranch) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/v1/branch_services/findServicesByBranchGuest?branch_id=${selectedBranch}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          if (result.services && Array.isArray(result.services)) {
            const services = result.services.map((service) => ({
              id: service.id,
              name: service.name,
            }));
            setServiceOptions(services);
          } else {
            console.error("Unexpected services data format:", result);
          }
        } catch (error) {
          console.error("Error fetching services:", error.message);
        }
      }
    };

    fetchServices();
  }, [selectedBranch]);

  // Filter services based on search query
  const filteredServices = serviceOptions.filter((service) =>
    service.name.toLowerCase().includes(serviceSearchQuery.toLowerCase())
  );

  return (
    <div>
      {/* Service Selection UI */}
      <div>
        {filteredServices.length === 0 && (
          <div className="flex justify-center align-center mt-20">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredServices.map((service) => (
          <div
            key={service.id}
            className={`p-6 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
              selectedService === service.id
                ? "border-blue-500"
                : "border-gray-300"
            }`}
            onClick={() => {
              setSelectedService(service.id);
              nextStep(service.id); // Add parameter
            }}
          >
            <div className="flex items-center space-x-4">
              {service.name === "PLC" ? (
                <FaMicrochip className="h-10 w-10 text-blue-500" />
              ) : service.name === "Sales" ? (
                <FaShoppingCart className="h-10 w-10 text-blue-500" />
              ) : service.name === "Gift" ? (
                <FaGift className="h-10 w-10 text-blue-500" />
              ) : service.name === "Rental" ? (
                <FaHome className="h-10 w-10 text-blue-500" />
              ) : service.name === "Loan" ? (
                <FaHandHoldingUsd className="h-10 w-10 text-blue-500" />
              ) : (
                <CogIcon className="h-10 w-10 text-blue-500" />
              )}
              <h3 className="text-xl font-semibold">{service.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceSelection;
