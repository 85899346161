import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import "jspdf-autotable";
import { TrendingUp } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns"; // Import date-fns for formatting timestamps
import CardSkeleton from "../../Components/common/CardSkeleton";
import StatBox from "../../Components/common/StatBox";
import TableSection from "../../Components/common/TableSection";
import TableSkeleton from "../../Components/common/TableSekeleton";
import { AuthContext } from "../../contexts/auth";

// Fetch Appointments
const fetchAppointments = async ({ queryKey }) => {
  const [
    _,
    page,
    perPage,
    searchQuery,
    selectedBranch,
    startingDate,
    endingDate,
  ] = queryKey;

  try {
    let url = `${process.env.REACT_APP_API_URL}/v1/reports/AppointmentReports?page=${page}&limit=${perPage}`;
    if (searchQuery) url += `&searchQuery=${searchQuery}`;
    if (selectedBranch) url += `&branch_id=${selectedBranch}`;
    if (startingDate) url += `&from_date=${startingDate}`;
    if (endingDate) url += `&to_date=${endingDate}`;

    const token = localStorage.getItem("token");
    if (!token) throw new Error("No auth token found in localStorage");

    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = data?.data.map((appointment) => ({
      ...appointment,
      appointment_time: appointment.appointment_time
        ? format(new Date(appointment.appointment_time), "MMM dd, yyyy HH:mm")
        : "N/A",
      checked_in_time: appointment.checked_in_time
        ? format(new Date(appointment.checked_in_time), "MMM dd, yyyy HH:mm")
        : "N/A",
      checked_out_time: appointment.checked_out_time
        ? format(new Date(appointment.checked_out_time), "MMM dd, yyyy HH:mm")
        : "N/A",
    }));

    return {
      totalItems: Number(data?.pagination?.total) || 0,
      totalPages: Number(data?.pagination?.totalPages) || 1,
      selfAppointments: Number(data?.pagination?.self_appointments) || 0,
      callAppointments: Number(data?.pagination?.call_center_appointments) || 0,
      data: formattedData,
    };
  } catch (error) {
    console.error("Error fetching appointments:", error);
    throw error;
  }
};

const ManageStations = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const dateChangeHandler = (date) => (date ? date.toISOString().split("T")[0] : null);

  const { user } = useContext(AuthContext);
  const ALLOWED_ROLE_IDS = [
    "cb57b04b-3418-42b9-83e9-d770aa54875a",
    "b7dffb6d-8c49-4705-ae2b-ebd70555cac7",
  ];
  
  useEffect(() => {
    if (!ALLOWED_ROLE_IDS.includes(user?.role?.id)) {
      setSelectedBranch(user?.employee?.branch_id);
    }
  }, [user]);

  const { isLoading: isBranchesLoading, data: branchesData } = useQuery({
    queryKey: ["branches"],
    queryFn: async () => {
      const url = `${process.env.REACT_APP_API_URL}/v1/branches`;
      const response = await axios.get(url);
      return response.data.data;
    },
  });

  const { token } = useContext(AuthContext);

  const { data: appointmentData, error, isLoading } = useQuery({
    queryKey: [
      "appointments",
      currentPage,
      rowsPerPage,
      searchQuery,
      selectedBranch,
      startingDate,
      endingDate,
    ],
    queryFn: fetchAppointments,
    keepPreviousData: true,
    enabled: !!token,
  });

  const HEADERS = [
    { name: "Name", dataIndex: "name" },
    { name: "CNR", dataIndex: "cnr" },
    { name: "Status", dataIndex: "status" },
    { name: "Appointment Time", dataIndex: "appointment_time" },
    { name: "Appointment Through", dataIndex: "appointment_through" },
    { name: "Branch Name", dataIndex: "branch_name" },
    { name: "Service Name", dataIndex: "service_name" },
    { name: "Time Difference (minutes)", dataIndex: "time_difference_minutes" },
    { name: "Officer Name", dataIndex: "officer_name" },
    { name: "Checked In Time", dataIndex: "checked_in_time" },
    { name: "Checked Out Time", dataIndex: "checked_out_time" },
  ];

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > appointmentData?.totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex space-x-4 mb-10 mt-10 flex-wrap gap-4 items-center">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
        <TableSkeleton />
      </div>
    );
  }

  if (error) return <div>Error fetching data: {error.message}</div>;

  const totalAppointments = appointmentData?.totalItems || 0;
  const selfAppointments = appointmentData?.selfAppointments || 0;
  const callAppointments = appointmentData?.callAppointments || 0;
  const totalPages = appointmentData?.totalPages;

  return (
    <div className="p-4">
      <div className="flex space-x-4 mb-10 mt-10 flex-wrap gap-4 items-center">
        <StatBox
          title="Total Appointments"
          stat={totalAppointments}
          fromColor="from-indigo-500"
          toColor="to-purple-500"
          icon={TrendingUp}
        />
        <StatBox
          title="Self Appointments"
          stat={selfAppointments}
          fromColor="from-green-400"
          toColor="to-blue-500"
          icon={TrendingUp}
        />
        <StatBox
          title="Walking Appointments"
          stat={callAppointments}
          fromColor="from-yellow-400"
          toColor="to-red-500"
          icon={TrendingUp}
        />
      </div>
      <div>
      {ALLOWED_ROLE_IDS.includes(user?.role?.auth_role_id) && (
  <select
    value={selectedBranch}
    onChange={(e) => setSelectedBranch(e.target.value)}
    className="w-64 p-1 h-14 border border-gray-300 rounded-md shadow-sm m-1"
  >
    <option value="">Select a branch</option>
    {branchesData?.map((branch, index) => (
      <option key={index} value={branch.id}>
        {branch.name}
      </option>
    ))}
  </select>
)}
        <DatePicker
          selected={startingDate}
          onChange={(date) => setStartingDate(dateChangeHandler(date))}
          dateFormat="yyyy-MM-dd"
          className="w-60 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date From"
          isClearable
        />
        <DatePicker
          selected={endingDate}
          onChange={(date) => setEndingDate(dateChangeHandler(date))}
          dateFormat="yyyy-MM-dd"
          className="w-60 border border-gray-300 rounded-lg p-4 m-1"
          placeholderText="Filter By Date To"
          isClearable
        />
      </div>
      <TableSection
        headers={HEADERS}
        currentData={appointmentData?.data}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default ManageStations;
