import { ClockIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

const fetchDuration = async (query) => {
  let url = `${process.env.REACT_APP_API_URL}/v1/appointments/durationListActiveOnly?branch_id=${query}&limit=25`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Error fetching Durations");
  }
  const realResponse = await response.json();
  return realResponse.data;
};

const TimeSlotSelection = ({
  selectedDate,
  selectedBranch,
  selectedService,
  setSelectedTimeSlot,
  selectedTimeSlotDetails,
  setSelectedTimeSlotDetails,
  setIsModalOpen,
  isEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const [branchId, setBranchId] = useState(selectedBranch);
  const {
    data: durationData,
    error: durationError,
    isLoading: isLoadingDuration,
  } = useQuery({
    queryKey: ["Durations", branchId],
    queryFn: () => fetchDuration(branchId),
  });

  // Fetch available time slots based on selected date and service
  const [onlyTimeSlots, setOnlyTimeSlots] = useState(null);
  const availabeTimeSlots = (data) => {
    console.log("the data:");
    const allTimeSlots = data?.map((eachTimeSlot) => {
      const [hour] = eachTimeSlot.time_from.split(":").map(Number);
      const period = hour < 12 ? "morning" : "afternoon";
      return {
        time: `${eachTimeSlot.time_from} - ${eachTimeSlot.time_to}`,
        duration_id: eachTimeSlot.id,
        time_from: eachTimeSlot.time_from,
        period,
      };
    });
    setOnlyTimeSlots(allTimeSlots);
  };

  useEffect(() => {
    availabeTimeSlots(durationData);
  }, [durationData]);

  const morningSlots = onlyTimeSlots?.filter(
    (slot) => slot.period === "morning"
  );
  const afternoonSlots = onlyTimeSlots?.filter(
    (slot) => slot.period === "afternoon"
  );

  const adjustTime = (timeString) => {
    const [startTime] = timeString.split(" - ");
    const [startHour, startMinute] = startTime.split(":").map(Number);
    let adjustedHour = (startHour - 6 + 24) % 24;

    const adjustedStartTime = `${adjustedHour
      .toString()
      .padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`;
    return adjustedStartTime;
  };

  return (
    <div>
      {/* <h2 className="text-3xl font-bold mb-6">Select a Time Slot</h2> */}
      {isLoadingDuration ? (
        <div>Loading available time slots...</div>
      ) : onlyTimeSlots?.length > 0 ? (
        <div className="space-y-8">
          {/* Morning Slots */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Morning Slots</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {morningSlots.map((timeSlot, index) => (
                <div
                  key={index}
                  className={`p-4 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
                    selectedTimeSlotDetails.time_from === timeSlot.time_from
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(timeSlot); // Set selected time slot
                    console.log(timeSlot, "IS There");

                    setSelectedTimeSlotDetails({
                      time_from: timeSlot.time_from,
                      duration_id: timeSlot.duration_id,
                      remaining_slots: timeSlot.remainingSlots,
                    });
                    if (!isEdit) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <ClockIcon className="h-8 w-8 text-blue-500" />
                    <h3 className="text-lg font-semibold">
                      {adjustTime(timeSlot.time)}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Afternoon Slots */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Afternoon Slots</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {afternoonSlots.map((timeSlot, index) => (
                <div
                  key={index}
                  className={`p-4 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
                    selectedTimeSlotDetails.time_from === timeSlot.time_from
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(timeSlot); // Set selected time slot
                    console.log("Test Here");

                    setSelectedTimeSlotDetails({
                      time_from: timeSlot.time_from,
                      duration_id: timeSlot.duration_id,
                      remaining_slots: timeSlot.remainingSlots,
                    });
                    if (!isEdit) {
                      setIsModalOpen(true); // Open modal after selecting the time slot
                    }
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <ClockIcon className="h-8 w-8 text-blue-500" />
                    <h3 className="text-lg font-semibold">
                      {adjustTime(timeSlot.time)}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>No available time slots for the selected date.</p>
      )}
    </div>
  );
};

export default TimeSlotSelection;
