import React from "react";

const AppointmentLabel = ({ cx, cy, totalAppointments }) => (
  <text x={cx} y={cy} textAnchor="middle" dominantBaseline="middle">
    <tspan x={cx} y={cy} className="fill-foreground text-3xl font-bold">
      {totalAppointments.toLocaleString()}
    </tspan>
    <tspan x={cx} y={cy + 24} className="fill-muted-foreground">
      Appointments
    </tspan>
  </text>
);

export default AppointmentLabel;
