import React from "react";

function StatBox({ title, stat, fromColor, toColor, icon: Icon, notes }) {
  return (
    <div
      className={`bg-gradient-to-r ${fromColor} ${toColor} p-4 rounded-lg  text-white`}
    >
      <div className="flex items-center justify-between">
        <div>
          <h2 className=" text-lg font-normal">{title}</h2>
          <h1 className="text-3xl font-bold mt-2">{stat}</h1>
        </div>
        <div className="bg-white p-3 rounded-full">
          {Icon && <Icon className="h-8 w-8  text-primary" />}
        </div>
      </div>
      <div>{notes}</div>
    </div>
  );
}

export default StatBox;
