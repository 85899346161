import React, { useState } from "react";
import Button from "../../Components/common/Button";
import axios from "axios";
import { toast } from "sonner";
import TableSekeleton from "../../Components/common/TableSekeleton";
import Dates from "./Dates";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../Components/lib/LoadingSpinner";
const fetchOffDates = async (page, limit, branch_id = null) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/appointments/getAllOffDays?page=${page}&limit=${limit}&branch_id=${branch_id}`
  );
  if (!response.ok) {
    throw new Error("Error fetching off dates");
  }
  return response.json();
};


const AddOffDates = ({ handleAddModalDates ,branch_id}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullDay, setIsFullDay] = useState(true);
  const [isMorning, setIsMorning] = useState(false);
  const [isAfternoon, setIsAfternoon] = useState(false);

  const [formData, setFormData] = useState({
    branch_id: branch_id || null,
    off_date: "",
    is_morning: true,
    is_afternoon: true,
  });
  const {
    data: dates,
    error: dateError,
    isLoading: isLoadingDates,
    refetch,
  } = useQuery({
    queryKey: ["Dates", currentPage, itemsPerPage, selectedBranch],
    queryFn: () => fetchOffDates(currentPage, itemsPerPage, selectedBranch),
  });

  const totalPages = dates ? Math.ceil(dates?.totalItems / itemsPerPage) : 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setSelectedBranch(selectedBranchId);
    setFormData({
      ...formData,
      branch_id: selectedBranchId,
    });
    refetch();
  };

  const handleChange = (e) => {
    const { name } = e.target;
    let value = "";

    value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/creatOffDays`,
        formData
      );
      if (response.status === 200 || response.status === 201) {
        if (response.data.statusCode == 1) {
          toast.success("Off date added successfully");
          refetch();
        } else {
          toast.error(response.data.message);
        }
      } else {
      }
    } catch (error) {
      toast.error("Failed to add dates. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const OnDelete = async (id) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this duration?"
      );

      if (!confirmDelete) {
        // If the user clicks "No", simply return without deleting
        return;
      }
      const url = `${process.env.REACT_APP_API_URL}/v1/appointments/deleteOffdays/${id}`;
      const method = "delete";

      const response = await axios({
        url,
        method,
      });
      if (response.status === 200) {
        if (response.data.status == "error") {
          toast.error(response.data.message);
        } else {
          toast.success(`off date Deleted successfully`);
          refetch();
        }
      } else {
        toast.error("Failed to add dates. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to add dates. Please try again later.");
    }
  };

  const handleFullDay = (e) => {
    setFormData({ ...formData, is_morning: true, is_afternoon: true });
    setIsFullDay(e.target.checked);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent backdrop-blur-sm z-50">
      <div className="relative p-4 w-full max-w-3xl max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 md:p-5 border-b border-gray-300">
            <h3 className="text-lg font-semibold text-gray-900">
              Add New Off Dates
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleAddModalDates}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5" onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="off_date"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Off Date (YYYY-MM-DD)
                </label>
                <input
                  type="date" // Changed from 'text' to 'date'
                  name="off_date"
                  id="off_date"
                  className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="YYYY-MM-DD" // This may not be needed for date input
                  value={formData.start_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-span-2 sm:col-span-1 flex align-center">
                <input
                  type="checkbox"
                  id="fullday"
                  name="fullday"
                  className="cursor-pointer"
                  checked={isFullDay}
                  onChange={handleFullDay}
                />
                <label
                  htmlFor="fullday"
                  className={`m-2  text-sm font-medium text-black cursor-pointer ${
                    !isFullDay ? "line-through" : ""
                  }`}
                >
                  Full Day
                </label>
              </div>
              {!isFullDay && (
                <div className="flex align-center">
                  <input
                    type="checkbox"
                    id="isMorning"
                    name="isMorning"
                    className="cursor-pointer"
                    checked={formData.is_morning}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_morning: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="isMorning"
                    className="m-2  text-sm font-medium text-black cursor-pointer"
                  >
                    Morning
                  </label>
                  <input
                    type="checkbox"
                    id="isAfternoon"
                    name="isAfternoon"
                    className="cursor-pointer"
                    checked={formData.is_afternoon}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_afternoon: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor="isAfternoon"
                    className="m-2  text-sm font-medium text-black cursor-pointer"
                  >
                    Afternoon
                  </label>
                </div>
              )}
            </div>
            <Button type="submit">
              <svg
                className="me-1 -ms-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>

              {isLoading ? <LoadingSpinner /> : `Add off Date`}
            </Button>
          </form>
          {isLoadingDates ? (
            <TableSekeleton />
          ) : (
            <Dates
              dates={dates?.data || []}
              type={"offdate"}
              onDelete={OnDelete}
            />
          )}
          <nav
            aria-label="Page navigation example"
            className="flex justify-center py-2 "
          >
            <ul className="flex items-center -space-x-px h-8 text-sm">
              {/* ... pagination buttons ... */}
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 leading-tight ${
                      currentPage === index + 1
                        ? "text-blue-600 border border-blue-300 bg-blue-50"
                        : "text-gray-500 bg-white border border-gray-300"
                    } hover:bg-gray-100 hover:text-gray-700`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              {/* ... pagination buttons ... */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AddOffDates;
