"use client";

import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../charts/Chart";
import LoadingSpinner from "../LoadingSpinner";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";

const fetchAllBranchAppointments = async (
  token,
  startingDate,
  endingDate,
  selectedBranch
) => {
  let URL = "";
  if (startingDate && endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?from_date=${startingDate}&to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?from_date=${startingDate}&branch_id=${selectedBranch}`;
  } else if (endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?from_date=${startingDate}&to_date=${endingDate}`;
  } else if (startingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?from_date=${startingDate}`;
  } else if (endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?to_date=${endingDate}`;
  } else if (selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization?branch_id=${selectedBranch}`;
  } else {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/dailyAppointmentsByOrganization`;
  }

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const updateKeyValues = response.data.data.map((eachAppointment) => ({
    date: eachAppointment.appointment_date,
    appointments: eachAppointment.appointment_count,
    confirmed_count: eachAppointment.confirmed_count,
    checkedin_count: eachAppointment.checkedin_count,
    checkedout_count: eachAppointment.checkedout_count,
    expired_count: eachAppointment.expired_count,
    incomplted_count: eachAppointment.incomplted_count,
    rejected_count: eachAppointment.rejected_count,
    cancelled_count: eachAppointment.cancelled_count,
  }));
  return updateKeyValues;
};

export default function BranchBarChart({
  startingDate,
  endingDate,
  selectedBranch,
}) {
  const { token } = useContext(AuthContext);

  const {
    data: branchAppointment,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "branchappointments",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchAllBranchAppointments(
        queryKey[1],
        queryKey[2],
        queryKey[3],
        queryKey[4]
      ),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) return <div>Error loading data</div>;

  const chartData = branchAppointment;

  const chartConfig = {
    appointments: {
      label: "Appointments",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Daily Appointments</CardTitle>
        <CardDescription>Number of appointments per day</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, bottom: 25 }}
          >
            <XAxis
              dataKey="date"
              tick={true}
              tickLine={false}
              axisLine={false}
              interval={0}
              angle={-30}
              textAnchor="end"
              dy={7}
              fontSize={"9px"}
              className="text-xs"
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              ticks={[0, 200, 400, 600, 800, 1000]}
            />
            <Bar dataKey="appointments" fill="#30f254" radius={4} />
            <Bar dataKey="confirmed_count" stackId="a" fill="#e5fa02" />
            <Bar dataKey="checkedin_count" fill="#ffc658" />
            <Bar dataKey="checkedout_count" fill="#f081e7" />
            <Bar dataKey="expired_count" fill="#BBB5F7" />
            <Bar dataKey="incomplted_count" fill="#82ca9d" />
            <ChartTooltip
              content={<ChartTooltipContent indicator="line" />}
              cursor={false}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
