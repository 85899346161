import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { NavigationSidebarProvider } from "./contexts/navigationSidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Layout from "./Components/layout/index";
import { RouteProvider } from "./contexts/route";
import "./index.css";

const queryClient = new QueryClient();

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <NavigationSidebarProvider>
          <RouteProvider>
            <QueryClientProvider client={queryClient}>
              <Layout />
            </QueryClientProvider>
          </RouteProvider>
        </NavigationSidebarProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
