import { Bars4Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { NavigationSidebarContext } from "../../contexts/navigationSidebar";
import { DefaultViews, RoleBasedViews } from "../../pages/views";

// Reusable SidebarItem component
const SidebarItem = ({
  Icon,
  label,
  to,
  isActive,
  onClick,
  isSidebarOpen,
  isMobile,
  isTablet,
}) => (
  <Link
    to={to}
    onClick={onClick}
    className={`font-semibold transition-all px-2 rounded-lg rounded-tr-none rounded-br-none py-2 flex items-center space-x-2 ${
      isActive
        ? "text-primary bg-white hover:bg-pink-700"
        : "text-white hover:bg-slate-400"
    }`}
  >
    {Icon && <Icon className={`${isSidebarOpen ? "size-5" : "size-6"}`} />}
    {(isSidebarOpen || isMobile || isTablet) && (
      <div className="flex truncate">{label}</div>
    )}
  </Link>
);

function NavigationSidebar() {
  const {
    isNavigationSidebarOpen,
    setNavigationSidebarOpen,
    isNavigationSidebarMinimized,
    setNavigationSidebarMinimized,
  } = useContext(NavigationSidebarContext);
  const { user } = useContext(AuthContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = screenWidth < 768;
  const isTablet = screenWidth >= 768 && screenWidth <= 1024;

  const handleLinkClick = () => {
    if (isMobile) {
      setNavigationSidebarOpen(false); // Close sidebar on mobile after clicking a link
    }
  };

  const rolesMenu = useMemo(() => {
    return Object.keys(RoleBasedViews[user.role.auth_role_id].routes).map(
      (key) => {
        const { icon, label, bool } =
          RoleBasedViews[user.role.auth_role_id].routes[key];
        return { Icon: icon, label, to: key, bool };
      }
    );
  }, [user.role.auth_role_id]);

  const defaultMenu = useMemo(() => {
    return Object.keys(DefaultViews).map((key) => {
      const { icon, label } = DefaultViews[key];
      return { Icon: icon, label, to: key };
    });
  }, []);

  const sidebarItems = useMemo(() => {
    const mergedMenu = [...rolesMenu];
    defaultMenu.forEach((defaultRoute) => {
      if (!mergedMenu.some((roleRoute) => roleRoute.to === defaultRoute.to)) {
        mergedMenu.push(defaultRoute);
      }
    });
    return mergedMenu;
  }, [rolesMenu, defaultMenu]);

  return (
    <div
      className={`${user?.role?.auth_role_id==='af43ff03-13d4-4c22-ab35-ae9e59408b20'?'hidden':''}  bg-primary text-white ${
        isMobile && !isNavigationSidebarOpen
          ? " w-full h-16 fixed top-0 left-0 flex items-center justify-between px-4"
          : isMobile && isNavigationSidebarOpen
          ? "z-50 w-full h-full fixed top-0 left-0 py-6 px-6"
          : isNavigationSidebarMinimized
          ? "w-64 py-6 pl-6"
          : "w-20 py-6 pl-6"
      } space-y-6 inset-y-0 left-0 transform transition-transform duration-200 ease-in-out`}
    >
      {isMobile && !isNavigationSidebarOpen && (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-2">
            <a href="/">
              <img
                src={process.env.PUBLIC_URL + "/icon.png"}
                className="size-8 md:size-12"
                alt="Qetero logo"
              />
            </a>
            <h1 className="text-xl md:text-2xl font-bold">Qetero</h1>
          </div>
          <button
            className="text-white"
            onClick={() => setNavigationSidebarOpen(!isNavigationSidebarOpen)}
          >
            <Bars4Icon className="h-6 w-6" />
          </button>
        </div>
      )}

      {(isMobile && isNavigationSidebarOpen) || !isMobile ? (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center space-x-2">
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/icon.png"}
                  className="size-12"
                  alt="Qetero logo"
                />
              </a>
              {(isNavigationSidebarMinimized || isMobile || isTablet) && (
                <h1 className="text-2xl font-bold">Qetero</h1>
              )}
            </div>
            {isMobile && (
              <button
                className="text-white"
                onClick={() => setNavigationSidebarOpen(false)}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          <ul className="space-y-3">
            {sidebarItems.map(
              (item) =>
                (item.bool === undefined || item.bool === true) && (
                  <SidebarItem
                    key={item.to}
                    Icon={item.Icon}
                    to={item.to}
                    label={item.label}
                    isActive={location.pathname === item.to}
                    onClick={handleLinkClick}
                    isSidebarOpen={isNavigationSidebarMinimized}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                )
            )}
          </ul>
        </>
      ) : null}
    </div>
  );
}

export default NavigationSidebar;
