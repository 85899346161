import { ClockIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useEffect, useState } from "react";

const TimeSlotSelection = ({
  selectedDate,
  //   availableTimes,
  //   loading,
  selectedBranch,
  selectedService,
  setSelectedTimeSlot,
  selectedTimeSlotDetails,
  setSelectedTimeSlotDetails,
  setIsModalOpen,
  isEdit,
}) => {
  // console.log("The date selected: ", selectedDate.getDay());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  // Fetch available time slots based on selected date and service
  const fetchAvailableTimeSlots = async (date) => {
    setLoading(true);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };
    const dateSelected = formatDate(selectedDate);
    console.log("date Selecrted", dateSelected);

    try {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/appointments/availableTimeSlotsByDateWithPercentage?branch_id=${selectedBranch}&branch_service_id=${selectedService}&appointment_date=${formattedDate}&slot=free`
      );
      const _data = response.data.data;
      // console.log(_data, "data hrrt");
      const isFriday = selectedDate.getDay() == 5;

      const _availableTimes = _data
        .map((timeSlot) => {
          const [hour] = timeSlot.time_from.split(":").map(Number);
          const period = hour < 12 ? "morning" : "afternoon";

          return {
            time: `${timeSlot.time_from} - ${timeSlot.time_to}`,
            currentAppointments: timeSlot.current_appointments,
            remainingSlots: timeSlot.remaining_slots,
            duration_id: timeSlot.duration_id,
            time_from: timeSlot.time_from,
            period,
          };
        })
        .filter((timeSlot) => {
          if (isFriday) {
            const hour = parseInt(timeSlot.time_from.split(":")[0], 10);
            if (hour >= 12 && hour < 14) {
              return false;
            }
          }
          return true;
        });

      console.log("AVAVA: ", _availableTimes);
      setAvailableTimes(_availableTimes);
    } catch (error) {
      console.error("Error fetching available time slots:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableTimeSlots(selectedDate);
  }, [selectedDate]);

  console.log(availableTimes, "Available Times");

  const morningSlots = availableTimes.filter(
    (slot) => slot.period === "morning"
  );
  const afternoonSlots = availableTimes.filter(
    (slot) => slot.period === "afternoon"
  );

  const adjustTime = (timeString) => {
    const [startTime] = timeString.split(" - ");
    const [startHour, startMinute] = startTime.split(":").map(Number);
    let adjustedHour = (startHour - 6 + 24) % 24;

    const adjustedStartTime = `${adjustedHour
      .toString()
      .padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`;
    return adjustedStartTime;
  };

  return (
    <div>
      {/* <h2 className="text-3xl font-bold mb-6">Select a Time Slot</h2> */}
      {loading ? (
        <div>Loading available time slots...</div>
      ) : availableTimes.length > 0 ? (
        <div className="space-y-8">
          {/* Morning Slots */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Morning Slots</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {morningSlots.map((timeSlot, index) => (
                <div
                  key={index}
                  className={`p-4 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
                    selectedTimeSlotDetails.time_from === timeSlot.time_from
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(timeSlot); // Set selected time slot
                    console.log(timeSlot, "IS There");

                    setSelectedTimeSlotDetails({
                      time_from: timeSlot.time_from,
                      duration_id: timeSlot.duration_id,
                      remaining_slots: timeSlot.remainingSlots,
                    });
                    if (!isEdit) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <ClockIcon className="h-8 w-8 text-blue-500" />
                    <h3 className="text-lg font-semibold">
                      {adjustTime(timeSlot.time)}
                    </h3>
                  </div>
                  <p className="text-gray-500 mt-2">
                    Remaining slots: {timeSlot.remainingSlots}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Afternoon Slots */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Afternoon Slots</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {afternoonSlots.map((timeSlot, index) => (
                <div
                  key={index}
                  className={`p-4 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
                    selectedTimeSlotDetails.time_from === timeSlot.time_from
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    setSelectedTimeSlot(timeSlot); // Set selected time slot
                    console.log("Test Here");

                    setSelectedTimeSlotDetails({
                      time_from: timeSlot.time_from,
                      duration_id: timeSlot.duration_id,
                      remaining_slots: timeSlot.remainingSlots,
                    });
                    if (!isEdit) {
                      setIsModalOpen(true); // Open modal after selecting the time slot
                    }
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <ClockIcon className="h-8 w-8 text-blue-500" />
                    <h3 className="text-lg font-semibold">
                      {adjustTime(timeSlot.time)}
                    </h3>
                  </div>
                  <p className="text-gray-500 mt-2">
                    Remaining slots: {timeSlot.remainingSlots}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>No available time slots for the selected date.</p>
      )}
    </div>
  );
};

export default TimeSlotSelection;
