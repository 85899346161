// components/Modal.js
import React, { useState, useEffect } from "react";

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  // initialData,
  serviceName,
  handleNameChange,
  handleServiceActive,
  active,
  serviceId,
}) => {
  // const [formData, setFormData] = useState(
  //   initialData || { name: "", description: "", active: "" }
  // );

  // useEffect(() => {
  //   setFormData(initialData || { name: "", description: "" });
  // }, [initialData]);

  if (!isOpen) return null;

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black md:min-w-0.5 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-1/2">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
          <h3 className="text-lg font-semibold">Edit service:</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="mt-4">
          <label className="block mb-2">Service Name:</label>
          <input
            type="text"
            name="name"
            value={serviceName}
            onChange={handleNameChange}
            className="border rounded w-full px-3 py-2"
            placeholder="Enter service name"
          />
        </div>
        <div className="mt-4">
          <label className="block mb-2">Active:</label>
          <select value={active} name="active" className="border rounded w-full px-3 py-2" onChange={handleServiceActive}>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
        <div className="flex mt-6 justify-evenly">
          <button onClick={onClose} className="mr-2 text-gray-500">
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
