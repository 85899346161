import axios from "axios";

const fetchAllAppointments = async (
  token,
  startingDate,
  endingDate,
  selectedBranch
) => {
  // let URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThrough`;
  if (startingDate && endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?from_date=${startingDate}&to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?from_date=${startingDate}&branch_id=${selectedBranch}`;
  } else if (endingDate && selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?to_date=${endingDate}&branch_id=${selectedBranch}`;
  } else if (startingDate && endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?from_date=${startingDate}&to_date=${endingDate}`;
  } else if (startingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?from_date=${startingDate}`;
  } else if (endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?to_date=${endingDate}`;
  } else if (selectedBranch) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch?branch_id=${selectedBranch}`;
  } else {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByAppointmentThroughByBranch`;
  }

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const responseData = response.data.data;

  return responseData;
};

export default fetchAllAppointments;
