import React from "react";
import FleetList from "./FleetList";

const Fleet = () => {
  return (
    <div className="flex flex-col align-middle ">
      <div className="flex  flex-row justify-between ">
        {/*<p className="text-4xl text-gray-800 ml-4">Booking</p>*/}
      </div>
      <FleetList />
    </div>
  );
};

export default Fleet;
