import { ClockIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useEffect, useState } from "react";

const TimeSlotSelection = ({
  selectedDate,
  selectedBranch,
  setTimeSlotId,
  timeSlotId,
  setSelectedTimeSlot,
  setSelectedExtraSlot,
  setSelectedTimeSlotDetails,
  setIsModalOpen,
}) => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(timeSlotId, "TimeSlotId"); // Log the current timeSlotId state

  // Function to format date into "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // Fetch available time slots based on selected date and branch
  const fetchAvailableTimeSlots = async (date) => {
    setLoading(true);

    const formattedDate = formatDate(selectedDate);
    console.log("Selected Date:", formattedDate);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/extra_slots/get_available_extra_slot?branch_id=${selectedBranch}&date=${formattedDate}`
      );

      const _data = response.data.data.data; // Accessing the nested time slot data
      console.log("Fetched Data:", _data);

      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const currentTotalMinutes = currentHours * 60 + currentMinutes;

      // Map the available time slots and filter for future slots with remaining availability
      const _availableTimes = _data.map((timeSlot) => ({
          id: timeSlot.id,
          time: `${timeSlot.time_from} - ${timeSlot.time_to}`,
          remainingSlots: timeSlot.extra_slot,
          appointment_duration_id: timeSlot.appointment_duration_id, // Ensure this is mapped
          time_from: timeSlot.time_from, // For comparison purposes
        }));

      setAvailableTimes(_availableTimes);
      console.log(_availableTimes, 'Available Times');
      
    } catch (error) {
      console.error("Error fetching available time slots:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch available slots whenever the selectedDate changes
  useEffect(() => {
    fetchAvailableTimeSlots(selectedDate);
  }, [selectedDate]);

  console.log(availableTimes, "Available Times");

  return (
    <div>
      {/* Time Slot Selection */}
      {loading ? (
        <div>Loading available time slots...</div>
      ) : availableTimes.length > 0 ? (
        <div className="space-y-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Available Time Slots</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {availableTimes.map((timeSlot, index) => (
                <div
                  key={index}
                  className={`p-4 border-2 rounded-lg cursor-pointer transition hover:scale-105 ${
                    timeSlot.time_from === setSelectedTimeSlot.time_from
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    console.log(timeSlot, "Selected Time Slot");

                    setSelectedTimeSlot(timeSlot); // Set selected time slot object
                    setSelectedTimeSlotDetails({
                      time_from: timeSlot.time_from,
                      remaining_slots: timeSlot.remainingSlots,
                    });
                    setSelectedExtraSlot(timeSlot.id); // Set selected extra slot ID
                    setTimeSlotId(timeSlot.appointment_duration_id); // Set appointment_duration_id
                    setIsModalOpen(true); // Open modal after selecting the time slot
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <ClockIcon className="h-8 w-8 text-blue-500" />
                    <h3 className="text-lg font-semibold">
                      {timeSlot.time}
                    </h3>
                  </div>
                  <p className="text-gray-500 mt-2">
                    Remaining slots: {timeSlot.remainingSlots}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>No available time slots for the selected date.</p>
      )}
    </div>
  );
};

export default TimeSlotSelection;