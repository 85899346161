import React, { createContext, useState } from "react";

export const NavigationSidebarContext = createContext();

export function NavigationSidebarProvider({ children }) {
  const [isNavigationSidebarMinimized, setNavigationSidebarMinimized] =
    useState(true);
  const [isNavigationSidebarOpen, setNavigationSidebarOpen] = useState(false);
  return (
    <NavigationSidebarContext.Provider
      value={{
        isNavigationSidebarOpen,
        setNavigationSidebarOpen,
        isNavigationSidebarMinimized,
        setNavigationSidebarMinimized,
      }}
    >
      {children}
    </NavigationSidebarContext.Provider>
  );
}
