import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import LoadingSpinner from "../LoadingSpinner";
import { AuthContext } from "../../../contexts/auth";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";

const FeedbackReport = ({ selectedBranch }) => {
  console.log(selectedBranch, 'From Review');
  
  const { user } = useContext(AuthContext);

  // Fetch data using react-query
  const {
    data,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["reviews", selectedBranch], // Add selectedBranch to queryKey for refetching when it changes
    queryFn: async () => {
      const url = selectedBranch
        ? `${process.env.REACT_APP_API_URL}/v1/reviews/countsByReview?branch_id=${selectedBranch}`
        : `${process.env.REACT_APP_API_URL}/v1/reviews/countsByReview`;

      const response = await axios.get(url);
      return response.data.data; // Extract 'data' from API response
    },
  });

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>Error loading data. Please try again later.</div>;

  const { averageRating, totalRatings, ratingsSummary, latestReviews } = data;

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-4">
        <CardTitle>Ratings & Reviews</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-row gap-8 pb-0">
        {/* Average Rating Section */}
        <div className="flex flex-col items-center w-1/3">
          <CardDescription className="text-center text-xl">
            <div className="flex items-center justify-center gap-2">
              <p className="text-4xl font-bold text-gray-800">{averageRating}</p>
              <p className="text-gray-600 text-lg">/ 5</p>
            </div>
            <div className="flex items-center justify-center mt-1">
              {[...Array(5)].map((_, index) => (
                <span key={index}>
                  {index < Math.floor(averageRating) ? (
                    <AiFillStar className="text-yellow-500 text-xl" />
                  ) : (
                    <AiOutlineStar className="text-gray-300 text-xl" />
                  )}
                </span>
              ))}
            </div>
            <p className="text-gray-500 mt-1">{totalRatings} Ratings</p>
          </CardDescription>
        </div>

        {/* Ratings Summary (Bar Chart) */}
        <div className="ratings-summary w-2/3">
          {Object.entries(ratingsSummary).map(([rating, count]) => (
            <div key={rating} className="flex items-center gap-4 mb-2">
              <div className="flex">
                {[...Array(parseInt(rating))].map((_, index) => (
                  <AiFillStar key={index} className="text-yellow-500 text-sm" />
                ))}
              </div>
              <div className="flex-1 h-4 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="bg-yellow-500 h-full"
                  style={{ width: `${(count / totalRatings) * 100}%` }}
                ></div>
              </div>
              <span className="text-gray-600">{count}</span>
            </div>
          ))}
        </div>
      </CardContent>
      <CardContent className="latest-reviews mt-6">
        {latestReviews.map((review, index) => (
          <div
            key={index}
            className="review-card border rounded-lg p-4 shadow-md bg-white mb-4"
          >
            <div className="flex items-center justify-between">
              <h3 className="font-semibold text-lg text-gray-800">
                {review.firstname} {review.lastname}
              </h3>
              <div className="flex items-center text-yellow-500">
                {[...Array(5)].map((_, idx) => (
                  <span key={idx}>
                    {idx < Math.floor(review.rating) ? (
                      <AiFillStar className="text-xl" />
                    ) : (
                      <AiOutlineStar className="text-gray-300 text-xl" />
                    )}
                  </span>
                ))}
              </div>
            </div>
            <p className="text-sm text-gray-600 mt-2">{review.created_at}</p>
          </div>
        ))}
      </CardContent>
      <CardFooter className="text-center text-sm text-gray-500 mt-4">
        See all reviews for more insights
      </CardFooter>
    </Card>
  );
};

export default FeedbackReport;
