import React, { useState } from 'react';
import Button from '../../Components/common/Button';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'sonner';

const fetchBranches = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/branches/`);
  return response.data.data;
};

const GenerateDurationModal = ({ handleGenerateModal }) => {
  const [formData, setFormData] = useState({
    branch_id: '',
    time_gap: '30', // Default to 30 minutes gap
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for form submission

  // Use React Query to fetch branches
  const { data: branches, error: branchesError, isLoading: isLoadingbranches } = useQuery({
    queryKey: ['branches'],
    queryFn: fetchBranches,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set loading state to true

    // Log formData to check if time_gap is updating correctly
    console.log("Submitting with formData:", formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/generateAppointmentDuration`,
        {
          branch_id: formData.branch_id,
          time_gap: formData.time_gap, // Make sure time_gap is included in the payload
          start_time: "08:00", // Setting default start time
        }
      );
      if (response.status === 200) {
        toast.success("Services updated successfully");
      } else {
        toast.error("Failed to add dates. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to add dates. Please try again later.");
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent backdrop-blur-sm z-50">
      <div className="relative w-full max-w-lg max-h-full p-6">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="flex items-center justify-between p-4 border-b border-gray-300">
            <h3 className="text-lg font-semibold text-gray-900">Generate New Duration</h3>
            <button
              type="button"
              className="text-gray-600 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1.5"
              onClick={handleGenerateModal}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <form className="p-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div className="w-full">
                <label
                  htmlFor="branch_id"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Select Branch
                </label>
                {isLoadingbranches ? (
                  <p className="text-gray-500">Loading branches...</p>
                ) : branchesError ? (
                  <p className="text-red-500">Error loading branches</p>
                ) : (
                  <select
                    name="branch_id"
                    id="branch_id"
                    className="w-full p-2.5 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    value={formData.branch_id}
                    onChange={handleChange}
                    required
                    disabled={isSubmitting} // Disable select when submitting
                  >
                    <option value="">Select a branch</option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              {/* Time Gap Selector */}
              <div className="w-full">
                <label
                  htmlFor="time_gap"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Time Gap (minutes)
                </label>
                <select
                  name="time_gap"
                  id="time_gap"
                  className="w-full p-2.5 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  value={formData.time_gap}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting} // Disable select when submitting
                >
                  <option value="30">30 minutes</option>
                  <option value="60">1 hour</option>
                  <option value="120">2 hours</option>
                  {/* Add more options if needed */}
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg flex items-center"
                disabled={isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? (
                  <svg
                    className="animate-spin mr-2 w-5 h-5 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    className="mr-2 w-5 h-5 inline-block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                )}
                {isSubmitting ? 'Generating...' : 'Generate Default Duration'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GenerateDurationModal;
