const serviceColors = {
  "Representation": "#e69b1c",
  "PLC": "#0088FA",
  "Rental": "#e61c5f",
  "Sales": "#e67373",
  "Loan": "#1ce61f",
  "Gift": "#442e70",
};

export const processServiceReports = (services) => {
  const chartData = services.map((eachService) => ({
    ...eachService,
    count: Number(eachService.service_count),
    fill: serviceColors[eachService.service_name],
  }));
  return chartData;
};
