import React, { useContext } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/auth";

const fetchInProgressQueues = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/findInProgressQueuesByUser`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch queues");
  }
  return response.json();
};

// Update queue status to "completed"
const updateQueueStatus = async (queueId, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/queues/updateCompleted?queueId=${queueId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update queue");
  }
  return response.json();
};

const InprogressQueue = () => {
  const { token } = useContext(AuthContext);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["in-progress-queues"],
    queryFn: () => fetchInProgressQueues(token), // Pass token to query function
  });

  const mutation = useMutation({
    mutationFn: (queueId) => updateQueueStatus(queueId, token),
    onSuccess: () => refetch(),
  });

  // Handle queue completion
  const handleCompleteQueue = (queueId) => {
    mutation.mutate(queueId);
  };

  return (
    <div className="flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4">Queue Manager</h2>

      {isLoading && (
        <div className="flex justify-center items-center h-64">
          <div className="loader border-t-4 border-blue-500 rounded-full w-16 h-16"></div>
        </div>
      )}

      {/* Error state */}
      {error && <div className="text-red-500 text-center">Failed to load data. Please try again.</div>}

      {/* Queue List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-7xl">
        {data?.queues?.length > 0 ? (
          data.queues.map((queue) => (
            <div
              key={queue.queue_id}
              className={`relative bg-white shadow-lg rounded-lg p-6 border ${
                queue.completed ? "border-green-500" : "border-gray-200"
              }`}
            >
              <h3 className="text-xl font-semibold text-gray-800">{`Name: ${queue.firstname} ${queue.lastname}`}</h3>
              <p className="text-gray-600">{`Queue No: ${queue.queue_no}`}</p>
              <p className="text-gray-600">{`Service: ${queue.service_name}`}</p>

              <button
                className={`mt-4 px-4 py-2 text-white rounded w-full ${
                  queue.completed ? "bg-green-500" : "bg-primary"
                }`}
                onClick={() => handleCompleteQueue(queue.queue_id)}
                disabled={queue.completed || mutation.isLoading}
              >
                {mutation.isPending && mutation.variables === queue.queue_id ? (
                  <div className="loader border-t-4 border-white rounded-full w-5 h-5 mx-auto"></div>
                ) : queue.completed ? (
                  "Completed"
                ) : (
                  "Complete"
                )}
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No In-Progress Queues</p>
        )}
      </div>

      {/* Refresh Button */}
      <button
        className="mt-6 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
        onClick={refetch}
      >
        Refresh Queues
      </button>
    </div>
  );
};

export default InprogressQueue;
