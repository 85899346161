import React, { useState, useEffect } from "react";

function AttendanceToggle() {
  const [isPresent, setIsPresent] = useState(false); // Attendance state
  const [loading, setLoading] = useState(false);

  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchAttendance = async () => {
      if (!token) {
        console.error("No token found. Please log in.");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/attendance/attendance`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch attendance data.");
        }

        const result = await response.json();

        if (result.status === "success" && result.data?.type === "clockedIn") {
          setIsPresent(true); // User is present
        } else {
          setIsPresent(false); // User is absent
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchAttendance();
  }, [token]);

  const handleToggleAttendance = async () => {
    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    setLoading(true);

    // Mock location data (replace with actual location fetching if available)
    const location = { longitude: 77.5946, latitude: 12.9716 }; // Example coordinates (Bangalore, India)
    const type = isPresent ? "clockedOut" : "clockedIn"; // Determine type based on current state

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/attendance/add_attendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Use the token from localStorage
        },
        body: JSON.stringify({
          type,
          longitude: location.longitude,
          latitude: location.latitude,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update attendance.");
      }

      const data = await response.json();
      console.log("Attendance updated:", data);

      // Update the attendance state based on the type sent
      setIsPresent(type === "clockedIn");
    } catch (error) {
      console.error("Error updating attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <span className={`text-sm font-semibold ${isPresent ? "text-green-600" : "text-red-600"}`}>
        {isPresent ? "Present" : "Absent"}
      </span>
      <div
        onClick={handleToggleAttendance}
        className={`relative w-16 h-8 rounded-full cursor-pointer transition-colors duration-300 ${
          isPresent ? "bg-green-500" : "bg-red-500"
        }`}
        style={{ pointerEvents: loading ? "none" : "auto" }}
      >
        <div
          className={`absolute top-1 left-1 w-6 h-6 rounded-full bg-white shadow-md transform transition-transform duration-300 ${
            isPresent ? "translate-x-8" : "translate-x-0"
          }`}
        ></div>
      </div>
    </div>
  );
}

export default AttendanceToggle;
