// lib/utils.js

/**
 * Utility function to conditionally join class names.
 * @param  {...any} classes - A list of class names and conditional expressions.
 * @returns {string} - A single string with all class names joined by a space.
 */
export function cn(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  