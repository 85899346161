import React, { useState, useEffect } from 'react';
import Button from '../../Components/common/Button';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'sonner';
import LoadingSpinner from '../../Components/lib/LoadingSpinner';

// Fetch branches from API
const fetchBranches = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/branches/`);
  return response.data.data;
};

// Define time options in Ethiopian time
const timeOptions = [
  "08:00:00", "08:30:00", 
  "09:00:00", "09:30:00", 
  "10:00:00", "10:30:00", 
  "11:00:00", "11:30:00", 
  "12:00:00", "12:30:00", 
  "13:00:00", "13:30:00", 
  "14:00:00", "14:30:00", 
  "15:00:00", "15:30:00", 
  "16:00:00", "16:30:00", 
  "17:00:00", "17:30:00", 
  "18:00:00", "18:30:00",
  "18:30:00", "19:00:00", 
  "19:30:00", "20:00:00", 
  "20:30:00", "21:00:00", 
  "21:30:00", "22:00:00", 
  "22:30:00", "23:00:00", 
  "23:30:00", "00:00:00",
];

const AddDurationModal = ({ handleAddModal, type, durationData }) => {
  const [formData, setFormData] = useState({
    branch_id: '',
    time_from: '',
    time_to: '',
    is_morning: false,
    active: true,
    order: '',
    id: 0,
    services_per_slot: 0, // New fields
    number_of_officers:'',
    expected_number_of_officers:'',
    serve_percentage_ratio:'',
    service_per_officer:0,
    free_service_slots: 0,
    paid_service_slots: 0,
    paid_percentage: 0.3,
  });
  
  const [isLoading, setIsLoading] = useState(false);

  // Use React Query to fetch branches
  const { data: branches, error: branchesError, isLoading: isLoadingBranches } = useQuery({
    queryKey: ['branches'],
    queryFn: fetchBranches,
  });

  useEffect(() => {
    if (type === 'Edit' && durationData) {
      setFormData({
        branch_id: durationData.branch_id || '',
        time_from: durationData.time_from || '',
        time_to: durationData.time_to || '',
        is_morning: durationData.is_morning || false,
        active: durationData.active || false, // Set active status from durationData
        order: durationData.order || '',
        expected_number_of_officers: durationData.expected_number_of_officers || '',
        serve_percentage_ratio:durationData.serve_percentage_ratio || '',
        id: durationData.id || 0
      });
    }
  }, [type, durationData]);

  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: inputType === 'checkbox' ? checked : value,
    }));

    // Automatically set time_to when time_from changes
    if (name === 'time_from') {
      const selectedIndex = timeOptions.indexOf(value);
      if (selectedIndex >= 0 && selectedIndex < timeOptions.length - 1) {
        setFormData((prevData) => ({
          ...prevData,
          time_from: value,
          time_to: timeOptions[selectedIndex + 1], // Set time_to to the next time option
          order: selectedIndex + 1 // Update order based on the selected time_from index
        }));
      } else if (selectedIndex === timeOptions.length - 1) {
        setFormData((prevData) => ({
          ...prevData,
          time_from: value,
          time_to: timeOptions[selectedIndex], // Last time option, keep it the same
          order: selectedIndex // Keep order the same as time_from index
        }));
      }
    }

    // Set order based on time_to if it changes
    if (name === 'time_to') {
      const fromIndex = timeOptions.indexOf(formData.time_from);
      const toIndex = timeOptions.indexOf(value);
      if (toIndex >= 0 && fromIndex !== -1 && toIndex > fromIndex) {
        setFormData((prevData) => ({
          ...prevData,
          order: toIndex // Adjust order based on the time_to index
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = type === 'Edit'
        ? `${process.env.REACT_APP_API_URL}/v1/appointments/duration/${durationData.id}`
        : `${process.env.REACT_APP_API_URL}/v1/appointments/addAppointmentDuration`;
      const method = type === 'Edit' ? 'put' : 'post';

      const response = await axios({
        url,
        method,
        data: {
          ...formData,
          time_from: formData.time_from,
          time_to: formData.time_to,
          services_per_slot: formData.services_per_slot,
          free_service_slots: formData.free_service_slots,
          paid_service_slots: formData.paid_service_slots,
          paid_percentage: formData.paid_percentage,
          expected_number_of_officers: formData.expected_number_of_officers,
          serve_percentage_ratio:formData.serve_percentage_ratio,
          service_per_officer: formData.service_per_officer,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      

      if (response.status === 200) {
        if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.success(`Time ${type === 'Edit' ? 'updated' : 'added'} successfully`);
        }
      } else {
        toast.error("Failed to add dates. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to add dates. Please try again later.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const adjustTime = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    let adjustedHours = (parseInt(hours, 10) - 6 + 24) % 24; // Subtract 6 and wrap around
    return `${adjustedHours.toString().padStart(2, '0')}:${minutes}:${seconds}`;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent backdrop-blur-sm z-50">
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 md:p-5 border-b border-gray-300">
            <h3 className="text-lg font-semibold text-gray-900">Generate New Duration</h3>
            <button
              type="button"
              className="text-gray-600 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleAddModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5" onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4 grid-cols-1">
              {/* Active Checkbox */}
            




              {/* Branch Selector */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="branch_id" className="block mb-2 text-sm font-medium text-gray-800">
                  Branch
                </label>
                {isLoadingBranches ? (
                  <p className="text-gray-600">Loading branches...</p>
                ) : branchesError ? (
                  <p className="text-red-500">Error loading branches</p>
                ) : (
                  <select
                    name="branch_id"
                    id="branch_id"
                    className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.branch_id}
                    onChange={handleChange}
                    required
                    disabled={isLoading} // Disable while loading
                  >
                    <option value="">Select location</option>
                    {branches.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              {/* Time From Selector */}
              <div>
                <label htmlFor="time_from" className="block mb-2 text-sm font-medium text-gray-800">
                  Time From
                </label>
                <select
                  id="time_from"
                  name="time_from"
                  className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5"
                  value={formData.time_from}
                  onChange={handleChange}
                  required
                  disabled={isLoading} // Disable while loading
                >
                  <option value="">Select time</option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {adjustTime(time)} {/* Display adjusted time */}
                    </option>
                  ))}
                </select>
              </div>

              {/* Time To Selector */}
              <div>
                <label htmlFor="time_to" className="block mb-2 text-sm font-medium text-gray-800">
                  Time To
                </label>
                <select
                  id="time_to"
                  name="time_to"
                  className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5"
                  value={formData.time_to}
                  onChange={handleChange}
                  required
                  disabled={isLoading} // Disable while loading
                >
                  <option value="">Select time</option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {adjustTime(time)} {/* Display adjusted time */}
                    </option>
                  ))}
                </select>
              </div>



 

              {/* Order Input */}
              <div>
                <label htmlFor="order" className="block mb-2 text-sm font-medium text-gray-800">
                  Order
                </label>
                <input
                  type="number"
                  id="order"
                  name="order"
                  className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5"
                  value={formData.order}
                  readOnly // Optionally make it readOnly if it should not be edited directly
                  required
                />
              </div>

              <div>
  <label htmlFor="serve_percentage_ratio" className="block mb-2 text-sm font-medium text-gray-800">
    Serve Percentage Ratio (Currently {formData.serve_percentage_ratio})
  </label>
  <select
    id="serve_percentage_ratio"
    name="serve_percentage_ratio"
    className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5"
    value={formData.serve_percentage_ratio} // Displays the current value
    onChange={handleChange}
    required
    disabled={isLoading} // Disable while loading
  >
    <option value="">Select ratio</option>
    {Array.from({ length: 10 }, (_, index) => 10 * (index + 1)).map((value) => (
      <option key={value} value={value}>
        {value}%
      </option>
    ))}
  </select>
</div>


       




              

              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  checked={formData.active}
                  onChange={handleChange}
                  disabled={isLoading} // Disable while loading
                />
                <label htmlFor="active" className="ml-2 text-sm font-medium text-gray-800">
                  Active
                </label>
              </div>
            </div>

                         {/* Is Morning Checkbox */}
                         <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="is_morning"
                  name="is_morning"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  checked={formData.is_morning}
                  onChange={handleChange}
                  disabled={isLoading} // Disable while loading
                />
                <label htmlFor="is_morning" className="ml-2 text-sm font-medium text-gray-800">
                  Is Morning?
                </label>
              </div>

            {/* Submit Button */}
            <div className="flex justify-between items-center">
              {isLoading && <span className="text-gray-500">Adding duration...</span>}
              <Button type="submit" disabled={isLoading} className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg">
                <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {isLoading ? <LoadingSpinner /> : `${type} Duration`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDurationModal;
