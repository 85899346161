import React, { useState } from "react";
import Button from "../../Components/common/Button";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const fetchLocations = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/locations/all`);
  if (!response.ok) {
    throw new Error("Error fetching locations");
  }
  return response.json();
};

const fetchOrganizations = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/organizations/all`);
  if (!response.ok) {
    throw new Error("Error fetching organizations");
  }
  return response.json();
};

const AddBranchModal = ({ handleAddModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    location_id: "",
    organization_id: "",
    description: "",
    branch_services_per_hour: "",
    no_of_windows: 40
  });

  const [isLoadingButton, setIsLoadingButton] = useState(false); // Loading state for the button

  const queryClient = useQueryClient();

  // Fetch locations using React Query
  const {
    data: locations,
    error: locationsError,
    isLoading: isLoadingLocations,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });

  // Fetch organizations using React Query
  const {
    data: organizations,
    error: organizationsError,
    isLoading: isLoadingOrganizations,
  } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchOrganizations,
  });

  // Mutation to handle form submission
  const mutation = useMutation({
    mutationFn: async (newBranch) => {
      setIsLoadingButton(true); // Set loading state to true when the mutation starts
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/branches`, newBranch);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["branches"]); // Invalidate the branches query to refresh the branch data after successful creation
      handleAddModal(); // Close the modal after successful submission
    },
    onError: (error) => {
      console.error("Error creating branch:", error);
    },
    onSettled: () => {
      setIsLoadingButton(false); // Set loading state to false after the mutation is finished
    },
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Trigger the mutation to send form data to the API
    mutation.mutate(formData);
  };

  return (
    <div className="justify-center items-center flex bg-opacity-70 backdrop-blur-sm overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-gray-900 rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold text-white">Add New Branch</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleAddModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5" onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type branch name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="no_of_windows"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Number of Windows
                </label>
                <input
                  type="number"
                  name="no_of_windows"
                  id="no_of_windows"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type number of windows"
                  value={formData.no_of_windows}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="location_id"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Location
                </label>

                {isLoadingLocations ? (
                  <p>Loading locations...</p>
                ) : locationsError ? (
                  <p>Error loading locations</p>
                ) : (
                  <select
                    name="location_id"
                    id="location_id"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    value={formData.location_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select location</option>
                    {locations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="organization_id"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Organization
                </label>
                {isLoadingOrganizations ? (
                  <p>Loading organizations...</p>
                ) : organizationsError ? (
                  <p>Error loading organizations</p>
                ) : (
                  <select
                    name="organization_id"
                    id="organization_id"
                    className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    value={formData.organization_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select organization</option>
                    {organizations.map((organization) => (
                      <option key={organization.id} value={organization.id}>
                        {organization.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="branch_services_per_hour"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Branch Services Per Hour
                </label>
                <input
                  type="number"
                  name="branch_services_per_hour"
                  id="branch_services_per_hour"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type branch services per hour"
                  value={formData.branch_services_per_hour}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-white bg-gray-800 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Write branch description here"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Update button to show loading spinner */}
            <Button type="submit" disabled={isLoadingButton}>
              {isLoadingButton ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              ) : (
                <>
                  <svg
                    className="me-1 -ms-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Add new branch
                </>
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBranchModal;
