import React, { useState, useEffect, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import { FaBuilding, FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineFormatListNumbered } from "react-icons/md";
import { AuthContext } from "../../contexts/auth";

const EditBranchModal = ({
  branchId,
  name,
  locationId: initialLocationId,
  servicesPerHour: initialServicesPerHour,
  organizationId: initialOrganizationId,
  active: initialActive,
  paidPercentage: initialPaidPercentage,
  timeSpecific: initialTimeSpecific,
  onClose,
}) => {
  const [branchName, setBranchName] = useState(name);
  const { token, setToken, setUser, user } = useContext(AuthContext);
  const [locationId, setLocationId] = useState(initialLocationId);
  const [locations, setLocations] = useState([]);
  const [servicesPerHour, setServicesPerHour] = useState(initialServicesPerHour);
  const [paidPercentage, setPaidPercentage] = useState(
    (initialPaidPercentage || 0.3) * 100
  );
  const [organizationId, setOrganizationId] = useState(initialOrganizationId);
  const [active, setActive] = useState(initialActive);
  const [timeSpecific, setTimeSpecific] = useState(initialTimeSpecific || false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [durationType, setDurationType] = useState(30); // Default duration type is 30 minutes

  const [userInput, setUserInput] = useState(0);
  const [calculationType, setCalculationType] = useState("number");

  useEffect(() => {
    fetchLocations();
    fetchBranchInformation();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/locations`
      );
      setLocations(
        Array.isArray(response.data.locations) ? response.data.locations : []
      );
    } catch (error) {
      console.error("Error fetching locations:", error);
      toast.error("Failed to fetch locations. Please try again later.");
    }
  };

  const fetchBranchInformation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/branches/by-id?id=${branchId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const branchData = response.data.branch;

      setBranchName(branchData.name || "");
      setLocationId(branchData.location_id || ""); // Ensure location_id is correctly set
      setServicesPerHour(branchData.branch_services_per_hour || "");
      setPaidPercentage((branchData.paid_percentage || 0.3) * 100);
      setActive(branchData.active || false);
      setTimeSpecific(branchData.time_specific || false);
      setDurationType(branchData.duration_type || 30); // Set duration type
    } catch (error) {
      console.error("Error fetching branch information:", error);
      toast.error(
        "Failed to fetch branch information. Please try again later."
      );
    }
  };

  const handleUpdateBranch = async () => {
    if (!branchName) {
      toast.error("Branch name cannot be empty.");
      return;
    }
    if (!locationId) {
      toast.error("Location cannot be empty.");
      return;
    }
    if (!servicesPerHour) {
      toast.error("Services per hour cannot be empty.");
      return;
    }
    if (isNaN(servicesPerHour) || servicesPerHour <= 0) {
      toast.error("Services per hour must be a positive number.");
      return;
    }
    if (isNaN(paidPercentage) || paidPercentage < 0 || paidPercentage > 100) {
      toast.error("Paid percentage must be between 0 and 100.");
      return;
    }

    const requestBody = {
      branchId,
      name: branchName,
      branch_services_per_hour: servicesPerHour,
      location_id: locationId,
      paid_percentage: paidPercentage / 100,
      active,
      time_specific: timeSpecific,
      duration_type: durationType,
    };

    try {
      setIsUpdating(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/branches/${branchId}`,
        requestBody
      );

      if (response.status === 200) {
        toast.success("Branch updated successfully.");
        onClose();
      } else {
        toast.error("Failed to update branch. Please try again later.");
      }
    } catch (error) {
      console.error("Error updating branch:", error);
      toast.error("Failed to update branch. Please try again later.");
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (calculationType === "number") {
      if (userInput >= 0 && servicesPerHour > 0) {
        const calculatedPaidPercentage = (userInput / servicesPerHour) * 100;
        setPaidPercentage(calculatedPaidPercentage);
      }
    } else if (calculationType === "percentage") {
      if (userInput >= 0 && userInput <= 100) {
        setPaidPercentage(userInput);
      }
    }
  }, [userInput, calculationType, servicesPerHour]);

  const freePercentage = 100 - paidPercentage;
  const freeSlots = Math.floor((servicesPerHour || 0) * (freePercentage / 100));
  const paidSlots = Math.floor((servicesPerHour || 0) * (paidPercentage / 100));

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="fixed inset-0 bg-black opacity-50 "></div>
      <div className="bg-white p-6 rounded-lg shadow-md z-50 max-w-2xl w-full">
        <h2 className="text-lg font-semibold mb-4 text-center">
          Edit Branch Information
        </h2>

        <div className="flex items-center border border-gray-300 rounded-md p-2 mb-4 w-full">
          <FaBuilding className="text-gray-800 mr-3" />
          <input
            type="text"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            className="flex-1 outline-none"
            placeholder="Branch Name"
          />
          <span className="text-gray-700 pointer-events-none">BRANCH NAME</span>
        </div>

        <label className="block mb-2">Location</label>
        <select
          value={locationId}
          onChange={(e) => setLocationId(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-4 w-full"
        >
          <option value="">Select Location</option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </select>

        <div className="flex items-center border border-gray-300 rounded-md p-2 mb-4 w-full">
          <MdOutlineFormatListNumbered className="text-gray-900 mr-3" />
          <input
            type="number"
            value={servicesPerHour}
            onChange={(e) => setServicesPerHour(e.target.value)}
            className="flex-1 outline-none"
            placeholder="Services Per Hour"
          />
          <span className="text-gray-700 pointer-events-none">
            SERVICE PER HOUR
          </span>
        </div>

        <label className="block mb-2">Select Method:</label>
        <div className="mb-4">
          <label>
            <input
              type="radio"
              value="number"
              checked={calculationType === "number"}
              onChange={(e) => setCalculationType(e.target.value)}
              className="mr-2"
            />
            Number of Paid Slots
          </label>
          <label className="ml-4">
            <input
              type="radio"
              value="percentage"
              checked={calculationType === "percentage"}
              onChange={(e) => setCalculationType(e.target.value)}
              className="mr-2"
            />
            Paid Percentage
          </label>
        </div>

        <label className="block mb-2">
          Input # of Paid Slots OR % of Paid Slots:
        </label>
        <input
          type="number"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-4 w-full"
        />

        <div className="flex justify-between">
          <div className="flex items-center">
            <label className="mr-2">Paid Slots: </label>
            <span>{paidSlots}</span>
          </div>
          <div className="flex items-center">
            <label className="mr-2">Free Slots: </label>
            <span>{freeSlots}</span>
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <label className="mr-2">Paid Percentage: </label>
            <span>{paidPercentage.toFixed(2)}%</span>
          </div>
        </div>

        {/* Duration Type Dropdown */}
        <label className="block mb-2">Duration Type</label>
        <select
          value={durationType}
          onChange={(e) => setDurationType(Number(e.target.value))}
          className="border border-gray-300 rounded-md p-2 mb-4 w-full"
        >
          <option value={30}>30 minutes</option>
          <option value={60}>60 minutes</option>
          <option value={90}>90 minutes</option>
          <option value={120}>120 minutes</option>
        </select>

        {/* Active Checkbox */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
            className="mr-2"
          />
          <label>Active</label>
        </div>

        {/* Time Specific Checkbox */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={timeSpecific}
            onChange={(e) => setTimeSpecific(e.target.checked)}
            className="mr-2"
          />
          <label>Time Specific</label>
        </div>

        <div className="mt-4 flex justify-between">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateBranch}
            disabled={isUpdating}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            {isUpdating ? "Updating..." : "Update Branch"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBranchModal;
