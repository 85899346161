import React from 'react'
import { TableBody, Table, TableCell, TableHead, TableHeader, TableRow } from '../../Components/common/Table'

import TimeOfDayTag from '../../Components/common/TimeOfDayTag'

const Durations = ({ durations, onEdit, onDelete }) => {
  return (
    <Table className="p-4 border  border-gray-200 rounded-lg">
      <TableHeader className="border-none">
        <TableRow className=" bg-gray-100">
          <TableHead>No.</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Time From</TableHead>
          <TableHead>Time To</TableHead>
          <TableHead>Time</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {durations.map((duration, index) => (
          <TableRow key={duration.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{duration.name}</TableCell>
            <TableCell>{duration?.time_from}</TableCell>
            <TableCell>{duration?.time_to}</TableCell>
            <TableCell><TimeOfDayTag time={duration.is_morning} /></TableCell>
            <TableCell> 
              <button
                onClick={() => onEdit(duration.id)}
                className="text-blue-500 hover:text-blue-700 mr-2"
              >
                Edit
              </button>
              <button
                onClick={() => onDelete(duration.id)}
                className="text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default Durations;
