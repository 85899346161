import React, { useState } from "react";
import CardsSection from "../../Components/common/CardsSection";
import TableSection from "../../Components/common/TableSection";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import CardSkeleton from "../../Components/common/CardSkeleton";
import TableSkeleton from "../../Components/common/TableSekeleton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";

const fetchBranches = async () => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches`;

  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");

  // Add the token to the Authorization header
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`, // Use "Bearer" followed by the token
    },
  });

  return response.data.data;
};


// Fetch Payments
const fetchPayment = async ({ queryKey }) => {
  const [
    _,
    page,
    perPage,
    searchQuery,
    selectedBranch,
    startingDate,
    endingDate,
  ] = queryKey;

  try {
    // Build the URL based on conditions
    const url =
      searchQuery && selectedBranch
        ? `${
            process.env.REACT_APP_API_URL
          }/v1/payments/?booking_code=${searchQuery}&page=${page}&limit=${perPage}&branch_id=${selectedBranch}${
            startingDate ? `&from_date=${startingDate}` : ""
          }${endingDate ? `&to_date=${endingDate}` : ""}`
        : searchQuery
        ? `${
            process.env.REACT_APP_API_URL
          }/v1/payments/?booking_code=${searchQuery}&page=${page}&limit=${perPage}${
            startingDate ? `&from_date=${startingDate}` : ""
          }${endingDate ? `&to_date=${endingDate}` : ""}`
        : selectedBranch
        ? `${
            process.env.REACT_APP_API_URL
          }/v1/payments/?branch_id=${selectedBranch}&page=${page}&limit=${perPage}${
            startingDate ? `&from_date=${startingDate}` : ""
          }${endingDate ? `&to_date=${endingDate}` : ""}`
        : `${
            process.env.REACT_APP_API_URL
          }/v1/payments/?page=${page}&limit=${perPage}${
            startingDate ? `&from_date=${startingDate}` : ""
          }${endingDate ? `&to_date=${endingDate}` : ""}`;

    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No auth token found in localStorage");
    }

    // Make the request with the Authorization header
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token
      },
    });

    const payments = data?.data?.data || [];

    console.log(payments, "Payments");

    // Format the payments data
    const formattedPayments = payments.map(
      ({
        payment_id,
        cnr,
        firstname,
        lastname,
        booking_code,
        type,
        amount,
        branch_name,
        organization_name,
        created_at,
        payment_ref,
        status,
        appointment_time,
      }) => ({
        id: payment_id,
        cnr: cnr,
        booking_code: booking_code,
        customer_name: `${firstname} ${lastname}`,
        type: type,
        amount: amount,
        branch_name: branch_name,
        organization_name: organization_name,
        status: status,
        appointment_time: appointment_time,
        createdAt: new Date(created_at),
        payment_ref: payment_ref?.trim() || "", // Handle null or undefined payment_ref
      })
    );

    return {
      totalItems: Number(data?.data?.totalItems) || 0, // Ensure totalItems is a number
      totalPages: Number(data?.data?.totalPages) || 1, // Ensure totalPages is a number
      data: formattedPayments,
    };
  } catch (error) {
    console.error("Error fetching payments:", error);
    throw error;
  }
};


const ManageRoute = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  const [selectedBranch, setSelectedBranch] = useState("");
  const { data: branchesData } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  const HEADERS = [
    { name: "Customer Name", dataIndex: "customer_name" },
    { name: "Booking Code", dataIndex: "booking_code" },
    { name: "CNR", dataIndex: "cnr" },
    { name: "Status", dataIndex: "status" }, // Fixed to match the key in the data
    { name: "Appointment Time", dataIndex: "appointment_time" },
    { name: "Branch name", dataIndex: "branch_name" },
    { name: "Payment Type", dataIndex: "type" },
    { name: "Amount", dataIndex: "amount" },
    { name: "Payment Reference", dataIndex: "payment_ref" },
    { name: "Organization", dataIndex: "organization_name" },
  ];

  const { data, error, isLoading } = useQuery({
    queryKey: [
      "payments",
      currentPage,
      rowsPerPage,
      searchQuery,
      selectedBranch,
      startingDate,
      endingDate,
    ],
    queryFn: fetchPayment,
    keepPreviousData: true,
  });

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex flex-wrap gap-4 mb-4">
          <CardSkeleton />
        </div>
        <TableSkeleton />
      </div>
    );
  }

  if (error) return <div>Error fetching data: {error.message}</div>;

  // const filteredData =
  //   data?.data?.filter(
  //     (row) =>
  //       row.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       row.branch_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       row.amount
  //         .toString()
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase()) ||
  //       row.payment_ref
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase()) || // Handle payment_ref being null
  //       row.organization_name.toLowerCase().includes(searchTerm.toLowerCase())
  //   ) || [];

  // const sortedData =
  //   filteredData.length > 0
  //     ? filteredData.sort((a, b) => b.createdAt - a.createdAt)
  //     : [];

  const totalAmount = (data?.totalItems || 0) * 50;
  const totalPages = data?.totalPages;

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (term) => {
    if (term.trim() === "") {
      setSearchQuery("");
    } else {
      setSearchQuery(term);
      setCurrentPage(1);
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.text("Appointment Data", 20, 10);

    // Define the columns
    const columns = [
      "Customer Name",
      "Booking Code",
      "CNR",
      "Status",
      "Appointment Time",
      "Branch name",
      "Organization",
      "Payment Type",
      "Amount",
      "Payment Reference",
    ];

    // Prepare rows for the table
    const rows = data?.data?.map((row) => [
      row.customer_name,
      row.booking_code,
      row.cnr,
      row.status,
      row.appointment_time,
      row.branch_name,
      row.organization_name,
      row.type,
      row.amount,
      row.payment_ref,
    ]);

    // Add autoTable to the document
    doc.autoTable({
      head: [columns],
      body: rows,
    });

    // Save the PDF
    doc.save("appointments.pdf");
  };

  const dateChangeHandler = (date) => {
    if (date === null) {
      return;
    }
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = date?.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-4">
      <div className="flex flex-row items-center justify-between">
        <CardsSection
          cardsData={[
            {
              title: "Total Service Fee",
              amount: `ETB  ${totalAmount}.00`,
              type: "payment",
            },
          ]}
        />
        <div>
          <select
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
            className="w-64 p-1 h-14 border border-gray-300 rounded-md shadow-sm m-1"
          >
            <option value="">Select a branch.</option>
            {branchesData?.map((eachBranchData, index) => (
              <option key={index} value={eachBranchData.id}>
                {eachBranchData.name}
              </option>
            ))}
          </select>
          <DatePicker
            selected={startingDate}
            onChange={(date) => setStartingDate(dateChangeHandler(date))}
            dateFormat="yyyy-MM-dd"
            monthsShown={1}
            className="w-60 border border-gray-300 rounded-lg p-4 m-1"
            placeholderText="Filter By Date From"
            isClearable
          />
          <DatePicker
            selected={endingDate}
            onChange={(date) => setEndingDate(dateChangeHandler(date))}
            dateFormat="yyyy-MM-dd"
            monthsShown={1}
            className="w-60 border border-gray-300 rounded-lg p-4 m-1"
            placeholderText="Filter By Date To"
            isClearable
          />
        </div>
      </div>
      <TableSection
        rowsPerPage={rowsPerPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        setSearchTerm={setSearchTerm}
        currentData={data?.data}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        currentPage={currentPage}
        totalPages={totalPages}
        downloadPDF={downloadPDF}
        handlePageChange={handlePageChange}
        headers={HEADERS}
      />
    </div>
  );
};

export default ManageRoute;
