import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Branchs from "./Branchs";
import { PlusIcon, TrendingUp } from "lucide-react";
import Button from "../../Components/common/Button";
import ListHeader from "../../Components/common/ListHeader";
import TableSekeleton from "../../Components/common/TableSekeleton";
import CardSkeleton from "../../Components/common/CardSkeleton";
import StatBox from "../../Components/common/StatBox";
import AddBranchModal from "./AddBranch";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// fetch Organizations
const fetchOrganizations = async ({ queryKey }) => {
  const [_, page, perPage, searchTerm] = queryKey;
  const url = searchTerm
    ? `${process.env.REACT_APP_API_URL}/v1/organizations/search?text=${searchTerm}&page=${page}&limit=${perPage}`
    : `${process.env.REACT_APP_API_URL}/v1/organizations/?page=${page}&limit=${perPage}`;

  const { data } = await axios.get(url);
  if (searchTerm) {
    data.data = data.organizations.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  } else {
    data.data = data.data.map(
      ({ id, name, description, created_at, active }) => ({
        id,
        organizationName: name,
        status: description,
        createdAt: new Date(created_at),
        activity: active,
      })
    );
  }

  return data;
};

// Fetch branches data for cards (limit 50)
const fetchCardBranches = async ({ queryKey }) => {
  const [_, orgId, selectedOrganization] = queryKey;
  const url = orgId
    ? `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${orgId}&limit=50`
    : selectedOrganization
    ? `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${selectedOrganization}&limit=50`
    : `${process.env.REACT_APP_API_URL}/v1/branches?limit=50`;
  const { data } = await axios.get(url);
  return data;
};

// Fetch branches data for table (with pagination)
const fetchTableBranches = async ({ queryKey }) => {
  const [_, page, limit, orgId,selectedOrganization, searchQuery] = queryKey; // Added searchQuery to queryKey
  const url = orgId
    ? `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${orgId}&page=${page}&limit=${limit}&search=${searchQuery}` // Pass searchQuery to backend
    : selectedOrganization
    ? `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${selectedOrganization}&page=${page}&limit=${limit}&search=${searchQuery}`
    : `${process.env.REACT_APP_API_URL}/v1/branches?page=${page}&limit=${limit}&search=${searchQuery}`;
  const { data } = await axios.get(url);
  return data;
};

const Branch = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // For the table
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const orgId = searchParams.get("orgId");
  console.log("Received orgId:", orgId);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // fetchOrganizations
  const {
    data: organizationsdata,
    error: organizationserror,
    isLoading: isLoadingOrganizations,
  } = useQuery({
    queryKey: ["organizations", currentPage, itemsPerPage, searchQuery],
    queryFn: fetchOrganizations,
  });

  // Query for card information (limit 50)
  const {
    data: cardBranchesData,
    error: cardBranchesError,
    isLoading: isLoadingCardBranches,
  } = useQuery({
    queryKey: ["cardBranches", orgId, selectedOrganization],
    queryFn: fetchCardBranches,
  });

  // Query for table information (pagination, default limit 10)
  const {
    data: tableBranchesData,
    error: tableBranchesError,
    isLoading: isLoadingTableBranches,
  } = useQuery({
    queryKey: ["tableBranches", currentPage, itemsPerPage, orgId,selectedOrganization, searchQuery], // Add searchQuery to queryKey
    queryFn: fetchTableBranches,
  });

  const handleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleModify = (branchId, name) => {
    navigate("/BranchDetail", {
      state: { branchId, name },
    });
  };

  // Handle search input change
  const handleSearchChange = (query) => {
    setSearchQuery(query); // Update search query state
    setCurrentPage(1); // Reset to first page when search is triggered
  };

  if (cardBranchesError || tableBranchesError) {
    return (
      <div>
        Error loading branches:{" "}
        {cardBranchesError?.message || tableBranchesError?.message}
      </div>
    );
  }

  // Calculate the number of active and inactive branches for the cards (based on cardBranchesData)
  const activeBranches =
    cardBranchesData?.data?.filter((branch) => branch.active) || [];
  const inactiveBranches =
    cardBranchesData?.data?.filter((branch) => !branch.active) || [];

  const totalPages = tableBranchesData
    ? Math.ceil(tableBranchesData?.totalItems / itemsPerPage)
    : 1;

  return (
    <div className="p-4 bg-green">
      <div className="flex px-4 justify-end">
        <Button className="a" onClick={handleAddModal}>
          <PlusIcon className="mr-2 h-4 w-4 " />
          Add Branch
        </Button>
      </div>

      {/* Cards Section - Showing branch statistics */}
      <div className="grid grid-cols-3 gap-4 p-4">
        {isLoadingCardBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-yellow-500"}
            icon={TrendingUp}
            stat={cardBranchesData?.totalItems}
            title={"Total Branch"}
          />
        )}
        {isLoadingCardBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-green-500"}
            icon={TrendingUp}
            stat={activeBranches.length} // Count of active branches
            title={"Active Branch"}
          />
        )}
        {isLoadingCardBranches ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={" to-indigo-500"}
            icon={TrendingUp}
            stat={inactiveBranches.length} // Count of inactive branches
            title={"Inactive Branch"}
          />
        )}
      </div>

      {/* Table Section - Showing paginated branch list */}
      <div className="flex flex-col border rounded-lg border-gray-100 m-4">
        <ListHeader
          titleHeader={"Branch List"}
          setListLimit={setItemsPerPage} // Default is 10
          searchOnChange={handleSearchChange} // Pass the search handler
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          organziationData={organizationsdata}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          fromBranch={true}
        />
        {isLoadingTableBranches ? (
          <TableSekeleton />
        ) : (
          <Branchs
            branches={tableBranchesData?.data || []}
            handleModify={handleModify}
          />
        )}
        {/* Pagination for table data */}
        <nav
          aria-label="Page navigation example"
          className="flex justify-center py-2 "
        >
          <ul className="flex items-center -space-x-px h-8 text-sm">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === index + 1
                      ? "text-blue-600 border border-blue-300 bg-blue-50"
                      : "text-gray-500 bg-white border border-gray-300"
                  } hover:bg-gray-100 hover:text-gray-700`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {showAddModal && <AddBranchModal handleAddModal={handleAddModal} />}
      </div>
    </div>
  );
};

export default Branch;
