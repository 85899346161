import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const AgentDetailsModal = ({ isOpen, onClose, agent }) => {
  if (!isOpen) return null;
  console.log(agent.user_id, 'Agent');
  

  // Generate dummy data for the last 10 days
  const generateDummyData = () =>
    Array.from({ length: 10 }, (_, i) => ({
      day: `Day ${10 - i}`,
      actions: Math.floor(Math.random() * 10),
    }));

  const data = generateDummyData();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
      <div className="bg-white rounded-lg p-8 shadow-xl w-2/3 relative max-w-3xl">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-xl transition duration-200"
        >
          ✖
        </button>
        <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-4">
          Agent Details
        </h2>
        {agent ? (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <p className="text-gray-700"><strong>Name:</strong> {agent.agent_name}</p>
              <p className="text-gray-700"><strong>Appointments Submitted:</strong> {agent.appointments_submitted}</p>
              <p className="text-gray-700"><strong>Appointments Checked In:</strong> {agent.appointments_checked_in}</p>
              <p className="text-gray-700"><strong>Documents Checked Out:</strong> {agent.documents_checked_out}</p>
              <p className="text-gray-700"><strong>Total Actions:</strong> {agent.total_actions}</p>
            </div>
            <h3 className="text-xl font-semibold text-gray-800">Last 10 Days Actions</h3>
            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                  <XAxis dataKey="day" tick={{ fill: "#6b7280" }} />
                  <YAxis tick={{ fill: "#6b7280" }} />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      border: "1px solid #e5e7eb",
                    }}
                    itemStyle={{ color: "#374151" }}
                  />
                  <Line
                    type="monotone"
                    dataKey="actions"
                    stroke="#4f46e5"
                    strokeWidth={2}
                    dot={{ r: 4 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <p className="text-gray-600">No agent data selected.</p>
        )}
      </div>
    </div>
  );
};

export default AgentDetailsModal;
