import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaClipboardCheck,
  FaRegClock,
  FaUsers,
  FaExclamationTriangle,
  FaStar,
} from "react-icons/fa";
import { MdDeleteForever, MdOutlineCancel } from "react-icons/md";
import StatBox from "../../Components/common/StatBox";
import CardSkeleton from "../../Components/common/CardSkeleton";
import { toast, Toaster } from "sonner";

const AppointmentManager = () => {
  const [queues, setQueues] = useState([]); // State to store queue data
  const [loading, setLoading] = useState(false); // Loading state
  const [filterStatus, setFilterStatus] = useState(""); // State to filter by status
  const [filteredQueues, setFilteredQueues] = useState([]); // State to store filtered queues
  const [availableStatuses, setAvailableStatuses] = useState([]); // State to store unique statuses

  // Fetch today's queue data from the API
  const fetchTodayQueues = async () => {
    setLoading(true);
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Please log in.");
      }

      // Make the API request with the token in the headers
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/queues/today`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );

      // Check if the response contains valid data
      if (response.data && Array.isArray(response.data.queues)) {
        setQueues(response.data.queues); // Set the fetched queue data
        setFilteredQueues(response.data.queues); // Initialize filtered queues with all data

        // Extract unique statuses from the queues
        const uniqueStatuses = [
          ...new Set(response.data.queues.map((queue) => queue.status)),
        ];
        setAvailableStatuses(uniqueStatuses); // Set available statuses
      } else {
        setQueues([]); // Set queues to an empty array if no data is returned
        setFilteredQueues([]);
        setAvailableStatuses([]); // Reset available statuses
        toast.info("No queues found for today.");
      }
    } catch (error) {
      console.error("Error fetching today's queues:", error);
      toast.error("Failed to fetch today's queues.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchTodayQueues();
  }, []);

  // Filter queues based on status
  useEffect(() => {
    if (filterStatus === "") {
      setFilteredQueues(queues); // Show all queues if no filter is selected
    } else {
      const filtered = queues.filter((queue) => queue.status === filterStatus);
      setFilteredQueues(filtered); // Set filtered queues based on status
    }
  }, [filterStatus, queues]);

  return (
    <div className="container mx-auto p-6">
      <Toaster richColors position="top-right" />
      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="grid grid-cols-4 gap-4 mb-6 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4">
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-yellow-500"}
            icon={FaUsers}
            title={"Total"}
            stat={queues.length}
            notes={
              "Waiting: " +
              queues.filter((queue) => queue.status === "waiting").length
            }
          />
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-500"}
            icon={FaRegClock}
            title={"In Progress"}
            stat={
              queues.filter((queue) => queue.status === "in_progress").length
            }
          />
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-200"}
            icon={FaClipboardCheck}
            title={"Completed"}
            stat={queues.filter((queue) => queue.status === "completed").length}
            notes={
              "Canceled: " +
              queues.filter((queue) => queue.status === "canceled").length
            }
          />
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-gray-300"}
            icon={FaExclamationTriangle}
            title={"On Hold"}
            stat={queues.filter((queue) => queue.status === "onHold").length}
          />
        </div>
      </div>

      {/* Filter by status dropdown */}
      <div className="flex flex-col sm:flex-row mb-4 w-full max-w-full space-y-2 sm:space-y-0 sm:space-x-2">
        <select
          className="px-4 py-2 bg-blue-500 text-white rounded-md sm:w-auto w-full"
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="">All Statuses</option>
          {availableStatuses.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>

      {/* Display queues */}
      {loading ? (
        <p className="text-gray-500">Loading...</p>
      ) : filteredQueues.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8 w-full max-w-8xl px-4 sm:px-0">
          {filteredQueues.map((queue) => (
            <div
              key={queue.queue_id}
              className="relative bg-white shadow-md rounded-xl p-8 border border-gray-300 transition duration-300 ease-in-out transform hover:shadow-xl"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {queue.firstname} {queue.lastname}
                </h3>
                <button
                  onClick={() => {
                    // Handle delete or other actions
                  }}
                  className="rounded-full p-2"
                >
                  <MdDeleteForever className="size-6 text-red-500" />
                </button>
              </div>
              <p className="text-gray-700 mb-1">Queue No: {queue.queue_no}</p>
              <p className="text-gray-700">
                Status:{" "}
                <span
                  className={`font-semibold ${
                    queue.status === "completed"
                      ? "text-green-500"
                      : queue.status === "canceled"
                      ? "text-red-500"
                      : "text-yellow-500"
                  }`}
                >
                  {queue.status}
                </span>
              </p>
              <p className="text-gray-700">
                Branch: {queue.branch_name || "N/A"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No queues found for the selected status.</p>
      )}
    </div>
  );
};

export default AppointmentManager;