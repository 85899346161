import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const SearchBar = ({ searchTerm, handleSearchTermChange }) => {
  return (
    <div className="relative flex justify-between mb-4">
      <span className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
      </span>
      <input
        id="search"
        type="text"
        value={searchTerm}
        onChange={handleSearchTermChange}
        className="border rounded-md p-2 pl-10"
        placeholder="Search..."
      />
    </div>
  );
};

export default SearchBar;
