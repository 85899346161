import React from "react";
import { Pie, PieChart, Label } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../charts/Chart";
import AppointmentLabel from "./AppointmentLabel";

const AppointmentPieChart = ({ chartData, totalAppointments }) => (
  <ChartContainer className="mx-auto aspect-square max-h-[250px]" config={{}}>
    <PieChart>
      <ChartTooltip
        cursor={false}
        content={<ChartTooltipContent hideLabel />}
      />
      <Pie
        data={chartData}
        dataKey="count"
        nameKey="status"
        innerRadius={60}
        outerRadius={80}
        strokeWidth={5}
      >
        <Label
          content={({ viewBox }) =>
            AppointmentLabel({ ...viewBox, totalAppointments })
          }
        />
      </Pie>
    </PieChart>
  </ChartContainer>
);

export default AppointmentPieChart;
