import axios from "axios";

const fetchAllAppointments = async (startingDate, endingDate) => {
  // let allAppointments = [];
  // let currentPage = 1;
  // let totalPages = 1;

  // Retrieve token from localStorage
  const token = localStorage.getItem("token");

  let URL = "";
  if (startingDate && endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?from_date=${startingDate}&to_date=${endingDate}`;
  } else if (startingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?from_date=${startingDate}`;
  } else if (endingDate) {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch?to_date=${endingDate}`;
  } else {
    URL = `${process.env.REACT_APP_API_URL}/v1/appointments/countsByBranch`;
  }

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`, // Add the token to the Authorization header
    },
  });

  //   const responseData = response.data;
  //   if (responseData.status === "success" && responseData.data) {
  //     const { data, totalPages: fetchedTotalPages } = responseData.data;
  //     if (Array.isArray(data)) {
  //       allAppointments = [...allAppointments, ...data];
  //     }
  //     totalPages = fetchedTotalPages;
  //     currentPage += 1;
  //   } else {
  //     console.error("Failed to fetch data", responseData);
  //     break;
  //   }
  // } while (currentPage <= totalPages);
  // } catch (error) {
  //   console.error("Error fetching appointments", error);
  // }
  const allAppointments = response.data.data;

  return allAppointments;
};

export default fetchAllAppointments;
