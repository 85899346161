import React, { useContext, useState } from "react";
import TableSkeleton from "../../Components/common/TableSekeleton";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/auth";
import axios from "axios";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/common/Table";
import { TableCell } from "@mui/material";
import { toast, Toaster } from "sonner";
import Calendar from "../appointmentduration/CalendarOfficerPerDay";

const fetchBranches = async () => {
  const url = `${process.env.REACT_APP_API_URL}/v1/branches`;
  const response = await axios.get(url);
  return response.data.data;
};

const fetchBranchConfigurations = async (branchId, page, limit) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/branch_configurations?branchId=${branchId}&page=1&limit=10`
    );
    // if (!response.ok) {
    //   throw new Error("Error while fetching Officers per day.");
    // }
    return response.data;
  } catch (error) {
    console.error("Error fetching branch configurations:", error);
  }
};

const SlotPerDay = ({ handleAddOfficerPerDay }) => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const branchId = user?.employee?.branch_id;

  const [active, setActive] = useState(false);
  const [specific_date, setSpecific_date] = useState(null);
  const [branch_services_per_slot, setBranch_services_per_slot] =
    useState(null);
  const [paid_percentage, setPaid_percentage] = useState(null);
  const [number_of_officers, setNumber_of_officers] = useState(null);
  const [service_per_slot, setService_per_slot] = useState(null);
  const [addNewForm, setAddNewForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [selectedBranch, setSelectedBranch] = useState(branchId);

  const BranchConfTable = (data) => {
    console.log("data: ", data.data);

    const handleEditClick = (data) => {
      let toUpdateData = {
        ...data,
        paid_percentage: Number(data?.paid_percentage) * 100,
      };
      console.log("THE DATA FROM EDIT: ", toUpdateData);
      setIsEdit(!isEdit);
      setEditData(toUpdateData);
    };
    const officerData = {};
    data?.data?.forEach((item) => {
      const date = new Date(item.specific_date);
      const dayOfMonth = date.getDate();
      officerData[dayOfMonth] = item;
    });

    return (
      <Calendar
        month={11}
        year={2024}
        officerData={officerData}
        data={data}
        handleEditClick={handleEditClick}
      />
    );
  };

  const { data: branchConfigurationData, isLoading } = useQuery({
    queryKey: ["branchConfigurations", selectedBranch],
    queryFn: () => fetchBranchConfigurations(selectedBranch),
  });
  console.log("*****: ", branchConfigurationData);

  // Fetch branches data
  const { data: branchesData } = useQuery({
    queryKey: ["branches"],
    queryFn: fetchBranches,
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      branch_services_per_slot: Number(branch_services_per_slot),
      branch_id: selectedBranch,
      specific_date,
      active,
      number_of_officers: Number(number_of_officers),
      service_per_slot: Number(service_per_slot),
      paid_percentage: parseFloat(30) / 100,
    };
    console.log("The data to be sent: ", data);
    try {
      const url = `${process.env.REACT_APP_API_URL}/v1/branch_configurations`;
      const response = await axios({
        url,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("The response is: ******: ", response);
      if (response.status === 201) {
        if (response.data.status === "success") {
          queryClient.invalidateQueries("branchConfigurations");
          toast.success("Branch Configuration added successfully");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      toast.error("Failed to add Branch Configuration.");
    } finally {
      setLoading(false);
      setAddNewForm(false);
    }
  };
  const handleAddNewButton = () => {
    setAddNewForm(!addNewForm);
    setIsEdit(false);
  };
  const updateHandler = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    const data = {
      id: editData.id,
      branch_services_per_slot: Number(editData.branch_services_per_slot),
      branch_id: editData.branch_id, // Corrected to match your key
      specific_date: editData.specific_date,
      active: editData.active,
      number_of_officers: Number(editData.number_of_officers),
      service_per_slot: Number(editData.service_per_slot),
      paid_percentage: parseFloat(30) / 100,
    };

    try {
      const url = `${process.env.REACT_APP_API_URL}/v1/branch_configurations/${editData.id}`;
      const response = await axios({
        url,
        method: "put", // Use PUT or PATCH for updates
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Update Response:", response);

      if (response.status === 200) {
        queryClient.invalidateQueries("branchConfigurations");
        toast.success("Branch Configuration updated successfully");
        setIsEdit(false);
      }
    } catch (error) {
      console.error("Error updating branch configuration:", error);
      toast.error("Failed to update Branch Configuration.");
    } finally {
      setUpdateLoading(false);
      setIsEdit(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent backdrop-blur-sm z-50 overflow-auto">
      <Toaster position="top-right" richColors />
      <div className="relative p-4 w-full md:w-auto max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 md:p-5 border-b border-gray-300">
            <h3 className="text-lg font-semibold text-gray-900 text-center">
              Add Officer Per Day
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleAddOfficerPerDay}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/*  */}
          <div className="flex items-center justify-around">
            {!isEdit && !addNewForm && (
              <button
                className={`p-2 ${
                  addNewForm ? "bg-yellow-400" : "bg-blue-500"
                } text-white rounded-md my-5`}
                onClick={handleAddNewButton}
              >
                Add New
              </button>
            )}
          </div>
          <div className="flex flex-row">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <div className="m-10">
                <BranchConfTable data={branchConfigurationData?.data} />
              </div>
            )}
            {(addNewForm || isEdit) && (
              <div
                className="p-4 md:p-5"
                // onSubmit={submitHandler}
              >
                {addNewForm ? (
                  <h1 className="text-center text-green-600 text-2xl m-5">
                    Add New Branch Configuration
                  </h1>
                ) : (
                  <h1 className="text-center text-yellow-600 text-2xl m-5">
                    Edit Branch Configuration
                  </h1>
                )}
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="mt-5">
                    <label className="block text-gray-700">Date</label>
                    <input
                      type="date" // Changed from 'text' to 'date'
                      name="specific_date"
                      id="specific_date"
                      className="bg-gray-100 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="YYYY-MM-DD" // This may not be needed for date input
                      value={isEdit ? editData.specific_date : specific_date}
                      onChange={
                        isEdit
                          ? (e) =>
                              setEditData({
                                ...editData,
                                specific_date: e.target.value,
                              })
                          : (e) => setSpecific_date(e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block text-gray-700">
                      Branch Services per Slot
                    </label>
                    <input
                      type="number"
                      name="branch_service_per_slot"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      value={
                        isEdit
                          ? editData.branch_services_per_slot
                          : branch_services_per_slot
                      }
                      onChange={(e) =>
                        isEdit
                          ? setEditData({
                              ...editData,
                              branch_services_per_slot: e.target.value,
                            })
                          : setBranch_services_per_slot(e.target.value)
                      }
                      required
                    />
                  </div>
                  {/* <div className="mt-5">
                    <label className="block text-gray-700">
                      Paid Percentage
                    </label>
                    <input
                      type="number"
                      name="paid_percentage"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      value={
                        isEdit ? editData?.paid_percentage : paid_percentage
                      }
                      onChange={(e) =>
                        isEdit
                          ? setEditData({
                              ...editData,
                              paid_percentage: e.target.value,
                            })
                          : setPaid_percentage(e.target.value)
                      }
                      required
                    />
                  </div> */}
                  <div className="mt-5">
                    <label className="block text-gray-700">
                      Number Of Officers
                    </label>
                    <input
                      type="number"
                      name="number_of_officers"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      value={
                        isEdit
                          ? editData.number_of_officers
                          : number_of_officers
                      }
                      onChange={(e) =>
                        isEdit
                          ? setEditData({
                              ...editData,
                              number_of_officers: e.target.value,
                            })
                          : setNumber_of_officers(e.target.value)
                      }
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block text-gray-700">
                      Service Per Slot
                    </label>
                    <input
                      type="number"
                      name="service_per_slot"
                      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      value={
                        isEdit ? editData.service_per_slot : service_per_slot
                      }
                      onChange={(e) =>
                        isEdit
                          ? setEditData({
                              ...editData,
                              service_per_slot: e.target.value,
                            })
                          : setService_per_slot(e.target.value)
                      }
                    />
                  </div>
                  <div className="mt-14">
                    <div className="flex items-center mb-4 ">
                      <input
                        type="checkbox"
                        checked={isEdit ? editData.active : active}
                        onChange={(e) =>
                          isEdit
                            ? setEditData({
                                ...editData,
                                active: e.target.checked,
                              })
                            : setActive(e.target.checked)
                        }
                        className="mr-2"
                      />
                      <label>Active</label>
                    </div>
                  </div>
                </div>
                <div>
                  {isEdit && !addNewForm ? (
                    <div>
                      <button
                        onClick={(e) => updateHandler(e)}
                        disabled={loading}
                        name="update"
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md"
                      >
                        {updateLoading ? "Loading..." : "Update"}
                      </button>
                      <button
                        onClick={() => setIsEdit(false)}
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md mx-5"
                      >
                        cancle
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        disabled={loading}
                        onClick={submitHandler}
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded-md"
                      >
                        {loading ? "Loading..." : "Add Officer/Day"}
                      </button>
                      <button
                        onClick={() => setAddNewForm(false)}
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md mx-5"
                      >
                        cancle
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotPerDay;
