// AgentPerformance.js
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AgentPerformance = ({ data }) => {
  // Prepare data for chart
  const chartData = {
    labels: data.map((officer) => `${officer.firstname} ${officer.lastname}`),
    datasets: [
      {
        label: "Today",
        data: data.map((officer) => officer.served_today),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "This Week",
        data: data.map((officer) => officer.served_this_week),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
      {
        label: "This Month",
        data: data.map((officer) => officer.served_this_month),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
      },
      {
        label: "Total Appointments",
        data: data.map((officer) => officer.served_all_time),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h2 className="text-lg font-semibold">Agent Performance</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default AgentPerformance;
