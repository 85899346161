const statusColors = {
  callCenter: "#0088FE",
  self: "#FFBB28",
  // self: "#00C49F",
  // "checked in": "#FFBB28",
  // "checked out": "#FF8042",
  // expired: "#808080",
  // inCompleted: "#EC5800",
};

const processAppointments = (appointments) => {
  // const statusCounts = {
  //   booked: 0,
  //   confirmed: 0,
  //   "checked in": 0,
  //   "checked out": 0,
  // };

  // appointments.forEach(({ status }) => {
  //   if (status in statusCounts) {
  //     statusCounts[status] += 1;
  //   }
  // });
  const chartData = appointments.map((item) => ({
    ...item,
    count: Number(item.appointment_count),
    fill: statusColors[item.appointment_through],
  }));
  // return Object.entries(statusCounts).map(([status, count]) => ({
  //   status,
  //   count,
  //   fill: statusColors[status],
  // }));
  return chartData;
};

export default processAppointments;
