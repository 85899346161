import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/auth";

const ExtraSlotsModal = ({ isOpen, onClose, branchId, name }) => {
  const { token } = useContext(AuthContext); // Get the token from the AuthContext
  const [extraSlotStatus, setExtraSlotStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch branch data when modal is opened
  useEffect(() => {
    if (isOpen && branchId) {
      setLoading(true);
      setError(null);

      // Fetch branch data using the branchId and the Authorization token
      fetch(`${process.env.REACT_APP_API_URL}/v1/branches/by-id?id=${branchId}`, {
        headers: {
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.branch && data.branch.extra_slot_status !== undefined) {
            setExtraSlotStatus(data.branch.extra_slot_status); // Correctly access extra_slot_status
          } else {
            setError("Could not retrieve branch data.");
          }
          setLoading(false);
        })
        .catch(() => {
          setError("An error occurred while fetching data.");
          setLoading(false);
        });
    }
  }, [isOpen, branchId, token]); // Add token to dependency array

  const handleConfirm = () => {
    if (extraSlotStatus === null) return; // Prevent sending request if no status is available

    // Toggle extra_slot_status
    const newStatus = !extraSlotStatus;

    // Send request to update the extra_slot_status using the new API
    setLoading(true); // Set loading to true when starting the update process
    fetch(`${process.env.REACT_APP_API_URL}/v1/branches/extra-slot-status/${branchId}`, {
      method: "PUT", // Use PUT to update the status
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
      },
      body: JSON.stringify({ extra_slot_status: newStatus }), // Sending the new status in the body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.extra_slot_status !== undefined) {
          console.log(`Extra Slot status updated for Branch: ${name} (ID: ${branchId})`);
          setExtraSlotStatus(newStatus); // Update the state to reflect the new status
          onClose(); // Close the modal after confirming
        } else {
          setError("Failed to update the extra slot status.");
        }
      })
      .catch((err) => {
        setError("An error occurred while updating the status.");
        console.error(err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false once the request is completed
      });
  };

  if (!isOpen) return null;

  // Helper function to determine the color for the status
  const statusColor = extraSlotStatus ? "bg-green-500" : "bg-red-500";
  const statusText = extraSlotStatus ? "Active" : "Inactive";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg shadow-md z-50 max-w-lg w-full">
        <h2 className="text-lg font-semibold mb-4 text-center">Activate Extra Slot</h2>
        {loading ? (
          <p className="text-gray-600">Loading...</p>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <>
            <p className="text-gray-600 mb-4">
              You are about to activate extra slots for the {name} branch.
            </p>
            <p className={`text-white mb-4 py-2 px-4 rounded-full ${statusColor}`}>
              Current status: {statusText}
            </p>
            <p className="text-gray-600 mb-4">
              Are you sure you want to {extraSlotStatus ? "deactivate" : "activate"} extra slots for this branch?
            </p>
          </>
        )}
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? "Processing..." : "Confirm"}
          </button>
          <button
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md ml-2 hover:bg-gray-400 transition duration-200"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExtraSlotsModal;
