import React, { useContext, useState } from "react";
import { toast } from "sonner";
import Button from '../../Components/common/Button';
import { AuthContext } from '../../contexts/auth';
import { Locate } from "lucide-react";

const AddLocation = ({ handleAddModal }) => {
  const { token } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    name: "",
    landmark: "",
    address: "",
    city: "",
    region: "",
    country: "Ethiopia",
    zone: "",
    latitude: "",
    longitude: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    for (let [key, value] of Object.entries(formValues)) {
      if (value.trim() === "") {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormValues(prevValues => ({
            ...prevValues,
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
          }));
        },
        (error) => {
          toast.error('Error getting location. Please enter manually.');
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser. Please enter manually.');
    }
  };

  const handleAddClick = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    const locationData = {
      ...formValues,
      coordinates: {
        type: "Point",
        coordinates: [parseFloat(formValues.longitude), parseFloat(formValues.latitude)],
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/locations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(locationData),
      });

      if (!response.ok) {
        const responseData = await response.json();
        toast.error(responseData.message || 'Failed to add location.');
      } else {
        handleAddModal();  // Close modal
        toast.success('Location added successfully');
      }
    } catch (error) {
      console.error('Error adding location:', error);
      toast.error('Oops! Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="justify-center items-center flex bg-opacity-70 backdrop-blur-sm overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-gray-900 rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold text-white">Add New Location</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-700 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleAddModal}
            >
              <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5" onSubmit={handleAddClick}>
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type location name"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
              </div>
              {/* Landmark input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="landmark" className="block mb-2 text-sm font-medium text-white">
                  Landmark
                </label>
                <input
                  type="text"
                  name="landmark"
                  id="landmark"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter a landmark"
                  value={formValues.landmark}
                  onChange={handleChange}
                  required
                />
                {errors.landmark && <p className="text-red-500 text-xs">{errors.landmark}</p>}
              </div>
              {/* Address input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-white">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter the address"
                  value={formValues.address}
                  onChange={handleChange}
                  required
                />
                {errors.address && <p className="text-red-500 text-xs">{errors.address}</p>}
              </div>
              {/* City input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="city" className="block mb-2 text-sm font-medium text-white">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter the city"
                  value={formValues.city}
                  onChange={handleChange}
                  required
                />
                {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
              </div>
              {/* Zone input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="zone" className="block mb-2 text-sm font-medium text-white">
                  Zone
                </label>
                <input
                  type="text"
                  name="zone"
                  id="zone"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter the zone"
                  value={formValues.zone}
                  onChange={handleChange}
                  required
                />
                {errors.zone && <p className="text-red-500 text-xs">{errors.zone}</p>}
              </div>
              {/* Region input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="region" className="block mb-2 text-sm font-medium text-white">
                  Region
                </label>
                <input
                  type="text"
                  name="region"
                  id="region"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter the region"
                  value={formValues.region}
                  onChange={handleChange}
                  required
                />
                {errors.region && <p className="text-red-500 text-xs">{errors.region}</p>}
              </div>
              {/* Country input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="country" className="block mb-2 text-sm font-medium text-white">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter the country"
                  value={formValues.country}
                  onChange={handleChange}
                  required
                />
                {errors.country && <p className="text-red-500 text-xs">{errors.country}</p>}
              </div>
              {/* Latitude input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-white">
                  Latitude
                </label>
                <input
                  type="text"
                  name="latitude"
                  id="latitude"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Latitude"
                  value={formValues.latitude}
                  onChange={handleChange}
                  required
                />
                {errors.latitude && <p className="text-red-500 text-xs">{errors.latitude}</p>}
              </div>
              {/* Longitude input */}
              <div className="col-span-2 sm:col-span-1">
                <label htmlFor="longitude" className="block mb-2 text-sm font-medium text-white">
                  Longitude
                </label>
                <input
                  type="text"
                  name="longitude"
                  id="longitude"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Longitude"
                  value={formValues.longitude}
                  onChange={handleChange}
                  required
                />
                {errors.longitude && <p className="text-red-500 text-xs">{errors.longitude}</p>}
              </div>
              {/* Get Location button */}
              <div className="col-span-2">
                <Button
                  variant="outline"
                  type="button"
                  onClick={handleGetLocation}
                  className="w-full"
                >
                  <Locate/>
                 Get Location
                 </Button>
              </div>
              {/* Description input */}
              <div className="col-span-2">
                <label htmlFor="description" className="block mb-2 text-sm font-medium text-white">
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  rows="2"
                  className="bg-gray-800 border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter a description"
                  value={formValues.description}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.description && <p className="text-red-500 text-xs">{errors.description}</p>}
              </div>

              {/* Add button */}
              <div className="col-span-2">
                <Button
                  type="submit"
                  className="w-full"
                >Add Location</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
