import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../../contexts/auth";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import LoadingSpinner from "../LoadingSpinner";
import fetchAllAppointments from "./fetchAllAppointments";
import processAppointments from "./processAppointments";
import AppointmentPieChart from "./AppointmentPieChart";
import StatusLegend from "./StatusLegend";

const PieChart = ({ startingDate, endingDate, selectedBranch }) => {
    const {user,  token } = useContext(AuthContext);

    console.log(token, 'token in the building');
  const {
    data: appointmentsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["appointments", token, startingDate, endingDate, selectedBranch],
    queryFn: ({ queryKey }) =>
      fetchAllAppointments(queryKey[1], queryKey[2], queryKey[3], queryKey[4]),
    refetchInterval: 10000,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) return <div>Error loading data</div>;

  const chartData = Array.isArray(appointmentsData)
    ? processAppointments(appointmentsData)
    : [];
  const totalAppointments = chartData.reduce(
    (acc, { count }) => acc + count,
    0
  );

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>Appointments Status</CardTitle>
        <CardDescription>Current Appointments Breakdown</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <AppointmentPieChart
          chartData={chartData}
          s
          totalAppointments={totalAppointments}
        />
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <StatusLegend chartData={chartData} />
        <div className="leading-none text-muted-foreground">
          Showing status breakdown of all current appointments.
        </div>
      </CardFooter>
    </Card>
  );
};

export default PieChart;
