import React, { useContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Toaster, toast } from "sonner";
import UserModal from "./UserModal";
import UserList from "./UserList";
import TableSkeleton from "../../Components/common/TableSekeleton";
import { PlusCircleIcon, TrendingUp } from "lucide-react";
import { AuthContext } from "../../contexts/auth";
import CardSkeleton from "../../Components/common/CardSkeleton";
import StatBox from "../../Components/common/StatBox";
import ListHeader from "../../Components/common/ListHeader";

// Fetch functions
const fetchUsers = async (page, limit, token, searchQuery) => {
  let url = searchQuery
    ? `${process.env.REACT_APP_API_URL}/v1/users?page=${page}&limit=${limit}&searchQuery=${searchQuery}`
    : `${process.env.REACT_APP_API_URL}/v1/users?page=${page}&limit=${limit}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error("Error fetching users");
  return response.json();
};

const fetchOrganizations = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/organizations/all`
  );
  if (!response.ok) throw new Error("Error fetching organizations");
  return response.json();
};

const fetchBranches = async (organizationId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${organizationId}`
  );
  if (!response.ok) throw new Error("Error fetching branches");
  return response.json();
};

const fetchWindows = async (branchId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/windows?branchId=${branchId}`
  );
  if (!response.ok) throw new Error("Error fetching Windows");
  return response.json();
};

const fetchRoles = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/roles/all`);
  if (!response.ok) throw new Error("Error fetching roles");
  return response.json();
};

const addUser = async (user) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/add_employee`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  );
  if (!response.ok) throw new Error("Failed to create user");
  return response.json();
};

const updateUser = async (updatedUser) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/edit_employee`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedUser),
    }
  );
  if (!response.ok) throw new Error("Failed to update user");
  return response.json();
};

const ManageUser = () => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState("");
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("123123");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [windowId, setWindowId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { token } = useContext(AuthContext);

  const { data: userData, isLoading: userLoading } = useQuery({
    queryKey: ["users", currentPage, itemsPerPage, searchQuery],
    queryFn: () => fetchUsers(currentPage, itemsPerPage, token, searchQuery),
  });

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchOrganizations,
  });

  const { data: branches } = useQuery({
    queryKey: ["branches", organizationId],
    queryFn: () => fetchBranches(organizationId),
    enabled: !!organizationId,
  });

  const { data: windows } = useQuery({
    queryKey: ["window", branchId, organizationId],
    queryFn: () => fetchWindows(branchId),
    enabled: !!branchId && !!organizationId,
  });

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRoles,
  });

  const addUserMutation = useMutation({
    mutationFn: addUser,
    onSuccess: () => {
      toast.success("User created successfully");
      queryClient.invalidateQueries(["users"]);
      closeModal();
    },
    onError: () => {
      toast.error("Error adding user");
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      toast.success("User updated successfully");
      queryClient.invalidateQueries(["users"]);
      setIsEditing(false);
      closeModal();
    },
    onError: () => {
      toast.error("Error updating user");
    },
  });

  const openModal = () => {
    setIsEditing(false);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setUserId(null);
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setPassword("123123");
    setOrganizationId("");
    setBranchId("");
    setRoleId("");
    setWindowId("");
  };

  const validateForm = () => {
    let isValid = true;
    if (!firstName) {
      toast.error("First name is required.");
      isValid = false;
    }
    if (!lastName) {
      toast.error("Last name is required.");
      isValid = false;
    }
    if (!phoneNumber) {
      toast.error("Phone number is required.");
      isValid = false;
    }
    if (!password) {
      toast.error("Password is required.");
      isValid = false;
    }
    return isValid;
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const newUser = {
      firstname: firstName,
      lastname: lastName,
      mobile: phoneNumber,
      password,
      roleId,
      branchId,
      organizationId,
      window_id: windowId,
    };
    addUserMutation.mutate(newUser);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const updatedUser = {
      id: id,
      firstname: firstName,
      lastname: lastName,
      mobile: phoneNumber,
      roleId,
      branchId,
      organizationId,
      window_id: windowId,
    };
    console.log("*****: ", updatedUser);
    updateUserMutation.mutate(updatedUser);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //   const currentItems =
  //     userData?.users
  //       ?.filter((user) => {
  //         const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
  //         return fullName.includes(searchQuery.toLowerCase());
  //       })
  //       .slice(indexOfFirstItem, indexOfLastItem) || [];

  const currentItems = userData?.users;
  //   const totalPages = Math.ceil((userData?.users?.length || 0) / itemsPerPage);
  const totalPages = userData
    ? Math.ceil(userData?.total_employees / itemsPerPage)
    : 1;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="p-6 bg-white">
      <Toaster richColors position="top-right" />
      <div className="grid grid-cols-3 gap-4 p-4">
        {userLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-yellow-500"}
            icon={TrendingUp}
            stat={userData?.total_employees}
            title={"Total Users"}
          />
        )}
        {userLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-500"}
            icon={TrendingUp}
            stat={`${userData?.activePercentage}%`}
            title={"Active Users"}
          />
        )}
        {userLoading ? (
          <CardSkeleton />
        ) : (
          <StatBox
            fromColor={"from-primary"}
            toColor={"to-green-200"}
            icon={TrendingUp}
            stat={"42"} // This should be dynamically fetched
            title={"Working Users"}
          />
        )}
      </div>
      <div className="flex px-4 justify-end space-x-4">
        <button
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          onClick={openModal}
        >
          <PlusCircleIcon className="h-5 w-5 mr-2 inline-block" />
          Add New User
        </button>
      </div>
      <div className="flex flex-col border rounded-lg border-gray-100 m-4">
        <ListHeader
          titleHeader={"User List"}
          searchOnChange={handleSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setListLimit={setItemsPerPage}
        />
      </div>
      {userLoading ? (
        <TableSkeleton />
      ) : (
        <div className="flex flex-col border rounded-lg border-gray-100 m-4">
          <UserList
            currentItems={currentItems}
            currentPage={currentPage}
            totalPages={totalPages}
            searchQuery={searchQuery}
            setItemsPerPage={setItemsPerPage}
            handleSearch={handleSearch}
            // handlePageChange={handlePageChange}
            getUserInfo={(userId) => {
              const user = userData?.users.find((u) => u.id === userId);
              console.log("USER: ", user);
              setUserId(userId);
              setId(user.user_id);
              setFirstName(user.firstname);
              setLastName(user.lastname);
              setPhoneNumber(user.mobile);
              setOrganizationId(user.organization_id);
              setBranchId(user.branch_id);
              setRoleId(user.auth_role_id);
              setIsEditing(true);
              setIsModalOpen(true);
            }}
            openModal={openModal}
          />
        </div>
      )}
      <nav
        aria-label="Page navigation example"
        className="flex justify-center py-2 "
      >
        <ul className="flex items-center -space-x-px h-8 text-sm">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index}>
              <button
                onClick={() => {
                  handlePageChange(index + 1);
                }}
                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                  currentPage === index + 1
                    ? "text-blue-600 border border-blue-300 bg-blue-50"
                    : "text-gray-500 bg-white border border-gray-300"
                } hover:bg-gray-100 hover:text-gray-700`}
              >
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <UserModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isEditing={isEditing}
        setWindowId={setWindowId}
        handleFirstNameChange={(e) => setFirstName(e.target.value)}
        handleLastNameChange={(e) => setLastName(e.target.value)}
        handlePhoneNumberChange={(e) => setPhoneNumber(e.target.value)}
        handlePassword={(e) => setPassword(e.target.value)}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        password={password}
        organizationId={organizationId}
        branchId={branchId}
        // roleId={roleId}
        handleAddClick={handleAddClick}
        handleEditClick={handleEditClick}
        organizations={organizations}
        branches={branches}
        windows={windows}
        roles={roles}
        roleId={roleId}
        setOrganizationId={setOrganizationId}
        setBranchId={setBranchId}
        setRoleId={setRoleId}
        isLoading={addUserMutation.isLoading || updateUserMutation.isLoading} // Check loading state
      />
    </div>
  );
};

export default ManageUser;
