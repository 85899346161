import {
  ArrowLeftStartOnRectangleIcon,
  ArrowPathIcon,
  Bars4Icon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import { AuthContext } from "../../contexts/auth";
import { NavigationSidebarContext } from "../../contexts/navigationSidebar";
import AttendanceToggle from "./Attendance"; // Import the new component

function Header() {
  const navigate = useNavigate();
  const [branchName, setBranchName] = useState(null);
  const [windowNumber, setWindowNumber] = useState(null);
  const { token, setToken, setUser, user } = useContext(AuthContext);

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/branches/id`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setBranchName(data?.branch?.name);
        setWindowNumber(data?.userDetails?.name);
      } catch (error) {
        console.error("Error fetching branch data:", error);
      }
    };

    fetchBranchData();
  }, [user, token]);

  const {
    isNavigationSidebarMinimized,
    setNavigationSidebarMinimized,
    isNavigationSidebarOpen,
  } = useContext(NavigationSidebarContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadStationNameTitle, setLoadStationNameTitle] = useState(true);
  const [loadRoleNameTitle, setLoadRoleNameTitle] = useState(true);
  const [stationName, setStationName] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [image, setImage] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = screenWidth < 768;

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setToken(null);
    setUser(null);
    navigate("/signin");
    setMenuOpen(false);
  };

  const handleUpdateProfile = () => {
    setMenuOpen(false);
    navigate("/profile");
  };

  return (
    <>
      <Toaster position="top-right" richColors />
      <header
        className={`flex items-center justify-between p-2 text-white ${
          isMobile ? "mt-16" : "mt-auto"
        }`}
      >
        <div className={`${user?.role?.auth_role_id==='af43ff03-13d4-4c22-ab35-ae9e59408b20'?'hidden':'flex'}  items-center space-x-2`}>
          <button
            className={`text-primary ${isMobile ? "hidden" : "block"}`}
            onClick={() =>
              setNavigationSidebarMinimized(!isNavigationSidebarMinimized)
            }
          >
            <Bars4Icon className="h-6 w-6" />
          </button>
          <p className=" text-gray-800">Welcome Back,</p>
          <h2 className="text-primary font-semibold">
            {" "}
            {user.firstname} {user.lastname}
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          {/* Move AttendanceToggle here for better visibility */}
          <AttendanceToggle />

          {/* Profile Button */}
          <div className="flex justify-start border border-gray-300 w-12 p-1 rounded-lg">
            <button
              className="flex items-center text-black space-x-4"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {loadingImage ? (
                <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500" />
              ) : (
                <img
                  className="size-7 rounded-lg cursor-pointer border-gray-100 "
                  src={image || "icon.png"}
                  alt="Profile"
                />
              )}
            </button>
          </div>
        </div>

        {/* Dropdown Menu */}
        {menuOpen && (
          <div
            ref={menuRef}
            className={`absolute ${user?.role?.auth_role_id==='af43ff03-13d4-4c22-ab35-ae9e59408b20'?'left-5':'right-5'}   mt-44 w-60 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}
          >
            <div
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="px-4 py-2 text-gray-700">
                <p className="font-bold ">{user.mobile}</p>
                {branchName ? (
                  <p className="font-medium text-gray-600">{branchName} Branch</p>
                ) : (
                  <p className="font-medium text-gray-600">Organizational Access</p>
                )}
                {user?.role?.auth_role_id === 'af43ff03-13d4-4c22-ab35-ae9e59408b20' && (
                  <p className="font-medium">Window No: {windowNumber}</p>
                )}
              </div>
    
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                role="menuitem"
                onClick={handleUpdateProfile}
              >
                <UserIcon className="h-6 w-6" />
                <p>Update profile</p>
              </button>
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                role="menuitem"
                onClick={handleLogout}
              >
                <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />
                <p>Logout</p>
              </button>
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default Header;