import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";
import ServiceSelection from "./Steps/ServiceSelection";
import DateSelectionForm from "./Steps/DateSelection";
import TimeSlotSelection from "./Steps/TimeSlotSelection";
import { toast, Toaster } from "sonner";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  appointmentDetails,
  onInputChange,
  userDetails,
  loading,
  hasDocuments,
  setHasDocuments,
}) => {
  const [errors, setErrors] = useState({});

  if (!isOpen) return null;
  console.log(loading, "status");

  const normalizePhoneNumber = (mobile) => {
    // Remove all non-numeric characters except leading "+"
    let normalizedMobile = mobile.replace(/[^0-9+]/g, "");

    // Check if the number starts with +251 (Ethiopia's country code)
    if (normalizedMobile.startsWith("+251")) {
      return normalizedMobile; // Already in the correct format
    }

    // Check if the number starts with 0, replace with +251
    if (normalizedMobile.startsWith("0")) {
      normalizedMobile = "+251" + normalizedMobile.slice(1); // Replace leading 0 with +251
    }

    // If the number is 9 digits and doesn't start with + or 0, assume it's local
    if (/^\d{9}$/.test(normalizedMobile)) {
      normalizedMobile = "+251" + normalizedMobile; // Prepend +251
    }

    console.log("Formatted Mobile Number:", normalizedMobile);
    return normalizedMobile;
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!userDetails.first_name) {
      newErrors.first_name = "First name is required.";
    }
    if (!userDetails.last_name) {
      newErrors.last_name = "Last name is required.";
    }

    // Normalize and store the mobile number in userDetails
    let formattedMobile = normalizePhoneNumber(userDetails.mobile);
    userDetails.mobile = formattedMobile; // Update userDetails with the normalized mobile number

    if (!formattedMobile) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\+251\d{9}$/.test(formattedMobile)) {
      newErrors.mobile = "Mobile number must be in the format +251XXXXXXXXX.";
    }

    if (userDetails.document_count > 3) {
      newErrors.document_count = "Only 3 Documents are allowed.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirm = () => {
    if (validateInputs()) {
      onConfirm(); // Only call onConfirm if inputs are valid
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Confirm Appointment</h2>
        <p className="mb-2">
          <strong>Fill Out Those Please</strong> {appointmentDetails.branch}
        </p>

        {/* User Information Fields */}
        <input
          type="text"
          placeholder="First Name"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-2 ${
            errors.first_name ? "border-red-500" : ""
          }`}
          name="first_name"
          value={userDetails.first_name}
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.first_name && (
          <p className="text-red-500 text-sm">{errors.first_name}</p>
        )}

        <input
          type="text"
          placeholder="Last Name"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-2 ${
            errors.last_name ? "border-red-500" : ""
          }`}
          name="last_name"
          value={userDetails.last_name}
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.last_name && (
          <p className="text-red-500 text-sm">{errors.last_name}</p>
        )}

        <input
          type="text"
          placeholder="Mobile Number"
          className={`w-full px-4 py-2 border border-gray-300 rounded mb-4 ${
            errors.mobile ? "border-red-500" : ""
          }`}
          name="mobile"
          value={userDetails.mobile} // This will display the normalized number
          onChange={onInputChange}
          disabled={loading}
        />
        {errors.mobile && (
          <p className="text-red-500 text-sm">{errors.mobile}</p>
        )}
        <div className="flex items-center m-2">
          <input
            type="checkbox"
            className="mr-2"
            checked={hasDocuments}
            onChange={(e) => setHasDocuments(e.target.checked)}
          />
          <label className="text-gray-800">Has more than one Documents?</label>
        </div>
        {hasDocuments && (
          <div className="mt-3">
            <label>Enter Number of Documents</label>
            <input
              name="document_count"
              value={userDetails.document_count}
              onChange={onInputChange}
              type="number"
              className="w-full px-4 py-2 border border-gray-300 rounded mb-4"
              min="1"
              max="3"
              step="1"
            />
            {errors.document_count && (
              <p className="text-red-500 text-sm">{errors.document_count}</p>
            )}
          </div>
        )}

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 mr-2 rounded"
            disabled={loading}
          >
            Cancel
          </button>

          <button
            onClick={handleConfirm} // Call the handleConfirm function
            className={`px-4 py-2 rounded text-white ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
            }`}
            disabled={loading}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Appointment Confirmed!</h2>
        <p>Your appointment has been successfully scheduled.</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const BookForManager = () => {
  const { user } = useContext(AuthContext);

  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null); // We will set this from the decoded token
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showAllServices, setShowAllServices] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [normalizedMobile, setNormalizedMobile] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secondLoader, setSecondLoader] = useState(false);
  console.log(selectedBranch, selectedService, selectedTimeSlot, selectedDate);
  const [selectedTimeSlotDetails, setSelectedTimeSlotDetails] = useState({
    time_from: null,
    duration_id: null,
    remainingSlots: null,
    branch_service_per_hour: null,
  });
  const [hasDocuments, setHasDocuments] = useState(false);

  // End of reschedule components

  function getFormattedDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const formattedDate = getFormattedDate();

  console.log(selectedTimeSlotDetails.duration_id, "duration ID"); // This will give you the duration ID of the selected time slot

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});

  console.log(appointmentDetails, "aapointment Details");

  // User Details State
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    document_count: 1,
  });

  console.log(userDetails, "user Details");

  // Decode the token to get the branch ID when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming the JWT token is stored in local storage
    if (token) {
      const decodedToken = jwtDecode(token);
      const branchId = decodedToken.data.employee.branch_id;
      setSelectedBranch(branchId);
    }
  }, []);

  // Handle Next for the Stepper
  const nextStep = (SelectedServiceId) => {
    if (step === 1 && SelectedServiceId) {
      // passed parameter as the second argument
      setStep((prev) => prev + 2);
    }
    // else if (step === 2 && selectedDate) {
    //   // fetchAvailableTimeSlots(selectedDate);
    //   setStep((prev) => prev + 1);
    // }
  };

  const prevStep = () => {
    setStep((prev) => prev - 2);
  };

  const initialStep = () => {
    if (step === 3 && selectedTimeSlotDetails) {
      setStep((prev) => prev - 2);
    }
  };

  const openConfirmationModal = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const confirmAppointment = async () => {
    try {
      const token = localStorage.getItem("token");
      setSecondLoader(true);
      console.log("Confirming appointment...");
      const remainingSlotsHere = selectedTimeSlotDetails.remaining_slots;

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
      };

      const appointmentData = {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        mobile: userDetails.mobile,
        appointment_time: formatDate(selectedDate),
        reschedule_count: 0,
        status: "confirmed",
        appointment_through: "callCenter",
        branch_service_id: selectedService,
        appointment_date: formatDate(selectedDate),
        appointment_duration_id: selectedTimeSlotDetails.duration_id,
        remainingSlots: remainingSlotsHere,
        document_count: userDetails.document_count,
      };

      const appointmentResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/add_appointment_by_portal`,
        appointmentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (appointmentResponse.data.status === "success") {
        console.log(
          "Appointment successfully created:",
          appointmentResponse.data
        );
        setIsSuccessModalOpen(true);
        setUserDetails({
          first_name: "",
          last_name: "",
          mobile: "",
        });
        initialStep();
      } else {
        console.error(
          "Failed to create appointment:",
          appointmentResponse.data
        );
        alert(
          "Failed to create appointment: " +
            (appointmentResponse.data.message || "Please try again.")
        );
      }
    } catch (err) {
      console.error("Error during appointment creation:", err);
      alert(
        "An error occurred while creating the appointment: " + err.message ||
          "Please try again."
      );
    } finally {
      setSecondLoader(false);
    }
  };

  useEffect(() => {
    let now = new Date();
    setSelectedDate(now);
  }, []);

  return (
    <div className="container mx-auto p-8">
      <Toaster richColors position="top-right" />
      {/* <h1 className="text-4xl font-bold mb-8">Book an Appointment</h1> */}
      {step === 1 && (
        <ServiceSelection
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          nextStep={nextStep}
          setShowAllServices={setShowAllServices}
          showAllServices={showAllServices}
        />
      )}
      {step === 2 && (
        <DateSelectionForm
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {step === 3 && (
        <TimeSlotSelection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          loading={loading}
          setSelectedTimeSlot={setSelectedTimeSlot}
          selectedTimeSlotDetails={selectedTimeSlotDetails}
          setSelectedTimeSlotDetails={setSelectedTimeSlotDetails}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <div className="flex justify-center space-x-6 mt-6">
        {step === 1 ? (
          ""
        ) : (
          <button
            onClick={prevStep}
            className="bg-blue-500 text-white px-6 py-2 rounded"
          >
            Back
          </button>
        )}

        {step === 2 && selectedDate && (
          <button
            onClick={nextStep}
            className="bg-blue-500 text-white px-6 py-2 rounded"
          >
            Next
          </button>
        )}
      </div>

      {step === 3 && selectedTimeSlot && (
        <button
          onClick={openConfirmationModal}
          className="bg-blue-500 text-white px-6 py-2 rounded mt-6"
        >
          Confirm Appointment
        </button>
      )}

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          confirmAppointment();
        }}
        appointmentDetails={appointmentDetails}
        userDetails={userDetails}
        onInputChange={handleInputChange}
        loading={loading}
        hasDocuments={hasDocuments}
        setHasDocuments={setHasDocuments}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          setIsModalOpen(false); // Close the confirmation modal if it's open
        }}
      />
    </div>
  );
};

export default BookForManager;
