const statusColors = {
  booked: "#0088FE",
  confirmed: "#00C49F",
  "checked in": "#FFBB28",
  "checked out": "#FF8042",
  expired: "#808080",
  inCompleted: "#EC5800",
};

const processAppointments = (appointments) => {
  // const statusCounts = {
  //   booked: 0,
  //   confirmed: 0,
  //   "checked in": 0,
  //   "checked out": 0,
  // };

  // appointments.forEach(({ status }) => {
  //   if (status in statusCounts) {
  //     statusCounts[status] += 1;
  //   }
  // });

  // return Object.entries(statusCounts).map(([status, count]) => ({
  //   status,
  //   count,
  //   fill: statusColors[status],
  // }));
  const chartData = appointments.map((items) => ({
    ...items,
    count: Number(items.appointment_count),
    fill: statusColors[items.status],
  }));
  return chartData;
};

export default processAppointments;
