import React from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/common/Table";
import StatusChips from "../../Components/common/StatusChips";
import { PencilIcon } from "lucide-react";

const Branchs = ({ services, handleModify, getServiceId }) => {
  return (
    <Table className="p-4 border border-gray-200 rounded-lg">
      <TableHeader className="border-none">
        <TableRow className="bg-gray-100">
          <TableHead>No.</TableHead>
          <TableHead>Service Name</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Created At</TableHead>
          <TableHead>Updated At</TableHead>
          <TableHead>Action</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {services.map((service, index) => (
          <TableRow key={service.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{service.name}</TableCell>
            <TableCell>{service.active ? "Active" : "Inactive"}</TableCell>
            <TableCell>{service.created_at}</TableCell>
            <TableCell>{service.updated_at}</TableCell>
            <TableCell>
              <button
                className="flex items-center text-white-500 hover:text-gray-500"
                onClick={() => getServiceId(service.id)}
              >
                <PencilIcon className="h-5 w-5 mr-1" />
                Edit
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Branchs;
