"use client";
import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../charts/Chart";
import LoadingSpinner from "../LoadingSpinner";
import { AuthContext } from "../../../contexts/auth";

const fetchBranchePerformance = async (
  token,
  startingDate,
  endingDate,
  selectedBranch
) => {
  // let URL = "";
  // if (startingDate && endingDate && selectedBranch) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?from_date=${startingDate}&to_date=${endingDate}&branch_id=${selectedBranch}`;
  // } else if (startingDate && selectedBranch) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?from_date=${startingDate}&branch_id=${selectedBranch}`;
  // } else if (endingDate && selectedBranch) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?to_date=${endingDate}&branch_id=${selectedBranch}`;
  // } else if (startingDate && endingDate) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?from_date=${startingDate}&to_date=${endingDate}`;
  // } else if (startingDate) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?from_date=${startingDate}`;
  // } else if (endingDate) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?to_date=${endingDate}`;
  // } else if (selectedBranch) {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance?branch_id=${selectedBranch}`;
  // } else {
  //   URL = `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance`;
  // }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/appointments/getBranchPerformance`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};
const ServiceBarChart = ({ startingDate, endingDate, selectedBranch }) => {
  const { token } = useContext(AuthContext);
  const {
    data: branchesData,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [
      "branchPerformance",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchBranchePerformance(
        queryKey[1],
        queryKey[2],
        queryKey[3],
        queryKey[4]
      ),
  });
  if (branchesLoading) {
    return <LoadingSpinner />;
  }
  if (branchesError) return <div>Error loading data</div>;

  const chartConfig = {
    servicesCount: {
      label: "Services Count",
      color: "hsl(var(--chart-1))",
    },
  };
  const chartData = [];
  branchesData?.map((eachBranch) => {
    chartData.push({
      name: eachBranch.branch_name,
      Served: eachBranch.served_today,
      CheckedIn: eachBranch.checked_in_appointments,
      Total: eachBranch.total_appointments,
    });
  });

  return (
    <Card className="w-full max-w-7xl mx-auto">
      <CardHeader>
        <CardTitle>Branch Performance</CardTitle>
        <CardDescription>
          Branches vs. Number of Unique Services
        </CardDescription>
      </CardHeader>
      <CardContent className="w-full">
        <ChartContainer config={chartConfig}>
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Served" stackId="a" fill="#8884d8" />
            <Bar dataKey="CheckedIn" stackId="a" fill="#82ca9d" />
            <Bar dataKey="Total" fill="#ffc658" />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default ServiceBarChart;
