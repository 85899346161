import React from "react";

function CardSkeleton() {
  return (
    <div class="bg-gray-300 p-5 rounded-lg animate-pulse">
      <div class="flex items-center justify-between">
        <div>
          <div class="h-4 w-20 rounded-lg bg-white"></div>
          <div class="h-8 w-32 mt-2 rounded-lg bg-white"></div>
        </div>
        <div class="bg-white p-3 rounded-full h-8 w-8"></div>
      </div>
    </div>
  );
}

export default CardSkeleton;
