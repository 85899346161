import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../Components/common/Card";
import LoadingSpinner from "../LoadingSpinner";
import fetchAllAppointments from "./fetchAllAppointments";
import processAppointments from "./processAppointments";
import AppointmentPieChart from "./AppointmentPieChart";
import StatusLegend from "./StatusLegend";
import { AuthContext } from "../../../contexts/auth";

const BookedThroughChart = ({ startingDate, endingDate, selectedBranch }) => {
  const { token } = useContext(AuthContext);
  const {
    data: appointmentsThrough,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "appointmentsBookedThrough",
      token,
      startingDate,
      endingDate,
      selectedBranch,
    ],
    queryFn: ({ queryKey }) =>
      fetchAllAppointments(queryKey[1], queryKey[2], queryKey[3], queryKey[4]),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) return <div>Error loading data</div>;

  const chartData = Array.isArray(appointmentsThrough)
    ? processAppointments(appointmentsThrough)
    : [];
  const totalAppointments = chartData.reduce(
    (acc, { count }) => acc + count,
    0
  );
  // const filteredData = chartData.filter((item) => item.status !== "booked");

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>Booked Through</CardTitle>
        <CardDescription>Appointments booked through</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <AppointmentPieChart
          chartData={chartData}
          totalAppointments={totalAppointments}
        />
        {/*  */}
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <StatusLegend chartData={chartData} />
        <div className="leading-none text-muted-foreground">
          Showing status breakdown of all current appointments.
        </div>
      </CardFooter>
    </Card>
  );
};

export default BookedThroughChart;
