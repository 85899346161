import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/auth'; // Assuming this context provides user and token
import axios from 'axios'; // Axios for making HTTP requests
import { useParams } from 'react-router-dom'; // For extracting params from the URL
import Modal from './Modal'; // Import the Modal component

const UpdateBranch = () => {
  const { user, token } = useContext(AuthContext);
  const { id } = useParams(); // Extracting id from URL parameters

  const [branchInfo, setBranchInfo] = useState({
    branchName: '',
    branchLocation: '',
    branchPhone: '',
    branchServicesPerHour: '',
    numberOfOfficers: '',
  });
  const [loading, setLoading] = useState(false); // To manage loading state
  const [error, setError] = useState(''); // To show error messages
  const [successMessage, setSuccessMessage] = useState(''); // To show success message
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the modal

  // Fetch branch details when component mounts
  useEffect(() => {
    const fetchBranchDetails = async () => {
      setLoading(true);
      setError('');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/branches/id`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        });

        // Populate state with branch details
        const { name, location_id, branch_no, branch_services_per_hour, number_of_officers } = response.data;
        setBranchInfo({
          branchName: name,
          branchLocation: location_id,
          branchPhone: branch_no || '',
          branchServicesPerHour: branch_services_per_hour || '',
          numberOfOfficers: number_of_officers || '',
        });
      } catch (err) {
        setError(err.response?.data?.msg || 'Failed to fetch branch details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBranchDetails();
  }, [id, token]);

  const handleBranchChangeInput = (e) => {
    const { name, value } = e.target;
    setBranchInfo({
      ...branchInfo,
      [name]: value,
    });
  };

  const handleSubmitBranchChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Open the confirmation modal
    setIsModalOpen(true);
  };

  const confirmUpdate = async () => {
    setIsModalOpen(false); // Close the modal
    setLoading(true); // Start loading

    try {
      // Make the branch information update request
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/branches/${user?.employee?.branch_id}`,
        {
          name: branchInfo.branchName,
          location_id: branchInfo.branchLocation,
          branch_no: branchInfo.branchPhone,
          branch_services_per_hour: branchInfo.branchServicesPerHour,
          number_of_officers: branchInfo.numberOfOfficers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        }
      );

      // Handle success response
      setSuccessMessage(response.data.msg || 'Branch information updated successfully!');
      // Optionally update state to reflect the latest values
      setBranchInfo({
        branchName: response.data.branch.name,
        branchLocation: response.data.branch.location_id,
        branchPhone: response.data.branch.branch_no,
        branchServicesPerHour: response.data.branch.branch_services_per_hour,
        numberOfOfficers: response.data.branch.number_of_officers,
      });
    } catch (err) {
      // Handle error
      setError(err.response?.data?.msg || 'Failed to update branch information. Please try again.');
      console.error(err);
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Update Branch Information</h2>
        {loading ? (
          <p>Loading branch details...</p>
        ) : (
          <form onSubmit={handleSubmitBranchChange} className="space-y-4">
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}
            <div>
              <label className="block text-gray-700">Branch Name</label>
              <input
                type="text"
                name="branchName"
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                value={branchInfo.branchName}
                onChange={handleBranchChangeInput}
                readOnly
              />
            </div>
            <div>
              <label className="block text-gray-700">Branch Number</label>
              <input
                type="tel"
                name="branchPhone"
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                value={branchInfo.branchPhone}
                onChange={handleBranchChangeInput}
                readOnly
              />
            </div>
            <div>
              <label className="block text-gray-700">Branch Services Per Slot</label>
              <input
                type="number"
                name="branchServicesPerHour"
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                value={branchInfo.branchServicesPerHour}
                onChange={handleBranchChangeInput}
                required
                disabled={loading}
              />
            </div>
            <div>
              <label className="block text-gray-700">Number of Officers</label>
              <input
                type="number"
                name="numberOfOfficers"
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                value={branchInfo.numberOfOfficers}
                onChange={handleBranchChangeInput}
                required
                disabled={loading}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Update Branch Information'}
            </button>
          </form>
        )}
      </div>
      {/* Confirmation Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmUpdate}
        message="Are you sure you want to update this branch information?"
      />
    </div>
  );
};

export default UpdateBranch;
