import React, { useEffect, useState } from "react";
import Button from "./Button";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

const Modal = ({ isOpen, onClose, organizationId, onEditSuccess }) => {
  const [organizationName, setOrganizationName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (organizationId && isOpen) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/organizations/${organizationId}`)
        .then((response) => {
          const { name, description, active } = response.data;
          setOrganizationName(name);
          setDescription(description);
          setStatus(active);
        })
        .catch((error) => {
          console.error("Error fetching organization data:", error);
        });
    }
  }, [organizationId, isOpen]);

  const resetState = () => {
    setDescription("");
    setOrganizationName("");
    setStatus(true);
    onClose();
  };
  const handleAdd = async (e) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations`, {
      name: organizationName,
      description: description,
    });

    queryClient.invalidateQueries("organizations");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      if (organizationId) {
        handleupdate();
      } else {
        handleAdd();
      }
      //onEditSuccess();
      resetState();
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };
  const handleupdate = async (e) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/organizations/edit?id=${organizationId}`,
      {
        name: organizationName,
        description,
        active: status,
      }
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">
          {organizationId ? "Edit " : "Add "}Organization
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Organization Name
            </label>
            <input
              type="text"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              rows={3}
              required
            />
          </div>
          {organizationId ? (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value === "true")}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          ) : null}
          <div className="flex justify-end">
            <Button
              type="button"
              className="text-gray-500 hover:text-gray-700 mr-4"
              onClick={resetState}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
