import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CardsSection from "../../Components/common/CardsSection";
import TableSection from "../../Components/common/TableSection";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import CardSkeleton from "../../Components/common/CardSkeleton";
import TableSkeleton from "../../Components/common/TableSekeleton";
import DatePicker from "react-datepicker";
import AgentDetailsModal from "./AgentDetails"; // Import the new modal
import "react-datepicker/dist/react-datepicker.css";

const fetchAgentPerformance = async (fromDate, toDate) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/appointments/agentPerformanceReport`;

  const url = `${baseUrl}?${fromDate ? `from_date=${fromDate}&` : ""}${
    toDate ? `to_date=${toDate}` : ""
  }`;

  const response = await axios.get(url);
  return response.data;
};

const ManageRoute = () => {
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const { data: agentPerformanceData, error, isLoading, refetch } = useQuery({
    queryKey: ["agentPerformance", fromDate, toDate],
    queryFn: () => fetchAgentPerformance(fromDate, toDate),
    keepPreviousData: true,
  });

  const HEADERS = [
    { name: "Agent Name", dataIndex: "agent_name" },
    { name: "Total Action Taken", dataIndex: "total_actions" },
    { name: "Appointments Submitted", dataIndex: "appointments_submitted" },
    { name: "Appointments Checked In", dataIndex: "appointments_checked_in" },
    { name: "Documents Checked Out", dataIndex: "documents_checked_out" },
    { name: "Total Action Taken", dataIndex: "total_actions" },
    {
      name: "Actions",
      render: (row) => (
        <button
          onClick={() => {
            setSelectedAgent(row);
            setIsModalOpen(true);
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Agent Details
        </button>
      ),
    },
  ];

  const handleDateFilterChange = () => {
    refetch();
  };

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex flex-wrap gap-4 mb-4">
          <CardSkeleton />
        </div>
        <TableSkeleton />
      </div>
    );
  }

  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="p-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center gap-4">
          <DatePicker
            selected={fromDate}
            onChange={(date) => {
              setFromDate(formatDate(date));
              handleDateFilterChange();
            }}
            placeholderText="From Date"
            dateFormat="yyyy-MM-dd"
            className="w-40 border border-gray-300 rounded-lg p-2"
            isClearable
          />
          <DatePicker
            selected={toDate}
            onChange={(date) => {
              setToDate(formatDate(date));
              handleDateFilterChange();
            }}
            placeholderText="To Date"
            dateFormat="yyyy-MM-dd"
            className="w-40 border border-gray-300 rounded-lg p-2"
            isClearable
          />
        </div>
        <CardsSection
          cardsData={[
            {
              title: "Agent Performance",
              amount: "Overview",
              type: "report",
            },
          ]}
        />
      </div>
      <TableSection
        rowsPerPage={10}
        currentData={agentPerformanceData}
        headers={HEADERS}
      />

      {/* Use the external modal */}
      <AgentDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        agent={selectedAgent}
      />
    </div>
  );
};

export default ManageRoute;
