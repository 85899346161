import React, { useState } from 'react';
import Button from '../../Components/common/Button';
import axios from 'axios';
import { toast } from 'sonner';
import TableSekeleton from '../../Components/common/TableSekeleton';
import Dates from './Dates';
import { useQuery } from '@tanstack/react-query';

const fetchDates = async (page, limit) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/appointments/dateList?page=${page}&limit=${limit}`);
  if (!response.ok) {
    throw new Error('Error fetching Durations');
  }
  return response.json();
};

const AddDates = ({ handleAddModalDates }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [formData, setFormData] = useState({
    start_date: '',
    numberOfDays: '',
    saturday: 1,
    sunday: 1,
  });
  const { data: dates, error: dateError, isLoading: isLoadingDates, refetch } = useQuery({
    queryKey: ['Dates', currentPage, itemsPerPage],
    queryFn: () => fetchDates(currentPage, itemsPerPage),
  });

  const totalPages = dates ? Math.ceil(dates?.totalItems / itemsPerPage) : 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let value = '';
    if (name === 'saturday' || name === 'sunday') value = checked ? 1 : 0;
    else value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/generateAppointmentDates`,

        formData
      );
      if (response.status === 200) {
        toast.success('Dates added successfully');
        refetch();
      } else {
        toast.error('Failed to add dates. Please try again later.');
      }
    } catch (error) {
      toast.error('Failed to add dates. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg shadow-md z-50 max-w-2xl w-full">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">Add New Dates</h2>
          <button
            type="button"
            className="text-gray-500 hover:bg-gray-100 hover:text-gray-900 rounded-full p-2"
            onClick={handleAddModalDates}
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="col-span-2">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium text-gray-700"
              >
                Start Date (YYYY-MM-DD)
              </label>
              <input
                type="text"
                name="start_date"
                id="start_date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
                placeholder="YYYY-MM-DD"
                value={formData.start_date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="numberOfDays"
                className="block text-sm font-medium text-gray-700"
              >
                Number of Days to Add
              </label>
              <input
                type="number"
                name="numberOfDays"
                id="numberOfDays"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
                placeholder="Enter number of days"
                value={formData.numberOfDays}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-2 flex items-center">
              <input
                type="checkbox"
                checked={formData.saturday === 1}
                onChange={handleChange}
                name="saturday"
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <label className="ml-2 text-gray-700">Saturday?</label>
            </div>
            <div className="col-span-2 flex items-center">
              <input
                type="checkbox"
                checked={formData.sunday === 1}
                onChange={handleChange}
                name="sunday"
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <label className="ml-2 text-gray-700">Sunday?</label>
            </div>
          </div>
          <Button type="submit">
            <svg
              className="mr-1 -ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            Add New Dates
          </Button>
        </form>
        {isLoadingDates ? <TableSekeleton /> : <Dates dates={dates?.data || []} />}
        <nav className="flex justify-center py-4">
          <ul className="inline-flex items-center space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index}>
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === index + 1
                      ? 'text-indigo-600 bg-indigo-100 border border-indigo-300'
                      : 'text-gray-500 bg-white border border-gray-300'
                  } hover:bg-indigo-50 hover:text-indigo-600`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AddDates;
