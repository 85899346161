import React from 'react';

const Button = ({ children, onClick, type = 'button', className = '', variant = 'solid' }) => {
  const baseStyles = 'px-4 py-2 align-middle rounded-lg flex row-auto justify-center focus:outline-none focus:ring-2 focus:ring-opacity-75';
  const solidStyles = 'bg-primary text-white hover:bg-blue-700 focus:ring-blue-400';
  const outlineStyles = 'bg-transparent text-primary border border-primary hover:bg-primary hover:text-white focus:ring-primary';

  const styles = variant === 'outline' ? outlineStyles : solidStyles;

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseStyles} ${styles} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
