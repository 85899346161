import React from "react";

const StatusLegend = ({ chartData }) => (
  <div className="flex flex-wrap gap-4">
    {chartData.map(({ service_name, fill }) => (
      <div key={service_name} className="flex items-center gap-2">
        <div
          style={{ backgroundColor: fill }}
          className="h-4 w-4 rounded-full"
        ></div>
        <span>{service_name}</span>
      </div>
    ))}
  </div>
);

export default StatusLegend;
