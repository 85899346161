import React, { useContext } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableFooter,
} from "./Table";
import SearchBar from "./SearchBar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Button from "./Button";
import { AuthContext } from "../../contexts/auth";

const TableSection = ({
  rowsPerPage,
  handleRowsPerPageChange,
  searchTerm,
  setSearchTerm,
  currentData,
  handleSearch,
  downloadPDF,
  currentPage,
  totalPages,
  handlePageChange,
  headers,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <div className="border rounded-lg bg-white">
      <div className="flex justify-between p-4">
        <div className="flex items-center">
          <label htmlFor="rows-per-page" className="mr-2">
            Rows per page:
          </label>
          <select
            id="rows-per-page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            className="border rounded-md p-2 mr-4"
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={35}>35</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </select>
        </div>

        {user?.role?.auth_role_id === "01bf91c3-abb9-4c5c-8b84-364dd28e8683" ? (
          ""
        ) : (
          <Button
            onClick={downloadPDF}
            className="bg-blue-500 text-white py-2 px-4 rounded mx-4"
          >
            Download PDF
          </Button>
        )}

        <div className="flex items-center">
          <SearchBar
            searchTerm={searchTerm}
            handleSearchTermChange={(e) => setSearchTerm(e.target.value)}
            className="m-2"
          />
          <Button onClick={() => handleSearch(searchTerm)} className="mb-3">
            Search
          </Button>
        </div>
      </div>

      <Table className="w-full divide-y divide-gray-200">
        <TableHeader>
          <TableRow>
            {headers.map((header, index) => (
              <TableHead key={index}>{header.name}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((header, colIndex) => (
                <TableCell key={colIndex}>
                  {header.render
                    ? header.render(row)
                    : row[header.dataIndex]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={headers.length}>
              <div className="flex justify-center items-center p-4">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => handlePageChange(value)}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default TableSection;
