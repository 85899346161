import React from "react";

function QueueItem({ number, windowNo, firstname, isEven }) {
  const bgColor = isEven ? "bg-blue-500" : "bg-pink-600";
  const marginClass = "mt-2";

  return (
    <div className={`flex flex-wrap gap-3 ${marginClass}`}>
      <div
        className={`flex flex-wrap flex-1 gap-5 px-5 py-5 ${bgColor} items-center`}
      >
        {/* Queue Number - Larger and Bolder */}
        <div className="font-extrabold text-white text-6xl flex-1 text-center">
          {`No: ${number}`}
        </div>

 

        <div className="font-extrabold text-white text-6xl flex-1 text-center">
        {`To:  ${windowNo}`}
        </div>
        
               <div className="flex flex-col justify-center flex-1">
          <div className="text-white text-2xl">{`Name: ${firstname}`}</div>
        </div>
      </div>
    </div>
  );
}

export default QueueItem;
