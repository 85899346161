import React from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/common/Table";
import StatusChips from "../../Components/common/StatusChips";

const Branchs = ({ branches, handleModify }) => {
  return (
    <Table className="p-4 border border-gray-200 rounded-lg">
      <TableHeader className="border-none">
        <TableRow className="bg-gray-100">
          <TableHead>No.</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Location</TableHead>
          <TableHead>Branch Number</TableHead>
          <TableHead>Branch Service Hour</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Organization</TableHead>
        
        
         
          <TableHead>Action</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {branches.map((branch, index) => (
          <TableRow key={branch.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{branch.name}</TableCell>
            <TableCell>{branch?.location?.name}</TableCell>
            <TableCell>{branch?.branch_no}</TableCell>
            <TableCell>{branch?.branch_services_per_hour} Per Hour</TableCell>
            <TableCell className={branch.active ? "text-green-500" : "text-red-500"}>
                  {branch.active ? "Active" : "Inactive"}
                </TableCell>
            <TableCell>{branch?.organization?.name}</TableCell>
      
         
         
            <TableCell>
              <StatusChips
                status="Modify"
                onClick={() => handleModify(branch.id, branch.name)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Branchs;
